import React from "react";
import { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import "./User_Scanner_Scanner.css";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getOrganizer } from "../../services/redux/middleware/getOrganizor";
import ScreenLoader from "../loader/screenLoader";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Stack from "@mui/material/Stack";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
const User_Scanner_Scanner = () => {
  const navigate = useNavigate();

  const tableRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const handleNavigate = (organizerId) => {
    navigate(`/personaluserorganizer/${organizerId}`);
  };
  const navigate2 = useNavigate();
  const [loading, setLoading] = useState(true);
  const goBack = () => {
    navigate2(-1);
  };
  const dispatch = useDispatch();
  const organizerData = useSelector((state) => state?.getOrganizer);
  const { organizers, totalPages } = organizerData?.userData?.data?.data || {};
  console.log("organozationdata is...", organizerData?.userData?.data?.data);
  const filteredOrganizers = organizers?.filter((organizer) =>
    organizer?.name?.toLowerCase().includes(searchQuery.toLowerCase())
  );
  useEffect(() => {
    setLoading(true);
    const datas = {
      page: currentPage,
      search: searchQuery,
    };
    dispatch(getOrganizer(datas)).finally(() => setLoading(false));
  }, [currentPage, dispatch, searchQuery]);
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    const searchInput = document.querySelector(".export-button");
    if (searchInput) {
      searchInput.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [currentPage]);
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const downloadCSV = () => {
    if (! organizerData?.userData?.data?.data?.organizers ||  organizerData?.userData?.data?.data?.organizers.length === 0) {
      toast.error("No data available to export!");
      return;
    }
    const data = organizerData?.userData?.data?.data?.organizers;
    if (data && data.length > 0) {
      const header = [
        "PFP",
        "Name",
        "Email",
        "Role",
        "Date Joined",
        "Hosted Events",
      ];
      const rows = data.map((row) => [
        row?.pfp || "",
        row?.name || "----",
        row?.email || "----",
        row?.role || "---",
        row?.dateJoined.split("T")[0] || "----",
        row?.hostedEvents || "----",
      ]);

      const csvContent = [
        header.join(","),
        ...rows.map((row) => row.join(",")),
      ].join("\n");

      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "organizer_data.csv";
      link.click();
    }
  };
  return (
    <div style={{ paddingBottom: "100px" }}>
      {loading && <ScreenLoader />}
      <div className="export-button">
        <button onClick={downloadCSV}>Export</button>
      </div>
      <div className="personal__user__table_div1">
        <div className="personal__user__table_div_1">
          <input
            className="search__user_input"
            type="text"
            placeholder="Search Organizer"
            value={searchQuery}
            onChange={handleSearchChange}
          />
          <img
            style={{ height: "20px", width: "20px" }}
            src="/Images/dashboard/searchicon.svg"
            alt="search icon"
          />
        </div>
      </div>
      <TableContainer
        component={Paper}
        sx={{
          backgroundColor: "rgba(255, 255, 255, 0.06)",
          overflowX: "auto",
        }}
        ref={tableRef}
        className="Organizer_detail_Follower_Main_____
"
      >
        <Table
          sx={{ minWidth: 1000, maxWidth: "100%" }}
          aria-label="simple table"
        >
          <TableHead sx={{ backgroundColor: "rgba(255, 255, 255, 0.06)" }}>
            <TableRow>
              <TableCell className="Organizer_detail_Follower_Main___Row">
                PFP
              </TableCell>
              <TableCell className="Organizer_detail_Follower_Main___Row">
                Name
              </TableCell>
              <TableCell className="Organizer_detail_Follower_Main___Row">
                Email
              </TableCell>
              <TableCell className="Organizer_detail_Follower_Main___Row">
                Role
              </TableCell>
              <TableCell
                style={{ whiteSpace: "nowrap" }}
                className="Organizer_detail_Follower_Main___Row"
              >
                Date Joined
              </TableCell>
              <TableCell
                style={{ whiteSpace: "nowrap" }}
                className="Organizer_detail_Follower_Main___Row"
              >
                Hosted Events
              </TableCell>
              <TableCell className="Organizer_detail_Follower_Main___Row">
                View
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow>
              <TableCell
                colSpan={8}
                style={{
                  height: "16px",
                  backgroundColor: "#030303",
                  borderBottom: "none",
                  padding: "0px",
                }}
              ></TableCell>
            </TableRow>
            {filteredOrganizers?.map((row, index) => (
              <TableRow
                key={index}
                className="Organizer_detail_Follower_Main___Body_Top"
              >
                <TableCell className="Organizer_detail_Follower_Main___Body">
                  <img
                    style={{
                      height: "40px",
                      width: "40px",
                      borderRadius: "1000px",
                    }}
                    src={row?.pfp || "/Images/dashboard/naitram.png"}
                  />
                </TableCell>
                <TableCell className="Organizer_detail_Follower_Main___Body">
                  {row?.name?.length > 20
                    ? `${row?.name.substring(0, 20)}...`
                    : row?.name || "----"}
                </TableCell>

                <TableCell className="Organizer_detail_Follower_Main___Body1">
                  {row?.email ? row?.email : "----"}
                </TableCell>
                <TableCell className="Organizer_detail_Follower_Main___Body1">
                  {row?.role ? row?.role : "---"}
                </TableCell>
                <TableCell
                  style={{ whiteSpace: "nowrap" }}
                  className="Organizer_detail_Follower_Main___Body1"
                >
                  {row?.dateJoined.split("T")[0] || "----"}
                </TableCell>
                <TableCell
                  style={{ textAlign: "center" }}
                  className="Organizer_detail_Follower_Main___Body2"
                >
                  {row?.hostedEvents || "0"}
                </TableCell>
                <TableCell className="Organizer_detail_Follower_Main___Body">
                  <img
                    style={{ cursor: "pointer" }}
                    src="/Images/scannereyemain/eyescanner.svg"
                    alt=".."
                    onClick={() => handleNavigate(row?.id)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Stack
        spacing={2}
        sx={{
          backgroundColor: "#121212",
          padding: "1rem",
          borderRadius: "8px",
        }}
      >
        <Pagination
          count={totalPages || 1}
          page={currentPage}
          onChange={handlePageChange}
          sx={{
            "& .MuiPagination-ul": {
              display: "flex",
              gap: "10px",
              justifyContent: "flex-end",
            },
          }}
          renderItem={(item) => (
            <PaginationItem
              slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
              {...item}
              sx={{
                color: "#fff",
                borderRadius: "10px",
                border: "1px solid rgba(255, 255, 255, 0.5)",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                },
                "&.Mui-selected": {
                  backgroundColor: "#00d059",
                  color: "#fff",
                  borderRadius: "10px",
                },
              }}
            />
          )}
        />
      </Stack>
    </div>
  );
};

export default User_Scanner_Scanner;
