import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getKycById } from "../../services/redux/middleware/getKycById";
import "./RequestS_Details_Owners.css";
import { approveStatus } from "../../services/redux/middleware/approveStatus";
import ScreenLoader from "../loader/screenLoader";

const RequestS_Details_Owners = () => {
  const { kycId } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const kycDataById = useSelector(
    (state) => state?.getKycById?.kycRequestData?.data?.data
  );

  useEffect(() => {
    setLoading(true);
    dispatch(getKycById({ userId: kycId })).finally(() => setLoading(false));
  }, [dispatch, kycId]);

 
const handleApprove = async () => {
  if (kycDataById && Array.isArray(kycDataById)) {
    try {
      setLoading(true); 
      let approvalSuccess = false;

      for (const record of kycDataById) {
        if (record?.userId) {
          const data = { userId: record.userId, approved: 1 };
          console.log("Approving status with data:", data);

          const result = await dispatch(approveStatus(data));
          console.log("Dispatch result:", result);

          if (result.type === "approveStatus/fulfilled") {
            if (result.payload?.status === 201) {
              approvalSuccess = true;
            }
          }
        }
      }

      if (approvalSuccess) {
        toast.success("Approved successfully");
      } else {
        toast.success("Approved successfully");
      }
    } catch (error) {
      console.error("Error during approval:", error);
      toast.error(error.message || "An unexpected error occurred");
    } finally {
      setLoading(false);
    }
  } else {
    toast.error("No KYC data available for approval");
  }
};

  const handleDeny = () => {
    toast.error("KYC details not approved");
  };

  return (
    <>
      {loading && <ScreenLoader />}
      <div>
        {kycDataById && kycDataById.length > 0 ? (
          <>
            <div className="RequestS_Details_Representative_divlast">
              <button
                onClick={handleApprove}
                className="RequestS_Details_Representative_divlastBtn1"
              >
                Approve
              </button>
              <button
                onClick={handleDeny}
                className="RequestS_Details_Representative_divlastBtn2"
              >
                Deny
              </button>
            </div>
            <TableContainer
              component={Paper}
              className="Organizer_detail_Follower_Main"
            >
              <Table
                sx={{ minWidth: 650, maxWidth: 780 }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow
                    style={{ backgroundColor: "rgba(255, 255, 255, 0.06)" }}
                  >
                    <TableCell className="Organizer_detail_Follower_Main___Row">
                      Name
                    </TableCell>
                    <TableCell className="Organizer_detail_Follower_Main___Row">
                      Relationship
                    </TableCell>
                    <TableCell className="Organizer_detail_Follower_Main___Row">
                      Percentage
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {kycDataById.map((data, index) =>
                    data?.Owner &&
                    Array.isArray(data.Owner) &&
                    data.Owner.length > 0
                      ? data.Owner.map((owner, ownerIndex) => (
                          <TableRow
                            key={`${index}-${ownerIndex}`}
                            className="Organizer_detail_Follower_Main___Body_Top"
                          >
                            <TableCell className="Organizer_detail_Follower_Main___Body">
                              {`${owner.FirstName || ""} ${
                                owner.LastName || ""
                              }`}
                            </TableCell>
                            <TableCell className="Organizer_detail_Follower_Main___Body1">
                              {owner.relationship || ""}
                            </TableCell>
                            <TableCell className="Organizer_detail_Follower_Main___Body1">
                              {owner.percentage || ""}
                            </TableCell>
                          </TableRow>
                        ))
                      : null
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        ) : (
          <div className="empty__state__main__cintainer__2">
            <Typography variant="h6" className="empty_state_text">
              There’s No Owners
            </Typography>
          </div>
        )}
      </div>
    </>
  );
};

export default RequestS_Details_Owners;
