import React from "react";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "./Updated_Scanner_Updated_Table.css";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import { getScanner } from "../../services/redux/middleware/getScanners";
import ScreenLoader from "../../Components/loader/screenLoader";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Stack from "@mui/material/Stack";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
const Updated_Scanner_Updated_Table = () => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const [filteredScanners, setFilteredScanners] = useState([]);
  const dispatch = useDispatch();
  const ScannerData = useSelector((state) => state?.getScanner);
  const { data: scannerData = {}, totalPages = 1 } =
    ScannerData?.scannerData?.data || {};

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    setLoading(true);
    dispatch(getScanner({ page: currentPage, search: searchQuery })).finally(
      () => setLoading(false)
    );
  }, [dispatch, currentPage, searchQuery]);

  useEffect(() => {

    if (scannerData?.scanners?.length) {
      const filtered = scannerData.scanners.filter((scanner) =>
        scanner.name?.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredScanners(filtered);
    }
  }, [scannerData, searchQuery]);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleNavigate = (id) => {
    navigate(`/User_Scanner____Details/${id}`);
  };

  const handleDownloadCSV = () => {
    if (!filteredScanners|| filteredScanners.length === 0) {
      toast.error("No data available to export!");
      return;
    }

    const headers = ["Name", "Email", "Role", "Date Joined", "Scan Tickets"];
    const rows = filteredScanners?.map((item) => [
      item.name,
      item.email,
      item.role,
      item.dateJoined,
      item.scanCount,
    ]);

    const csvContent = [headers, ...rows]
      .map((row) => row.join(","))
      .join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.download = "scanner_data.csv";
    link.click();
    URL.revokeObjectURL(url);
  };

  return (
    <>
      {loading && <ScreenLoader />}
      <div className="export-button">
        <button onClick={handleDownloadCSV}>Export</button>
      </div>
      <div
        className="personal-info-detail-container____"
        style={{ paddingBottom: "100px" }}
      >
        <div className="personal__user__table_div">
          <div className="personal__user__table_div_1">
            <input
              className="search__user_input"
              type="text"
              placeholder="Search Scanner"
              value={searchQuery}
              onChange={handleSearchChange}
            />
            <img
              style={{ height: "20px", width: "20px" }}
              src="/Images/dashboard/searchicon.svg"
              alt="search icon"
            />
          </div>
        </div>
        <Box>
          <Box
            sx={{ backgroundColor: "#000", mb: "16px", width: "1080px" }}
          ></Box>
          <TableContainer
            className="Organizer_detail_Follower_Main_____"
            component={Paper}
            sx={{
              backgroundColor: "rgba(255, 255, 255, 0.06)",
              overflowX: "auto",
            }}
          >
            <Table>
              <TableHead
                sx={{ backgroundColor: "rgba(255, 255, 255, 0.06)" }}
                className="to-apply-table-border-color"
              >
                <TableRow>
                  {/* <TableCell
                    sx={{ borderBottom: "1px solid #292929", width: "85px" }}
                    className="even-name-text-style-for"
                  >
                    PFP
                  </TableCell> */}
                  <TableCell
                    sx={{ borderBottom: "1px solid #292929" }}
                    className="even-name-text-style-for"
                  >
                    Name
                  </TableCell>
                  <TableCell
                    sx={{ borderBottom: "1px solid #292929", width: "188px" }}
                    className="even-name-text-style-for"
                  >
                    Email
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: "1px solid rgba(41, 41, 41, 1)",
                      width: "195px",
                    }}
                    className="even-name-text-style-for"
                  >
                    Role
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: "1px solid rgba(41, 41, 41, 1)",
                      width: "105px",
                    }}
                    className="even-name-text-style-for"
                  >
                    Date Joined
                  </TableCell>
                  {/* <TableCell
                  sx={{ borderBottom: "1px solid rgba(41, 41, 41, 1)"  , width:'141px' }}
                  className="even-name-text-style-for"
                >
                 Tickets Bought
                </TableCell> */}
                  <TableCell
                    sx={{
                      borderBottom: "1px solid rgba(41, 41, 41, 1)",
                      width: "141px",
                    }}
                    className="even-name-text-style-for"
                  >
                    Scan Tickets
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: "1px solid rgba(41, 41, 41, 1)",
                    }}
                    className="even-name-text-style-for"
                  >
                    View
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableRow
                sx={{
                  height: "16px",
                  backgroundColor: "#030303",
                  width: "84px",
                }}
              >
                <TableCell colSpan={8}></TableCell>
              </TableRow>
              <TableBody
                style={{
                  background:
                    "linear-gradient(360deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.06) 100%)",
                }}
                className="for-to-give-the-padding"
              >
                {filteredScanners?.map(
                  (row, index) => (
                    <TableRow
                      key={index}
                      className="main-even-name-text-style-for"
                    >
                      {/* <TableCell
                      sx={{ borderBottom: "1px solid #292929", width: "85px" }}
                      className="main-even-name-text-style-for-padding-margin-name"
                    >
                      <img
                        src={row.picture}
                        alt="Event"
                        style={{ width: "40px" }}
                      />
                    </TableCell> */}
                      <TableCell
                        sx={{
                          borderBottom: "1px solid #292929",
                          width: "188px",
                          textTransform: "none !important",
                        }}
                        className="main-even-name-text-style-for-padding-margin-name"
                      >
                        {row?.name}
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: "1px solid #292929",
                          width: "195px",
                        }}
                        className="main-even-name-text-style-for-padding-margin-type"
                      >
                        {row?.email}
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: "1px solid #292929",
                          width: "105px",
                        }}
                        className="main-even-name-text-style-for-padding-margin-type"
                      >
                        {row?.role}
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: "1px solid #292929",
                          width: "141px",
                        }}
                        className="main-even-name-text-style-for-padding-margin-type"
                      >
                        {row?.dateJoined.split("T")[0]}
                      </TableCell>
                      {/* <TableCell
                    sx={{ borderBottom: "1px solid #292929" , width:'141px' }}
                    className="main-even-name-text-style-for-padding-margin-type"
                  >
                    {row.date}
                  </TableCell> */}
                      <TableCell
                        sx={{
                          borderBottom: "1px solid #292929",
                          width: "141px",
                        }}
                        className="main-even-name-text-style-for-padding-margin-type"
                      >
                        {row?.scanCount}
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: "1px solid #292929",
                          width: "84px",
                        }}
                        className="main-even-name-text-style-for-padding-margin-type"
                      >
                        <img
                          onClick={() => {
                            console.log("scanner id is", row?.id);
                            handleNavigate(row?.id);
                          }}
                          src="/Images/User_Updated_table/eye_updated.svg"
                          alt="Eye View"
                          style={{ width: "24px", cursor: "pointer" }}
                        />
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Stack
            spacing={2}
            sx={{
              backgroundColor: "#121212",
              padding: "1rem",
              borderRadius: "8px",
            }}
          >
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
              sx={{
                "& .MuiPagination-ul": {
                  display: "flex",
                  gap: "10px",
                  justifyContent: "flex-end",
                },
              }}
              renderItem={(item) => (
                <PaginationItem
                  slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                  {...item}
                  sx={{
                    color: "#fff",
                    borderRadius: "10px",
                    border: "1px solid rgba(255, 255, 255, 0.5)",
                    "&:hover": {
                      backgroundColor: "rgba(255, 255, 255, 0.1)",
                    },
                    "&.Mui-selected": {
                      backgroundColor: "#00d059",
                      color: "#fff",
                      borderRadius: "10px",
                    },
                  }}
                />
              )}
            />
          </Stack>
        </Box>
      </div>
    </>
  );
};
export default Updated_Scanner_Updated_Table;
