import React from "react";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "./CollectiblesOwned.css";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import ScreenLoader from "../../Components/loader/screenLoader";
const CollectiblesOwned = (collectibles) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(true);

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  useEffect(() => {
    if (
      collectibles?.collectibles?.userCollectiblesData?.data?.userCollectibles
    ) {
      setLoading(false);
    } else {
      const timer = setTimeout(() => {
        setLoading(false);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [collectibles]);

  const exportToCSV = () => {
    if (
      !collectibles?.collectibles?.userCollectiblesData?.data?.userCollectibles ||
      collectibles?.collectibles?.userCollectiblesData?.data?.userCollectibles
        .length === 0
    ) {
      toast.error("No data available to export!");
      return;
    }
  
    const csvRows = [];
    const headers = ["Picture", "Collectible Name", "Description", "Date", "Time"];
    csvRows.push(headers.join(","));
  
    // Add data rows
    collectibles?.collectibles?.userCollectiblesData?.data?.userCollectibles?.forEach(
      (row) => {
        const rowData = [
          row?.Collectiblee?.image || "N/A",
          row?.Collectiblee?.name || "----",
          row?.Collectiblee?.description || "----",
          row.date?.split("T")[0] || "----",
          row.date?.split("T")[1]?.split(".")[0] || "----",
        ];
        csvRows.push(rowData.join(","));
      }
    );
  
    const blob = new Blob([csvRows.join("\n")], {
      type: "text/csv;charset=utf-8;",
    });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "collectibles_data.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };
  
  return (
    <>
      {loading && <ScreenLoader />}
      <div style={{ paddingBottom: "100px" }}>
        <div className="export-button">
          <button onClick={exportToCSV}>Export</button>
        </div>
        <div className="personal-info-detail-container---ed">
          <div className="CollectiblesOwned-to-give-width">
            <Box>
              <Box
                sx={{ height: "50px", backgroundColor: "#000", mb: "16px" }}
              ></Box>
              {!collectibles?.collectibles?.userCollectiblesData?.data
                ?.userCollectibles?.length ? (
                <div>
                  <div className="empty__state__main__cintainer__1">
                    <Typography className="empty_state_text" variant="h6">
                      There’s No Collectibles Owned
                    </Typography>
                  </div>
                </div>
              ) : (
                <TableContainer
                  className="Organizer_detail_Follower_Main_____"
                  component={Paper}
                  sx={{
                    backgroundColor: "rgba(255, 255, 255, 0.06)",
                    overflowX: "auto",
                  }}
                >
                  <Table>
                    {collectibles?.collectibles?.userCollectiblesData?.data
                      ?.userCollectibles?.length > 0 && (
                      <TableHead
                        sx={{ backgroundColor: "rgba(255, 255, 255, 0.06)" }}
                        className="to-apply-table-border-color"
                      >
                        <TableRow>
                          <TableCell
                            sx={{ borderBottom: "1px solid #292929" }}
                            className="even-name-text-style-for"
                          >
                            Picture
                          </TableCell>
                          <TableCell
                            sx={{ borderBottom: "1px solid #292929" }}
                            className="even-name-text-style-for"
                          >
                            Collectible Name
                          </TableCell>
                          <TableCell
                            sx={{ borderBottom: "1px solid #292929" }}
                            className="even-name-text-style-for"
                          >
                            Description
                          </TableCell>
                          <TableCell
                            sx={{
                              borderBottom: "1px solid rgba(41, 41, 41, 1)",
                            }}
                            className="even-name-text-style-for"
                          >
                            Date
                          </TableCell>
                          <TableCell
                            sx={{
                              borderBottom: "1px solid rgba(41, 41, 41, 1)",
                            }}
                            className="even-name-text-style-for"
                          >
                            Time
                          </TableCell>
                        </TableRow>
                      </TableHead>
                    )}

                    {collectibles?.collectibles?.userCollectiblesData?.data
                      ?.userCollectibles?.length > 0 && (
                      <TableRow
                        sx={{ height: "16px", backgroundColor: "#030303" }}
                      >
                        <TableCell colSpan={5}></TableCell>
                      </TableRow>
                    )}

                    <TableBody
                      style={{
                        background:
                          "linear-gradient(360deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.06) 100%)",
                      }}
                      className="for-to-give-the-padding"
                    >
                      {collectibles?.collectibles?.userCollectiblesData?.data?.userCollectibles?.map(
                        (row, index) => (
                          <TableRow
                            key={index}
                            className="main-even-name-text-style-for"
                          >
                            <TableCell
                              sx={{ borderBottom: "1px solid #292929" }}
                              className="main-even-name-text-style-for-padding-margin-name"
                            >
                              <img
                                src={
                                  row?.Collectiblee?.image
                                    ? row?.Collectiblee?.image
                                    : "/Images/dashboard/naitram.png"
                                }
                                alt="Event"
                                style={{ width: "40px" }}
                              />
                            </TableCell>
                            <TableCell
                              sx={{
                                borderBottom: "1px solid #292929",
                                width: "275px",
                              }}
                              className="main-even-name-text-style-for-padding-margin-name"
                            >
                              {row?.Collectiblee?.name || "----"}
                            </TableCell>
                            <TableCell
                              sx={{
                                borderBottom: "1px solid #292929",
                                width: "275px",
                              }}
                              className="main-even-name-text-style-for-padding-margin-type"
                            >
                              {row?.Collectiblee?.description || "----"}
                            </TableCell>
                            <TableCell
                              sx={{
                                borderBottom: "1px solid #292929",
                                width: "275px",
                              }}
                              className="main-even-name-text-style-for-padding-margin-type"
                            >
                              {row.date?.split("T")[0] || "----"}
                            </TableCell>
                            <TableCell
                              sx={{
                                borderBottom: "1px solid #292929",
                                width: "275px",
                              }}
                              className="main-even-name-text-style-for-padding-margin-type"
                            >
                              {row.createdAt?.split("T")[1]?.split(".")[0] ||
                                "----"}
                            </TableCell>
                          </TableRow>
                        )
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Box>
          </div>
        </div>
      </div>
    </>
  );
};

export default CollectiblesOwned;
