import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { styled } from "@mui/system";
import "./SalesAnalytics_table1.css";

const SalesAnalytics_table1 = (saleData) => {
  // Sample data as shown in the image
  // const data = [
  //   { id: 1, ticketType: "Premium", sold: 100, percentage: 90 },
  //   { id: 2, ticketType: "Gold", sold: 80, percentage: 78 },
  //   { id: 3, ticketType: "Takeovr 2024", sold: 150, percentage: 120 },
  //   { id: 4, ticketType: "Takeovr 2024", sold: 40, percentage: 35 },
  // ];
  const [isExpanded, setIsExpanded] = useState(false);
  const [ticketTypes, setTicketTypes] = useState([]);
  
  const handleToggle = () => {
    setIsExpanded((prevExpanded) => !prevExpanded);
  };
  
  useEffect(() => {
    if (saleData?.salesData?.getLiveEvent?.data?.ticketTypes) {
      console.log(
        "Sales data is .......",
        saleData.salesData.getLiveEvent.data.ticketTypes
      );
      setTicketTypes(saleData.salesData.getLiveEvent.data.ticketTypes);
    }
  }, [saleData]);
  
  const total = ticketTypes
    .map((item) => item.count * item.price)
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  
  const totalUserCount = ticketTypes
    .map((item) => {
      const countTickets = item.rsvpCapacity
        ? item.originalNoOfTickets - item.rsvpCapacity
        : item.originalNoOfTickets - item.noOfTickets;
  
      console.log("Count of tickets is:", countTickets);
  
      return countTickets ? countTickets : 0;
    })
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  
  console.log("Total Salesssssssssssss:", ticketTypes);
  console.log("Total User Count:", totalUserCount);

  const soldTickets = () => {
    if (ticketTypes?.rsvpCapacity) {
      return ticketTypes?.originalNoOfTickets - ticketTypes?.rsvpCapacity;
    } else {
      return ticketTypes?.originalNoOfTickets - ticketTypes?.noOfTickets;
    }
  };
//   const total = ticketTypes
//   .map((item) => item.count * item.price)
//   .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

// const totalUserCount = ticketTypes
//   .map((item) => item.userCount)
//   .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

// console.log("Total Salesssssssssssss:", ticketTypes);
// console.log("Total User Count:", totalUserCount);

// const soldTickets = () => {
//   if (ticketTypes?.rsvpCapacity) {
//     return ticketTypes?.originalNoOfTickets - ticketTypes?.rsvpCapacity;
//   } else {
//     return ticketTypes?.originalNoOfTickets - ticketTypes?.noOfTickets;
//   }
// };
  return (
    <div className="SalesAnalytics_table1___main">
      <div className="SalesAnalytics_table1___main_Firstttt">
        <div className="SalesAnalytics_table1___main_Firstttt_1">
          <p className="SalesAnalytics_table1___main_Firstttt_1_p1">
            Tickets Sold
          </p>
          <p className="SalesAnalytics_table1___main_Firstttt_1_p2">
            {totalUserCount}
          </p>
        </div>

        <div className="SalesAnalytics_table1___main_Firstttt_2">
          <img src="/Payout_Images/1.svg" alt=".." />
        </div>
      </div>

      <TableContainer
        style={{
          display: isExpanded ? "flex" : "none",
        }}
        component={Paper}
        className="SalesAnalytics_table1___MAIN___11"
      >
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow className="SalesAnalytics_table1___MAIN">
              <TableCell className="SalesAnalytics_table1___Ptags">#</TableCell>
              <TableCell className="SalesAnalytics_table1___Ptags">
                Ticket Type
              </TableCell>
              <TableCell className="SalesAnalytics_table1___Ptags">
                {" "}
                Sold
              </TableCell>
              <TableCell className="SalesAnalytics_table1___Ptags">
                Percentage
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell
                colSpan={4}
                style={{
                  height: "16px",
                  backgroundColor: "transparent",
                  borderBottom: "none",
                  padding: "0px",
                }}
              ></TableCell>
            </TableRow>

            {ticketTypes?.map((row, index) => (
              <TableRow key={row.id}>
                <TableCell
                  className="SalesAnalytics_table1___Ptags2"
                  style={{ width: "70px" }}
                >
                  {index}
                </TableCell>
                <TableCell
                  className="SalesAnalytics_table1___Ptags2"
                  style={{ width: "320px" }}
                >
                  {row?.selectedEventTicketType}
                </TableCell>
                <TableCell
                  className="SalesAnalytics_table1___Ptags2"
                  style={{ width: "213px" }}
                >
                  {(() => {
                    const countTickets = row?.rsvpCapacity
                      ? row?.originalNoOfTickets - row?.rsvpCapacity
                      : row?.originalNoOfTickets - row?.noOfTickets;

                    console.log("Count of tickets is:", countTickets);

                    return countTickets ? countTickets : 0;
                  })()}
                </TableCell>
                <TableCell
                  className="SalesAnalytics_table1___Ptags2"
                  style={{ width: "233px" }}
                >
                  {(
                    ((row?.rsvpCapacity
                      ? row?.originalNoOfTickets - row?.rsvpCapacity
                      : row?.originalNoOfTickets - row?.noOfTickets) /
                      row?.originalNoOfTickets) *
                    100
                  ).toFixed(2)}
                  %
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="expandimge____">
        <img
          src={
            isExpanded
              ? "/Payout_Images/minimize.svg"
              : "/Payout_Images/Back - Button.svg"
          }
          alt=""
          style={{ cursor: "pointer", position: "absolute" }}
          onClick={handleToggle}
        />
      </div>
    </div>
  );
};

export default SalesAnalytics_table1;
