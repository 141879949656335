import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getFollowerDetail } from "../../services/redux/middleware/getFollowerDetail";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";
import "./Organizer_Detail_Follower.css";
import ScreenLoader from "../loader/screenLoader";

const Organizer_Detail_Follower = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const { organizerId } = useParams();
  const [followersDetailData, setFollowersDetailData] = useState([]);
  const followersData = useSelector(
    (state) =>
      state?.getFollowerDetail?.userCollectiblesData?.data?.data
        ?.followerDetails
  );

  useEffect(() => {
    setLoading(true);
    dispatch(getFollowerDetail({ userId: organizerId }));
  }, [dispatch, organizerId]);

  useEffect(() => {
    if (followersData) {
      setFollowersDetailData(followersData || []);
    }
    setLoading(false);
  }, [followersData]);
  console.log("followrrs data is ..", followersDetailData);
  const headers = [
    "Follower Name",
    "Email",
    "Bought Tickets",
    "Attended Events",
    "Following",
  ];
  const data = followersDetailData?.map((row) => [
    row?.name || "---",
    row?.email || "---",
    row?.ticketsBought || "---",
    row?.attendedEvents || "---",
    followersDetailData?.length || "---",
  ]);

  const generateCSV = () => {
    if (!followersDetailData || followersDetailData.length === 0) {
      toast.error("No data available to export!");
      return;
    }

    const csvContent = [
      headers.join(","),
      ...data.map((row) => row.join(",")),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "followers_data.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <>
      {loading && <ScreenLoader />}
      <div style={{ paddingBottom: "100px" }}>
        <div className="export-button">
          <button
            style={{ marginTop: "0px", cssText: "margin-top: 0px !important;" }}
            onClick={generateCSV}
            className="Organizer_detail_Follower__Btn"
          >
            Export
          </button>
        </div>
        <div className="personal-info-detail-container-----sj">
          {followersDetailData?.length === 0 ? (
            <div className="empty__state__main__cintainer__2">
              <Typography
                className="empty_state_text"
                variant="h6"
                sx={{
                  marginBottom: "16px",
                  fontSize: "1rem",
                  fontWeight: 500,
                }}
              >
                There’s No Followers
              </Typography>
            </div>
          ) : (
            <TableContainer
              component={Paper}
              className="Organizer_detail_Follower_Main"
            >
              <Table sx={{ minWidth: 750 }} aria-label="simple table">
                <TableHead
                  sx={{
                    backgroundColor: "rgba(255, 255, 255, 0.06)",
                    width: "275px",
                    borderBottom: "1px solid #292929",
                  }}
                  className="to-apply-table-border-color"
                >
                  <TableRow>
                    <TableCell className="Organizer_detail_Follower_Main___Row">
                      Follower Name
                    </TableCell>
                    <TableCell className="Organizer_detail_Follower_Main___Row">
                      Email
                    </TableCell>
                    <TableCell className="Organizer_detail_Follower_Main___Row">
                      Bought Tickets
                    </TableCell>
                    <TableCell className="Organizer_detail_Follower_Main___Row">
                      Attended Events
                    </TableCell>
                    <TableCell className="Organizer_detail_Follower_Main___Row">
                      Following
                    </TableCell>
                  </TableRow>
                </TableHead>

                {/* Spacer row for gap */}
                <TableRow sx={{ height: "16px", backgroundColor: "#030303" }}>
                  <TableCell colSpan={5}></TableCell>
                </TableRow>

                <TableBody>
                  {followersDetailData?.map((row, index) => (
                    <TableRow
                      key={index}
                      className="Organizer_detail_Follower_Main___Body_Top"
                    >
                      <TableCell className="Organizer_detail_Follower_Main___Body">
                        {row?.name ? row?.name : "---"}
                      </TableCell>
                      <TableCell className="Organizer_detail_Follower_Main___Body1">
                        {row?.email ? row?.email : "---"}
                      </TableCell>
                      <TableCell className="Organizer_detail_Follower_Main___Body1">
                        {row?.ticketsBought ? row?.ticketsBought : "0"}
                      </TableCell>
                      <TableCell className="Organizer_detail_Follower_Main___Body1">
                        {row?.attendedEvents ? row?.attendedEvents : "0"}
                      </TableCell>
                      <TableCell className="Organizer_detail_Follower_Main___Body1">
                        {followersDetailData?.length
                          ? followersDetailData?.length
                          : "---"}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>
      </div>
    </>
  );
};

export default Organizer_Detail_Follower;
