import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../../apiInterceptor";
import { API_URL } from "../../client";

export const getTicketsByEventNTicketIndex = createAsyncThunk("getTcketByIndexNEventID", async (data) => {
  const { eventId, ticketIndex } = data;
  try {
    console.log("inside getTcketByIndexNEventID");
    const res = await api.get(`${API_URL}/event/getTicketByEventId/${eventId}/${ticketIndex}`);
    console.log("inside getTcketByIndexNEventID", res);
    return {
      status: res?.status,
      data: res?.data?.data,
    };
  } catch (error) {
    return {
      message: error?.response?.data?.error,
      status: error?.response?.status,
    };
  }
});

export const getRsvpTickets = createAsyncThunk("getRsvpTickets", async (data ) => {
  try {
    console.log("inside getRsvpTickets");
    const res = await api.get(`${API_URL}/event/getRsvpTickets/${data}`);
    console.log("inside getRsvpTickets", res);
    return {
      status: res?.status,
      data: res?.data,
    };
  } catch (error ) {
    return {
      message: error?.response?.data?.error,
      status: error?.response?.status,
    };
  }
});

export const getDashBoardData = createAsyncThunk("getDashBoardData", async (data ) => {
  try {
    console.log("inside getDashBoardData");
    const res = await api.get(`${API_URL}/event/DashBoard-data/${data}`);
    console.log("inside getDashBoardData", res);
    return {
      status: res?.status,
      data: res?.data,
    };
  } catch (error ) {
    return {
      message: error?.response?.data?.error,
      status: error?.response?.status,
    };
  }
});
