import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  pushNotifications,
  uploadImage,
} from "../../services/redux/middleware/pushNotifications";
import "./pushNotifications.css";
import ScreenLoader from "../../Components/loader/screenLoader";

function PushNotifications() {
  const fileInputRef = useRef(null);
  const dispatch = useDispatch();

  const [title, setTitle] = useState("");
  const [details, setDetails] = useState("");
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [loading,setLoading]=useState('')

  const handleIconClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const isImage = file.type.startsWith("image/");
      if (!isImage) {
        toast.error("Please upload a valid image file.");
        return;
      }
      const previewUrl = URL.createObjectURL(file);
      setImagePreview(previewUrl);

      dispatch(uploadImage(file)).then((res) => {
        if (res?.payload?.data) {
          setImage(res?.payload?.data);
        } else {
          toast.error("Failed to upload image");
        }
      });
    }
  };

  const handleCancelImage = () => {
    setImage(null);
    setImagePreview(null);
    fileInputRef.current.value = "";
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    if (!title || !details) {
      toast.error("Please fill in all required fields.");
      return;
    }
  
    try {
      setLoading(true); 
      const data = { title, details, image };
      console.log("Push notifications data:", data);
  
      const result = await dispatch(pushNotifications(data));
  
      if (result.payload) {
        toast.success("Push notification sent");
        setTitle("");
        setDetails("");
        setImage(null);
        setImagePreview(null);
      } else {
        toast.error(result.payload?.message || "Failed to add notification");
      }
    } catch (error) {
      toast.error(error.message || "An unexpected error occurred");
    } finally {
      setLoading(false); 
    }
  };
  return (
    <>
     {loading && <ScreenLoader />}
    <div className="push__notifications__main_div">
      <div className="push__notifications____div">
        <div className="notifcation__title__container">
          <div className="notification__title__heading_container">
            <p className="notification__title_heading">NOTIFICATION TITLE</p>
            <input
              className="title__input__field"
              type="text"
              placeholder="Enter Title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </div>
        </div>
        <div className="notifcation__detail__container">
          <div className="notification__title__heading_container_1">
            <p className="notification__details_heading">
              NOTIFICATION DETAILS
            </p>
            <textarea
              className="details__input__field"
              placeholder="Enter Details"
              value={details}
              onChange={(e) => setDetails(e.target.value)}
              required
            />
          </div>
        </div>

        <div className="upload__image__container">
          <div className="upload__image__container_inner">
            <div>
              <p className="image__text__main">
                IMAGE <span className="optional__text">(Optional)</span>
              </p>
              <p className="upload__image__text">Upload Image</p>
            </div>
            <img
              src="/Images/PushNotifications/uploadIcon.svg"
              alt="upload"
              onClick={handleIconClick}
              style={{ cursor: "pointer" }}
            />
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              accept="image/*"
              onChange={handleFileChange}
            />
          </div>

          {imagePreview && (
            <div
              className="image-preview-container"
              style={{ position: "relative", display: "inline-block" }}
            >
              <img
                src={imagePreview}
                alt="Preview"
                className="image-preview"
                style={{
                  width: "50px",
                  height: "50px",
                  objectFit: "cover",
                  borderRadius: "8px",
                }}
              />
              <span
                onClick={handleCancelImage}
                style={{
                  position: "absolute",
                  top: "-10px",
                  right: "-10px",
                  background: "#00d059",
                  color: "#030303",
                  width: "20px",
                  height: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "50%",
                  cursor: "pointer",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                &times;
              </span>
            </div>
          )}
        </div>
      </div>

      <button onClick={handleSubmit} className="send__button__main">
        Send
      </button>
    </div>
    </>
  );
}

export default PushNotifications;
