import React, { useState, useEffect } from "react";
import User_Updated_Table from "../User_Updated_Table/User_Updated_Table";
import Updated_Scanner_Updated_Table from "../Updated_Scanner_Updated_Table/Updated_Scanner_Updated_Table";
import "./user.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Avatar from "../../Assets/Dashboard/Avatar.svg";
import View from "../../Assets/Dashboard/eye.svg";
import SalesChart from "../../Components/SalesAnalyst_Graph/SalesAnalyst_Graph";
import { useLocation, useNavigate } from "react-router-dom";
import User_Scanner_Scanner from "../../Components/User_Scanner_Scanner/User_Scanner_Scanner";

function Users() {
  // Combined inline styles for the parent container
  const containerStyle = {
    borderWidth: "0px",
    borderStyle: "solid",
    borderImageSlice: 1,
    borderImageSource:
      "linear-gradient(180deg, rgba(251, 251, 251, 0.6) 0%, rgba(251, 251, 251, 0.7) 100%)",
    borderRadius: "0.5rem",
    backgroundColor: "transparent",
    height: "100vh",
    overflowY: "auto",
    paddingBottom: "50px",
  };

  const scrollbarStyles = `
    ::-webkit-scrollbar {
      width: 5px;
    }
    ::-webkit-scrollbar-track {
      background: transparent;
    }
    ::-webkit-scrollbar-thumb {
      background: #13FF7A;
      border-radius: 5px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #13ff79b9;
    }
  `;
  const location = useLocation();
  const [selectedView, setSelectedView] = useState(
    () => localStorage.getItem("selectedView") || "Simple User"
  );
  useEffect(() => {
    localStorage.setItem("selectedView", selectedView);
  }, [selectedView]);
  const [act, setAct] = useState(
    () => localStorage.getItem("activeTab") || "A"
  );

  useEffect(() => {
    localStorage.setItem("activeTab", act);
  }, [act]);

  const handleButtonClick = (view) => {
    setSelectedView(view);
  };

  const handleBtnClick = (active) => {
    setAct(active);
  };

  const isActive = (path) => location?.pathname === path;

  useEffect(() => {
    // Scroll to the top of the page whenever the selectedView or active tab changes
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [selectedView, act, location.pathname]);

  return (
    <div className="UsersUsers" style={containerStyle}>
      <style>{scrollbarStyles}</style>
      <div className="UsersButtons">
        <button
          className="simpleUserButton"
          style={{
            background:
              selectedView === "Simple User"
                ? "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%) padding-box, linear-gradient(167deg, #13FF7A 0.2%, #002B12 50.2%, #13FF7A 100.2%) border-box"
                : "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%) padding-box, linear-gradient(360deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.06) 100%) border-box",
            color: selectedView === "Simple User" ? "#00A849" : "#FFFFFF",
            border: "1px solid transparent",
            whiteSpace: "nowrap",
          }}
          onClick={() => handleButtonClick("Simple User")}
        >
          Simple User
        </button>
        <button
          className="simpleUserButton"
          style={{
            background:
              selectedView === "Organiser"
                ? "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%) padding-box, linear-gradient(167deg, #13FF7A 0.2%, #002B12 50.2%, #13FF7A 100.2%) border-box"
                : "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%) padding-box, linear-gradient(360deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.06) 100%) border-box",
            color: selectedView === "Organiser" ? "#00A849" : "#FFFFFF",
            border: "1px solid transparent",
          }}
          onClick={() => handleButtonClick("Organiser")}
        >
          Organiser
        </button>
        <button
          className="simpleUserButton"
          style={{
            background:
              selectedView === "Scanner"
                ? "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%) padding-box, linear-gradient(167deg, #13FF7A 0.2%, #002B12 50.2%, #13FF7A 100.2%) border-box"
                : "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%) padding-box, linear-gradient(360deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.06) 100%) border-box",
            color: selectedView === "Scanner" ? "#00A849" : "#FFFFFF",
            border: "1px solid transparent",
          }}
          onClick={() => handleButtonClick("Scanner")}
        >
          Scanner
        </button>
      </div>
      {selectedView === "Simple User" && (
        <>
          <User_Updated_Table />
          {/* <button className="Export">Export</button> */}
        </>
      )}
      {selectedView === "Organiser" && (
        <>
          <User_Scanner_Scanner />
          {/* <Updated_Scanner_Updated_Table/> */}
          {/* <button className="Export">Export</button> */}
        </>
      )}
      {selectedView === "Scanner" && (
        <>
          <Updated_Scanner_Updated_Table />
          {/* <button className="Export">Export</button> */}
        </>
      )}
    </div>
  );
};

export default Users;
