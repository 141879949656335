import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography
} from "@mui/material";
import "./RequestS_Details_Executive.css";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getKycById } from "../../services/redux/middleware/getKycById";
import { approveStatus } from "../../services/redux/middleware/approveStatus";
import ScreenLoader from "../loader/screenLoader";

const RequestS_Details_Executive = () => {
  const { kycId } = useParams();
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const kycDataById = useSelector(
    (state) => state?.getKycById?.kycRequestData?.data?.data
  );

  useEffect(() => {
    setLoading(true);
    dispatch(getKycById({ userId: kycId })).finally(() => setLoading(false));
  }, [dispatch, kycId]);

  const handleApprove = async () => {
  
    if (kycDataById && Array.isArray(kycDataById)) {
      try {
        setLoading(true);
        let approvalSuccess = false;
  
        for (const record of kycDataById) {
          if (record?.userId) {
            const data = { userId: record.userId, approved: 1 };
            console.log("Approving status with data:", data);
  
            const result = await dispatch(approveStatus(data));
            console.log("Dispatch result:", result);
  
            if (result.type === "approveStatus/fulfilled") {
              if (result.payload?.status === 201) {
                approvalSuccess = true;
              }
            }
          }
        }
  
        if (approvalSuccess) {
          toast.success("Approved successfully");
        } else {
          toast.success("Approved successfully");
        }
      } catch (error) {
        console.error("Error during approval:", error);
        toast.error(error.message || "An unexpected error occurred");
      } finally {
        setLoading(false); 
      }
    } else {
      toast.error("No KYC data available for approval");
    }
  };

  const handleDeny = () => {
    toast.error("KYC details not approved");
  };

  const hasExecutives =
    kycDataById &&
    kycDataById.some(
      (data) =>
        data?.Executive && Array.isArray(data.Executive) && data.Executive.length > 0
    );

  return (
    <>
      {loading && <ScreenLoader />}
      <div>
        {!hasExecutives && (
          <div className="empty__state__main__cintainer__2">
          <Typography variant="h6" className="empty_state_text">
          There’s no Executive
          </Typography>
        </div>
        )}
        {hasExecutives && (
          <div className="RequestS_Details_Representative_divlast">
            <button
              onClick={handleApprove}
              className="RequestS_Details_Representative_divlastBtn1"
            >
              Approve
            </button>
            <button
              onClick={handleDeny}
              className="RequestS_Details_Representative_divlastBtn2"
            >
              Deny
            </button>
          </div>
        )}
        <TableContainer
          component={Paper}
          className="Organizer_detail_Follower_Main"
        >
          <Table sx={{ maxWidth: 800 }} aria-label="simple table">
            {kycDataById && kycDataById.length > 0 && (
              <TableHead>
                <TableRow style={{ backgroundColor: "rgba(255, 255, 255, 0.06)" }}>
                  <TableCell className="Organizer_detail_Follower_Main___Row">
                    Name
                  </TableCell>
                  <TableCell className="Organizer_detail_Follower_Main___Row">
                    Relationship
                  </TableCell>
                </TableRow>
              </TableHead>
            )}
            {kycDataById && kycDataById.length > 0 && (
              <TableRow sx={{ height: "0px", backgroundColor: "#030303" }}>
                <TableCell colSpan={6}></TableCell>
              </TableRow>
            )}
            <TableBody>
              {kycDataById && kycDataById.length > 0
                ? kycDataById.map((data, index) => {
                    if (
                      data?.Executive &&
                      Array.isArray(data.Executive) &&
                      data.Executive.length > 0
                    ) {
                      return data.Executive.map((executive, executiveIndex) => (
                        <TableRow
                          key={`${index}-${executiveIndex}`}
                          className="Organizer_detail_Follower_Main___Body_Top"
                        >
                          <TableCell
                            className="Organizer_detail_Follower_Main___Body"
                            sx={{
                              "@media (max-width:399px)": {
                                fontSize: "10px !important",
                              },
                            }}
                          >
                            {`${executive.FirstName || "----"} ${
                              executive.LastName || "----"
                            }`}
                          </TableCell>
                          <TableCell
                            className="Organizer_detail_Follower_Main___Body1"
                            sx={{
                              "@media (max-width:399px)": {
                                fontSize: "10px !important",
                              },
                            }}
                          >
                            {executive.relationship || "----"}
                          </TableCell>
                        </TableRow>
                      ));
                    } else {
                      return null;
                    }
                  })
                : null}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};

export default RequestS_Details_Executive;
