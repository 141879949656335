
export const API_URL = "https://something.dotskulls.com"
// export const API_URL = "http://142.93.205.99:3001/"

// export const API_URL = "https://stagingbe.dotskulls.com"





