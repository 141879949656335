import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import "./kycApprovalDetail.css";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
} from "@mui/material";
import { Check, Close } from "@mui/icons-material";

import { useTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { getKycApproval } from "../../services/redux/middleware/getKyc";
import useMediaQuery from "@mui/material/useMediaQuery";
import Kyc__Approval__Representative from "../KycApprovalRepresentative/kycApprovalRepresentative";
import Kyc_Details_Owners from "../Kyc__Approval__Owners/kyc__Approval_owner";
import Kyc_Details_Executive from "../Kyc__Approval__Executive/Kyc__Approval__Executive";
import { getKycById } from "../../services/redux/middleware/getKycById";
import { Spinner } from "react-bootstrap";
import ScreenLoader from "../loader/screenLoader";

function KycApprovalDetailTable() {
  const { userId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [act, setact] = useState("A");
  const [individualData, setIndividualData] = useState([]);
  const [businessData, setbusinessData] = useState([]);
  const handlebtnclick = (active) => {
    setact(active);
  };
  const navigate2 = useNavigate();
  const [loading, setLoading] = useState(true);
  console.log("Received user id is............", userId);

  const KycDataildata = useSelector(
    (state) => state?.getKycById?.kycRequestData?.data?.data
  );

  useEffect(() => {
    setLoading(true);
    if (userId) {
      dispatch(getKycById({ userId })).finally(() => {
        setLoading(false);
      });
    } else {
      console.log("User ID is not defined");
      setLoading(false);
    }
  }, [dispatch, userId]);

  useEffect(() => {
    if (KycDataildata?.length) {
      const individualData = KycDataildata.filter(
        (item) => item.userType === "Individual"
      );
      setIndividualData(individualData);
      const businessData = KycDataildata.filter(
        (item) => item.userType === "Business"
      );
      setbusinessData(businessData);
      const RepresentativeData = KycDataildata.filter(
        (item) => item.userType === "Representative"
      );
      const OwnerData = KycDataildata.filter(
        (item) => item.userType === "Owner"
      );
      const ExecutiveData = KycDataildata.filter(
        (item) => item.userType === "Executive"
      );

      console.log("Individual Data:", individualData);

      console.log("Business Data:", businessData);
      console.log("Represntative Data.", RepresentativeData);
      console.log("Owner data ", OwnerData);
      console.log("Ecxecutive data ", ExecutiveData);
    }
  }, [KycDataildata]);

  console.log("KYC detail data is ...", KycDataildata);

  const goBack = () => {
    navigate2(-1);
  };

  const isActive = (path) => location.pathname === path;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <div className="personal-info-detail-container___________newchnge">
      <div className="Organiser_Detail_HostedEvents_Sales_Analytics_Main_heading_and_image__owners">
        <img src="/Payout_Images/graphlogo.svg" onClick={goBack} />
        <h2>KYC Details</h2>
      </div>
      <div className="top-buttons___new_try">
        <button
          style={{
            background:
              act === "A"
                ? "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)padding-box, linear-gradient(167deg, #13FF7A 0.2%, #002B12 50.2%, #13FF7A 100.2%)border-box "
                : "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)padding-box, linear-gradient(360deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.06) 100%)border-box",

            color: act === "A" ? "#00A849" : "#FFFFFF",
            border:
              act === "A" ? "1px solid  transparent" : "1px solid  transparent",
          }}
          className={isActive("/personal-information") ? "active" : ""}
          onClick={() => handlebtnclick("A")}
        >
          Individual
        </button>
        <button
          style={{
            background:
              act === "B"
                ? "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)padding-box, linear-gradient(167deg, #13FF7A 0.2%, #002B12 50.2%, #13FF7A 100.2%)border-box "
                : "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)padding-box, linear-gradient(360deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.06) 100%)border-box",

            color: act === "B" ? "#00A849" : "#FFFFFF",
            border:
              act === "B" ? "1px solid  transparent" : "1px solid  transparent",
          }}
          className={isActive("/event-tickets") ? "active" : ""}
          onClick={() => handlebtnclick("B")}
        >
          Business
        </button>
        <button
          style={{
            background:
              act === "C"
                ? "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)padding-box, linear-gradient(167deg, #13FF7A 0.2%, #002B12 50.2%, #13FF7A 100.2%)border-box "
                : "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)padding-box, linear-gradient(360deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.06) 100%)border-box",

            color: act === "C" ? "#00A849" : "#FFFFFF",
            border:
              act === "C" ? "1px solid  transparent" : "1px solid  transparent",
          }}
          className={isActive("/nft-rewards") ? "active" : ""}
          onClick={() => handlebtnclick("C")}
        >
          Representative
        </button>
        <button
          style={{
            background:
              act === "D"
                ? "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)padding-box, linear-gradient(167deg, #13FF7A 0.2%, #002B12 50.2%, #13FF7A 100.2%)border-box "
                : "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)padding-box, linear-gradient(360deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.06) 100%)border-box",

            color: act === "D" ? "#00A849" : "#FFFFFF",
            border:
              act === "D" ? "1px solid  transparent" : "1px solid  transparent",
          }}
          className={isActive("/collectibles-owned") ? "active" : ""}
          onClick={() => handlebtnclick("D")}
        >
          Owners
        </button>
        <button
          style={{
            background:
              act === "E"
                ? "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)padding-box, linear-gradient(167deg, #13FF7A 0.2%, #002B12 50.2%, #13FF7A 100.2%)border-box "
                : "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)padding-box, linear-gradient(360deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.06) 100%)border-box",

            color: act === "E" ? "#00A849" : "#FFFFFF",
            border:
              act === "E" ? "1px solid  transparent" : "1px solid  transparent",
          }}
          className={isActive("/photo-roll") ? "active" : ""}
          onClick={() => handlebtnclick("E")}
        >
          Executive
        </button>
      </div>

      {act === "A" && (
        <div
          style={{
            paddingLeft: "0px",
            cssText: "padding-left: 0px !important;",
          }}
          className="given-to-the-width-to-all"
        >
          <Box>
            <Box
              sx={{
                height: "50px",
                backgroundColor: "#000",
                mb: "16px",
                width: "1250px",
              }}
            ></Box>

            {loading ? (
              <ScreenLoader />
            ) : !individualData ||
              individualData.length === 0 ||
              !individualData.some((row) => row?.Individual?.length > 0) ? (
              <div className="empty__state__main__cintainer__1">
                <Typography className="empty_state_text" variant="h6">
                  There’s No Individual
                </Typography>
              </div>
            ) : (
              <TableContainer
                component={Paper}
                sx={{
                  backgroundColor: "rgba(255, 255, 255, 0.06)",
                  overflowX: "auto",
                }}
              >
                <Table>
                  <TableHead
                    sx={{ backgroundColor: "rgba(255, 255, 255, 0.06)" }}
                    className="to-apply-table-border-color"
                  >
                    <TableRow>
                      <TableCell
                        sx={{ borderBottom: "1px solid #292929" }}
                        className="even-name-text-style-for"
                      >
                        Name
                      </TableCell>
                      <TableCell
                        sx={{ borderBottom: "1px solid #292929" }}
                        className="even-name-text-style-for"
                      >
                        Email
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: "1px solid rgba(41, 41, 41, 1)",
                        }}
                        className="even-name-text-style-for"
                      >
                        DOB
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: "1px solid rgba(41, 41, 41, 1)",
                        }}
                        className="even-name-text-style-for"
                      >
                        Website
                      </TableCell>

                      <TableCell
                        sx={{
                          borderBottom: "1px solid rgba(41, 41, 41, 1)",
                        }}
                        className="even-name-text-style-for"
                      >
                        Address
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: "1px solid rgba(41, 41, 41, 1)",
                        }}
                        className="even-name-text-style-for"
                      >
                        Town/City
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: "1px solid rgba(41, 41, 41, 1)",
                        }}
                        className="even-name-text-style-for"
                      >
                        Country
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: "1px solid rgba(41, 41, 41, 1)",
                        }}
                        className="even-name-text-style-for"
                      >
                        Postal Code
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  {/* Spacer row for gap */}
                  <TableRow sx={{ height: "16px", backgroundColor: "#030303" }}>
                    <TableCell colSpan={7}></TableCell>
                  </TableRow>

                  <TableBody>
                    {individualData?.map((row, index) =>
                      row?.Individual?.map((individual, individualIndex) => (
                        <TableRow
                          key={`${index}-${individualIndex}`}
                          className="main-even-name-text-style-for"
                        >
                          <TableCell
                            sx={{
                              borderBottom: "1px solid #292929",
                              width: "275px",
                            }}
                            className="main-even-name-text-style-for-padding-margin-name"
                          >
                            {individual?.FirstName || individual?.LastName
                              ? `${individual?.FirstName || ""} ${
                                  individual?.LastName || ""
                                }`
                              : "-----"}
                          </TableCell>

                          <TableCell
                            sx={{
                              borderBottom: "1px solid #292929",
                              width: "275px",
                            }}
                            className="main-even-name-text-style-for-padding-margin-type"
                          >
                            {individual?.Email && individual?.Email.length > 20
                              ? `${individual?.Email.slice(0, 20)}...`
                              : individual?.Email || "-----"}
                          </TableCell>

                          <TableCell
                            sx={{
                              borderBottom: "1px solid #292929",
                              width: "275px",
                            }}
                            className="main-even-name-text-style-for-padding-margin-type"
                          >
                            {individual?.DOB || "-----"}
                          </TableCell>

                          <TableCell
                            sx={{
                              borderBottom: "1px solid #292929",
                              width: "275px",
                            }}
                            className="main-even-name-text-style-for-padding-margin-type"
                          >
                            {individual?.organizationWebsite &&
                            individual?.organizationWebsite.length > 20
                              ? `${individual?.organizationWebsite.slice(
                                  0,
                                  20
                                )}...`
                              : individual?.organizationWebsite || "-----"}
                          </TableCell>
                          <TableCell
                            sx={{
                              borderBottom: "1px solid #292929",
                              width: "275px",
                            }}
                            className="main-even-name-text-style-for-padding-margin-type"
                          >
                            {individual?.Address1 &&
                            individual?.Address1.length > 20
                              ? `${individual?.Address1.slice(0, 20)}...`
                              : individual?.Address1 || "----"}
                          </TableCell>
                          <TableCell
                            sx={{
                              borderBottom: "1px solid #292929",
                              width: "275px",
                            }}
                            className="main-even-name-text-style-for-padding-margin-type"
                          >
                            {individual.City || "----"}
                          </TableCell>
                          <TableCell
                            sx={{
                              borderBottom: "1px solid #292929",
                              width: "275px",
                            }}
                            className="main-even-name-text-style-for-padding-margin-type"
                          >
                            {individual.Country || "----"}
                          </TableCell>
                          <TableCell
                            sx={{
                              borderBottom: "1px solid #292929",
                              width: "275px",
                            }}
                            className="main-even-name-text-style-for-padding-margin-type"
                          >
                            {individual.postalCode || "----"}
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Box>
        </div>
      )}

      {act === "B" && (
        <div
          style={{
            paddingLeft: "0px",
            cssText: "padding-left: 0px !important;",
          }}
          className="given-to-the-width-to-all"
        >
          <Box>
            <Box
              sx={{ height: "50px", backgroundColor: "#000", mb: "16px" }}
            ></Box>

            {loading ? (
              <ScreenLoader />
            ) : !businessData || businessData.length === 0 ? (
              <div className="empty__state__main__cintainer__1">
                <Typography className="empty_state_text" variant="h6">
                  There’s No Business
                </Typography>
              </div>
            ) : (
              <TableContainer
                component={Paper}
                sx={{
                  backgroundColor: "rgba(255, 255, 255, 0.06)",
                  overflowX: "auto",
                }}
              >
                <Table>
                  <TableHead
                    sx={{ backgroundColor: "rgba(255, 255, 255, 0.06)" }}
                    className="to-apply-table-border-color"
                  >
                    <TableRow>
                      <TableCell
                        sx={{ borderBottom: "1px solid #292929" }}
                        className="even-name-text-style-for"
                      >
                        Company Name
                      </TableCell>
                      <TableCell
                        sx={{ borderBottom: "1px solid #292929" }}
                        className="even-name-text-style-for"
                      >
                        Business Type
                      </TableCell>
                      <TableCell
                        sx={{ borderBottom: "1px solid rgba(41, 41, 41, 1)" }}
                        className="even-name-text-style-for"
                      >
                        UTR
                      </TableCell>
                      <TableCell
                        sx={{ borderBottom: "1px solid rgba(41, 41, 41, 1)" }}
                        className="even-name-text-style-for"
                      >
                        Website
                      </TableCell>
                      <TableCell
                        sx={{ borderBottom: "1px solid rgba(41, 41, 41, 1)" }}
                        className="even-name-text-style-for"
                      >
                        Address
                      </TableCell>
                      <TableCell
                        sx={{ borderBottom: "1px solid rgba(41, 41, 41, 1)" }}
                        className="even-name-text-style-for"
                      >
                        Town/City
                      </TableCell>
                      <TableCell
                        sx={{ borderBottom: "1px solid rgba(41, 41, 41, 1)" }}
                        className="even-name-text-style-for"
                      >
                        Country
                      </TableCell>
                      <TableCell
                        sx={{ borderBottom: "1px solid rgba(41, 41, 41, 1)" }}
                        className="even-name-text-style-for"
                      >
                        Postal Code
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  {/* Spacer row for gap */}
                  <TableRow sx={{ height: "16px", backgroundColor: "#030303" }}>
                    <TableCell colSpan={7}></TableCell>
                  </TableRow>

                  <TableBody>
                    {businessData?.map((row, index) => (
                      <TableRow
                        key={index}
                        className="main-even-name-text-style-for"
                      >
                        <TableCell
                          sx={{
                            borderBottom: "1px solid #292929",
                            width: "275px",
                          }}
                          className="main-even-name-text-style-for-padding-margin-name"
                        >
                          {row?.Business?.[0]?.companyName || "-----"}
                        </TableCell>

                        <TableCell
                          sx={{
                            borderBottom: "1px solid #292929",
                            width: "275px",
                          }}
                          className="main-even-name-text-style-for-padding-margin-type"
                        >
                          {row?.Business?.[0]?.BusinessType &&
                          row?.Business?.[0]?.BusinessType.length > 20
                            ? `${row?.Business?.[0]?.BusinessType.slice(
                                0,
                                20
                              )}...`
                            : row?.Business?.[0]?.BusinessType || "-----"}
                        </TableCell>
                        <TableCell
                          sx={{
                            borderBottom: "1px solid #292929",
                            width: "275px",
                          }}
                          className="main-even-name-text-style-for-padding-margin-type"
                        >
                          {row?.Business?.[0]?.UTR || "-----"}
                        </TableCell>

                        <TableCell
                          sx={{
                            borderBottom: "1px solid #292929",
                            width: "275px",
                          }}
                          className="main-even-name-text-style-for-padding-margin-type"
                        >
                          {row?.Business?.[0]?.companyWebsite &&
                          row?.Business?.[0]?.companyWebsite.length > 20
                            ? `${row?.Business?.[0]?.companyWebsite.slice(
                                0,
                                20
                              )}...`
                            : row?.Business?.[0]?.companyWebsite || "-----"}
                        </TableCell>

                        <TableCell
                          sx={{
                            borderBottom: "1px solid #292929",
                            width: "275px",
                          }}
                          className="main-even-name-text-style-for-padding-margin-type"
                        >
                          {row?.Business?.[0]?.Address1 &&
                          row?.Business?.[0]?.Address1.length > 20
                            ? `${row?.Business?.[0]?.Address1.slice(0, 20)}...`
                            : row?.Business?.[0]?.Address1 || "-----"}
                        </TableCell>

                        <TableCell
                          sx={{
                            borderBottom: "1px solid #292929",
                            width: "275px",
                          }}
                          className="main-even-name-text-style-for-padding-margin-type"
                        >
                          {row?.Business?.[0]?.City || "-----"}
                        </TableCell>

                        <TableCell
                          sx={{
                            borderBottom: "1px solid #292929",
                            width: "275px",
                          }}
                          className="main-even-name-text-style-for-padding-margin-type"
                        >
                          {row?.Business?.[0]?.Country || "-----"}
                        </TableCell>

                        <TableCell
                          sx={{
                            borderBottom: "1px solid #292929",
                            width: "275px",
                          }}
                          className="main-even-name-text-style-for-padding-margin-type"
                        >
                          {row?.Business?.[0]?.postalCode || "-----"}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Box>
        </div>
      )}

      {act === "C" && <Kyc__Approval__Representative />}
      {act === "D" && <Kyc_Details_Owners />}
      {act === "E" && <Kyc_Details_Executive />}
    </div>
  );
}

export default KycApprovalDetailTable;
