import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
} from "@mui/material";
import { Check, Close } from "@mui/icons-material";
import { toast } from "react-toastify";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import ScreenLoader from "../../Components/loader/screenLoader";

const EventTicket = (event) => {
  const containerStyle = {
    borderWidth: "0px",
    borderStyle: "solid",
    borderImageSlice: 1,
    borderImageSource:
      "linear-gradient(180deg, rgba(251, 251, 251, 0.6) 0%, rgba(251, 251, 251, 0.7) 100%)",
    borderRadius: "0.5rem",
    backgroundColor: "transparent",
    height: "100vh",
    overflowY: "scroll",
    // padding: '20px',
  };

  const scrollbarStyles = `
    ::-webkit-scrollbar {
      width: 5px;
    }
    ::-webkit-scrollbar-track {
      background: transparent;
    }
    ::-webkit-scrollbar-thumb {
      background: #13FF7A;
      border-radius: 5px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #13ff79b9;
    }
  `;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [loading, setLoading] = useState(true);
  const [eventsData, setEventsData] = useState([]);
  console.log("this is event dataaaaaaaaa", eventsData);

  useEffect(() => {
    if (event?.event?.getEventsDetailsData?.data) {
      setEventsData(event?.event?.getEventsDetailsData?.data);
      setLoading(false);
    } else {
      const timer = setTimeout(() => {
        setLoading(false);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [event]);

  console.log("event data is ......", eventsData);

  const convertToCSV = (data) => {
    const headers = [
      "Event Name",
      "Ticket Type",
      "Price",
      "Date",
      "Attended",
      "Check In Time",
    ];
  
    const rows = data.map((item) => [
      item?.event?.dataValues?.name || "-----",
      item?.event?.tickets[item?.isIndex]?.ticketType || "-----",
      item?.event?.tickets[item?.isIndex]?.ticketPrice || "0",
      item?.createdAt ? item.createdAt.split("T")[0] : "-----",
      item?.scan ? "Yes" : "No",
      item?.scan
        ? item?.createdAt
          ? new Date(item.createdAt).toLocaleTimeString("en-GB", {
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
            })
          : "-----"
        : "---",
    ]);
  
    const csvContent = [
      headers.join(","),
      ...rows.map((row) => row.join(",")),
    ].join("\n");
  
    return csvContent;
  };
  
  const downloadCSV = () => {
    if (!eventsData || eventsData.length === 0) {
      toast.error("No data available to export!");
      return;
    }
  
    const csvData = convertToCSV(eventsData);
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
  
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "event_ticket_data.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };
  

  return (
    <>
      {loading && <ScreenLoader />}
      <div style={{ paddingBottom: "100px" }}>
        <div className="export-button">
          <button onClick={downloadCSV}>Export</button>
        </div>
        <div className="personal-info-detail-container-even-ticket">
          <div className="EventTicket-to-give-max-width">
            <Box className="event__ticket__main__div">
              <Box
                sx={{ height: "50px", backgroundColor: "#000", mb: "16px" }}
              ></Box>
              {eventsData?.length === 0 ? (
                <div>
                  <div className="empty__state__main__cintainer__1">
                    <Typography className="empty_state_text" variant="h6">
                      There’s No Event Ticket
                    </Typography>
                  </div>
                </div>
              ) : (
                <TableContainer
                  className="Organizer_detail_Follower_Main_____"
                  component={Paper}
                  sx={{
                    backgroundColor: "rgba(255, 255, 255, 0.06)",
                    overflowX: "auto",
                  }}
                >
                  <Table>
                    {eventsData?.length > 0 && (
                      <TableHead
                        sx={{
                          backgroundColor: "rgba(255, 255, 255, 0.06)",
                          width: "275px",
                          borderBottom: "1px solid #292929 ",
                        }}
                        className="to-apply-table-border-color"
                      >
                        <TableRow className="tble-row-btm">
                          <TableCell
                            sx={{
                              borderBottom: "1px solid #292929",
                              width: "200px",
                            }}
                            className="even-name-text-style-for"
                          >
                            Event Name
                          </TableCell>
                          <TableCell
                            sx={{
                              borderBottom: "1px solid #292929",
                              width: "200px",
                            }}
                            className="even-name-text-style-for"
                          >
                            Ticket Type
                          </TableCell>
                          <TableCell
                            sx={{
                              borderBottom: "1px solid rgba(41, 41, 41, 1)",
                              width: "200px",
                            }}
                            className="even-name-text-style-for"
                          >
                            Price
                          </TableCell>
                          <TableCell
                            sx={{
                              borderBottom: "1px solid rgba(41, 41, 41, 1)",
                              width: "170px",
                            }}
                            className="even-name-text-style-for"
                          >
                            Date
                          </TableCell>
                          <TableCell
                            sx={{
                              borderBottom: "1px solid rgba(41, 41, 41, 1)",
                              width: "170px",
                            }}
                            className="even-name-text-style-for"
                          >
                            Attended
                          </TableCell>
                          <TableCell
                            sx={{
                              borderBottom: "1px solid rgba(41, 41, 41, 1)",
                              width: "195px",
                            }}
                            className="even-name-text-style-for"
                          >
                            Check In Time
                          </TableCell>
                        </TableRow>
                      </TableHead>
                    )}

                    {/* Spacer row for gap */}
                    {eventsData?.length > 0 && (
                      <TableRow
                        sx={{ height: "16px", backgroundColor: "#030303" }}
                      >
                        <TableCell colSpan={6}></TableCell>
                      </TableRow>
                    )}

                    <TableBody
                      style={{
                        background:
                          "linear-gradient(360deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.06) 100%)",
                      }}
                    >
                      {eventsData?.map((items, index) => (
                        <TableRow
                          className="main-even-name-text-style-for"
                          key={index}
                        >
                          <TableCell
                            sx={{
                              borderBottom: "1px solid #292929",
                              width: "275px",
                            }}
                            className="main-even-name-text-style-for-padding-margin-name"
                          >
                            {items?.event?.dataValues?.name
                              ? items?.event?.dataValues?.name
                              : "-----"}
                          </TableCell>
                          <TableCell
                            sx={{
                              borderBottom: "1px solid #292929",
                              width: "200px",
                            }}
                            className="main-even-name-text-style-for-padding-margin-type"
                          >
                            {items?.event?.tickets[items?.isIndex]?.ticketType}
                          </TableCell>
                          <TableCell
                            sx={{
                              borderBottom: "1px solid #292929",
                              width: "200px",
                            }}
                            className="main-even-name-text-style-for-padding-margin-type"
                          >
                            {items?.event?.tickets[items?.isIndex]?.ticketPrice}
                          </TableCell>
                          <TableCell
                            sx={{
                              borderBottom: "1px solid #292929",
                              width: "200px",
                            }}
                            className="main-even-name-text-style-for-padding-margin-type"
                          >
                            {items?.createdAt
                              ? items.createdAt.split("T")[0]
                              : "-----"}
                          </TableCell>
                          <TableCell
                            sx={{
                              borderBottom: "1px solid #292929",
                              width: "170px",
                            }}
                            className="main-even-name-text-style-for-padding-margin-type-false-true"
                          >
                            {items?.scan ? (
                              <Check sx={{ fontSize: 18, color: "#00FF00" }} />
                            ) : (
                              <Close sx={{ fontSize: 18, color: "#FF0000" }} />
                            )}
                          </TableCell>
                          <TableCell
                            sx={{
                              borderBottom: "1px solid #292929",
                              width: "195px",
                            }}
                            className="main-even-name-text-style-for-padding-margin-type"
                          >
                            {items?.scan
                              ? items?.createdAt
                                ? new Date(items.createdAt).toLocaleTimeString(
                                    "en-GB",
                                    {
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      second: "2-digit",
                                    }
                                  )
                                : "-----"
                              : "---"}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Box>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventTicket;
