import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getAllWaitlist } from "../middleware/getAllWaitList";


const initialState = {
  loading: false,
  error: "",
   userData: [],
};
const getAllWaitlistSlice = createSlice({
  name: "getAllWaitlist",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllWaitlist.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllWaitlist.fulfilled, (state, action) => {
      state.loading = false;
      state.userData = action.payload;
    });
    builder.addCase(getAllWaitlist.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error || "something wrong";
    });
  },
});
export default getAllWaitlistSlice.reducer;