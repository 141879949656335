import React from "react";
import Sidebar from "./Components/sidebar";
import Users from "./Pages/User/Users";

import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PersonalInfoDetail from "./Pages/PersonalInfoDetail/PersonalInfoDetail";
import EventTicket from "./Pages/EventTicket/EventTicket";
import MrtRewards from "./Pages/MRTRewards/MrtRewards";
import CollectiblesOwned from "./Pages/CollectiblesOwned/CollectiblesOwned";
import PhotoRoll from "./Pages/PhotoRoll/PhotoRoll";
import LikedEvents from "./Pages/LikedEvents/LikedEvents";
import FollowingTicket from "./Pages/FollowingTickets/FollowingTicket";

import Organizer_Detail_Follower from "./Components/Organizer_Detail_follower/Organizer_Detail_Follower";
import Organizer_Detail_Payout from "./Components/Organizer_Detail_Payout/Organizer_Detail_Payout";
import Organizer_Detail_Scanner_login from "./Components/Organizer_Detail_Scanner_login/Organizer_Detail_Scanner_login";
import Organiser_Detail_Liked_Events from "./Components/Organiser_Detail_Liked_Events/Organiser_Detail_Liked_Events";
import PersonalInfoUserOrganizer from "./Pages/Personal-Info-User-Organizer/PersonalInfoUserOrganizer";
import Attendeed_Hosted_Events from "./Components/Attendees_Hosted_Events/Attendees_Hosted_Events";
import Hosted_Events_Attendeed_main from "./Components/Hosted_Events_Attendeed_main/Hosted_Events_Attendeed_main";
// import HostedEventsOrganizerDetail from "./Components/HostedEventsOrganizerDetail/HostedEventsOrganizerDetail";
import SalesAnalytics_table1 from "./Components/SalesAnalytics_table1/SalesAnalytics_table1";
import SalesAnalytics_table2__2 from "./Components/SalesAnalytics_table2__/SalesAnalytics_table2__2";
import SalesAnalytics_table_3 from "./Components/SalesAnalytics_table_3/SalesAnalytics_table_3";
import Organiser_Detail_HostedEvents_Sales_Analytics from "./Pages/Organiser_Detail_HostedEvents_Sales_Analytics/Organiser_Detail_HostedEvents_Sales_Analytics";
import User_Scanner____Details from "./Components/User_Scanner____Details/User_Scanner____Details";
import Organiser_Detail_Liked_Events__Table from "./Components/Organiser_Detail_Liked_Events__Table/Organiser_Detail_Liked_Events__Table";
import KycAndAproval from "./Components/KycAndAprovalTable/KycAndAproval";
import KycIndivialDetailTable from "./Components/KycIndivialDetail/KycIndivialDetailTable";
import BusnesDetailTable from "./Components/KycBusnesDetail/BusnesDetailTable";
import Kyc_Updated_Request_Approval from "./Components/Kyc_Updated_Request_Aproval/Kyc_Updated_Request_Approval";
import New_Updated_Payout_GetPaid from "./Components/New_Updated_Payout_GetPaid/New_Updated_Payout_GetPiad";
import GetPaidRequest from "./Pages/PayOuts/GetPaidRequests";
import PayOutAccount from "./Pages/PayOuts/payoutAccount";
import GetHistory from "./Pages/PayOuts/payOutHistory";
import After___QA__PayoutHistory1 from "./Components/After___QA__PayoutHistory1/After___QA__PayoutHistory1";
import After___QA__PayoutHistory2 from "./Components/After___QA__PayoutHistory2/After___QA__PayoutHistory2";
import GetPaidRequest_After_Qa from "./Components/GetPaidRequest_After_Qa/GetPaidRequest_After_Qa";
import GetPaidRequest_After_Qa2 from "./Components/GetPaidRequest_After_Qa2/GetPaidRequest_After_Qa2";
import Signed_In_Main from "./Components/Signed_In_Main/Signed_In_Main";
import PushNotifications from "./Pages/PushNotifications/pushNotifications";
import FeedbackPage from "./Pages/Feedback/feedbackPage";
import ReportPage from "./Pages/Reports/Report";
// import User_Updated_Table from "./Pages/User_Updated_Table/User_Updated_Table";
// import Updated_Scanner_Updated_Table from "./Pages/Updated_Scanner_Updated_Table/Updated_Scanner_Updated_Table";
import Pizdez_table from "./Components/Pizdez-maintable/Pizdez_table";
import Resaon_report from "./Components/Resaon_report/Resaon_report";
import Feedback_Pizdez_table from "./Components/Fedback_pizdez_table/Feedback_Pizdez_table";
import StaticExample from "./Components/Feedbackpopup/Feedbackpopup";
import SupportRequest from "./Pages/SupportRequest/supportRequest";
import SupportRequestDetail from "./Pages/SupportRequestDetail.js/supportRequestDetail";
import GetSponsored from "./Pages/getSponseored/getSponsored";
import GetSponsoredRequest from "./Pages/getSponsoredRequests/getSponsoredRequest";
import Waitlist from "./Pages/Waitlist/waitlist";
import WaitlistTable from "./Pages/WaitListDetailTable/waitlistTable";
import KycApprovalDetailTable from "./Components/KycApprovalDetails/kycApprovalDetails";
import Launch__party from "./Components/Launch__party/Launch__party";
import Sales_RvspTicketing from "./Components/SalesAnalytics_RvspTicketing/Sales_RvspTicketing";
import Festival_Muktitasks from "./Components/Festivals__MultiTasks/Festival_Muktitasks";
import Private_event_ticketing from "./Components/Private_event_ticketing/Private_event_ticketing";
import Event_ticketing_attendence from "./Components/Event_ticketing_attendence/Event_ticketing_attendence";
import Event_ticketing_users from "./Components/Event_ticketing_users/Event_ticketing_users";
import Festivals__Multitasks__attendence from "./Components/Festivals__Multitasks__attendence/Festivals__Multitasks__attendence";
import Festivals__Multitasks__users from "./Components/Festivals__Multitasks__users/Festivals__Multitasks__users";
import PrivateEventTicketing from "./Components/SalesAnalytics_PrivateTicketing/PrivateTicketing";
import FestivalEventTicketing from "./Components/SalesAnalytics_FestivalTicketing/FestivalTicketing";
import PasswordEvents from "./Components/PasswordedEvents/passwordEvents";
import CustomTickets from "./Components/Custom_Tickets/customTickets";
import WhiteListTicketing from "./Components/WaitListTicketing/whitetListTicketing";

function App() {
  return (
    <Router>
      <Routes>
        {/* <Sidebar>
         <Route path="/" element={<User />} />
         </Sidebar> */}
        <Route path="/whitelist_ticketing" element={<WhiteListTicketing />} />
        <Route path="/private_ticketing" element={<PrivateEventTicketing />} />
        <Route path="/customer-ticket" element={<CustomTickets />} />
        <Route path="/passworded-event" element={<PasswordEvents />} />
        <Route path="/Feedbackpopup" element={<StaticExample />} />
        <Route
          path="/FeedbackPizdeztable/:eventId"
          element={<Feedback_Pizdez_table />}
        />
        <Route
          path="/kyc_Approval__table/:userId"
          element={<KycApprovalDetailTable />}
        />
        <Route path="/Pizdeztable/:eventId" element={<Pizdez_table />} />
        <Route
          path="/support_request_detail/:id"
          element={<SupportRequestDetail />}
        />
        <Route path="/Resaon_report" element={<Resaon_report />} />
        <Route path="/Waitlist_Detail/:eventId" element={<WaitlistTable />} />
        <Route
          path="/Wait_List"
          element={
            <Sidebar>
              <Waitlist />
            </Sidebar>
          }
        />
        <Route
          path="/Requests_Sponsored/:id"
          element={<GetSponsoredRequest />}
        />
        <Route
          path="/Sponsores"
          element={
            <Sidebar>
              <GetSponsored />
            </Sidebar>
          }
        />
        <Route
          path="/feedbackPage"
          element={
            <Sidebar>
              <FeedbackPage />{" "}
            </Sidebar>
          }
        />
        <Route
          path="/PersonalInfoDetail/:id"
          element={<PersonalInfoDetail />}
        />
        <Route path="/Sales_Rvsp_ticketing" element={<Sales_RvspTicketing />} />
        <Route
          path="/Festivals_Multitask__tickets"
          element={<FestivalEventTicketing />}
        />
        <Route
          path="/Support_request"
          element={
            <Sidebar>
              <SupportRequest />
            </Sidebar>
          }
        />
        <Route path="/eventticket" element={<EventTicket />} />
        <Route path="/mrtreward" element={<MrtRewards />} />
        <Route path="/Collectibles" element={<CollectiblesOwned />} />
        <Route path="/photoroll" element={<PhotoRoll />} />
        <Route path="/likedevents" element={<LikedEvents />} />
        <Route path="/followingticket" element={<FollowingTicket />} />
        <Route
          path="/report"
          element={
            <Sidebar>
              <ReportPage />{" "}
            </Sidebar>
          }
        />
        <Route path="/Launch-party/:eventId" element={<Launch__party />} />
        {/* <Route path="/KycAndAproval" element={<KycAndAproval />} /> */}
        <Route
          path="/personaluserorganizer/:organizerId"
          element={<PersonalInfoUserOrganizer />}
        />
        <Route
          path="/Organiser_Detail_HostedEvents_Sales_Analytics/:eventId"
          element={<Organiser_Detail_HostedEvents_Sales_Analytics />}
        />
        <Route
          path="/Attendeed_Hosted_Events/:eventId"
          element={<Attendeed_Hosted_Events />}
        />
        <Route
          path="/Hosted_Events_Attendeed_main"
          element={<Hosted_Events_Attendeed_main />}
        />

        <Route
          path="/Organizer_Detail_Follower"
          element={<Organizer_Detail_Follower />}
        />
        <Route
          path="/Organizer_Detail_Payout"
          element={<Organizer_Detail_Payout />}
        />
        <Route
          path="/Organizer_Detail_Scanner_login"
          element={<Organizer_Detail_Scanner_login />}
        />
        <Route
          path="/Organiser_Detail_Liked_Events"
          element={<Organiser_Detail_Liked_Events />}
        />
        <Route
          path="/SalesAnalytics_table1"
          element={<SalesAnalytics_table1 />}
        />
        <Route
          path="/SalesAnalytics_table2__2"
          element={<SalesAnalytics_table2__2 />}
        />
        <Route
          path="/SalesAnalytics_table_3"
          element={<SalesAnalytics_table_3 />}
        />
        {/* <Route path="/Organiser_Detail_HostedEvents_Sales_Analytics" element={<Organiser_Detail_HostedEvents_Sales_Analytics/>} /> */}
        <Route
          path="/User_Scanner____Details/:id"
          element={<User_Scanner____Details />}
        />

        <Route
          path="/Organiser_Detail_Liked_Events__Table"
          element={<Organiser_Detail_Liked_Events__Table />}
        />

        <Route path="/" element={<Signed_In_Main />} />
        <Route
          path="/pushNotifications"
          element={
            <Sidebar>
              <PushNotifications />
            </Sidebar>
          }
        />
        <Route
          path="/users"
          element={
            <Sidebar>
              <Users />
            </Sidebar>
          }
        />
        <Route
          path="/KycAndAproval"
          element={
            <Sidebar>
              <KycAndAproval />
            </Sidebar>
          }
        />
        <Route
          path="/KycIndivialDetailTable/:kycId"
          element={<KycIndivialDetailTable />}
        />
        <Route
          path="/Kyc_Updated_Request_Approval"
          element={
            <Sidebar>
              <Kyc_Updated_Request_Approval />
            </Sidebar>
          }
        />
        <Route
          path="/New_Updated_Payout_GetPaid"
          element={
            <Sidebar>
              <New_Updated_Payout_GetPaid />
            </Sidebar>
          }
        />
        <Route
          path="/gpRequest"
          element={
            <Sidebar>
              <GetPaidRequest />
            </Sidebar>
          }
        />
        <Route
          path="/After___QA__PayoutHistory1"
          element={
            <Sidebar>
              <After___QA__PayoutHistory1 />
            </Sidebar>
          }
        />
        <Route
          path="/After___QA__PayoutHistory2"
          element={
            <Sidebar>
              <After___QA__PayoutHistory2 />
            </Sidebar>
          }
        />
        <Route
          path="/GetPaidRequest_After_Qa/:payoutId"
          element={
            <Sidebar>
              <GetPaidRequest_After_Qa />
            </Sidebar>
          }
        />
        <Route
          path="/GetPaidRequest_After_Qa2"
          element={
            <Sidebar>
              <GetPaidRequest_After_Qa2 />
            </Sidebar>
          }
        />
        <Route
          path="/getpayouthistory"
          element={
            <Sidebar>
              <GetHistory />
            </Sidebar>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
