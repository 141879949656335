import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
} from "@mui/material";
import "./Sales_RvspTicketing.css";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getRsvpTickets } from "../../services/redux/middleware/salesanalytics";
import { toast } from "react-toastify";
import ScreenLoader from "../loader/screenLoader";
function Sales_RvspTicketing() {
  const containerStyle = {
    borderWidth: "0px",
    borderStyle: "solid",
    borderImageSlice: 1,
    borderImageSource:
      "linear-gradient(180deg, rgba(251, 251, 251, 0.6) 0%, rgba(251, 251, 251, 0.7) 100%)",
    borderRadius: "0.5rem",
    backgroundColor: "transparent",
    height: "100vh",
    overflowY: "scroll",
    // padding: '20px',
  };

  const scrollbarStyles = `
    ::-webkit-scrollbar {
      width: 5px;
    }
    ::-webkit-scrollbar-track {
      background: transparent;
    }
    ::-webkit-scrollbar-thumb {
      background: #13FF7A;
      border-radius: 5px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #13ff79b9;
    }
  `;
  
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [currentTicketData, setCurrentTicketData] = useState("");
  console.log("eid", currentTicketData);
  // const dispatch = useDispatch();

  const dispatch = useDispatch();

  const [allFields, setAllFields] = useState(null);
  const [eventID, setEventID] = useState(null);

  const rsvpTicketsData = useSelector(
    (state) => state.getAllRSVPcTickets?.allRSVPs?.data
  );
  const rsvpLoader = useSelector((state) => state.getAllRSVPcTickets?.loading);

  useEffect(() => {
    const storedTicketData = localStorage.getItem("currentTicketData");
    if (storedTicketData) {
      const parsedData = JSON.parse(storedTicketData);
      setCurrentTicketData(parsedData);
      setEventID(parsedData?.eventId);
    }
  }, []);

  useEffect(() => {
    if (eventID) {
      setLoading(true);
      dispatch(getRsvpTickets(eventID))
        .finally(() => setLoading(false))
        .catch((error) => console.error("Error fetching RSVP tickets:", error));
    }
  }, [eventID, dispatch]);

  useEffect(() => {
    if (currentTicketData?.allTickets && rsvpTicketsData) {
      const updatedTickets = currentTicketData.allTickets.map((ticket) => {
        const users = rsvpTicketsData?.tickets?.filter(
          (rsvpUser) => Number(rsvpUser?.isIndex) === Number(ticket?.isIndex)
        );
        return {
          ...ticket,
          allUsers: users?.map((t) => t?.rsvData),
        };
      });

      setCurrentTicketData((prev) => ({
        ...prev,
        allTickets: updatedTickets,
      }));

      const fields = updatedTickets.map((ticket) =>
        Object.keys(ticket?.allUsers?.[0]?.[0] || {})
      );
      setAllFields(fields);
    }
  }, [rsvpTicketsData]);

  const convertToLocal = (utcDate) => {
    const date = new Date(utcDate);
    function getOrdinal(n) {
      const suffixes = ["th", "st", "nd", "rd"];
      const value = n % 100;
      return suffixes[(value - 20) % 10] || suffixes[value] || suffixes[0];
    }
    const formattedDate = new Intl.DateTimeFormat("en-GB", {
      day: "numeric",
      month: "long",
      year: "numeric",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    }).format(date);
    const dayWithOrdinal = `${date.getDate()}${getOrdinal(date.getDate())}`;
    return `${dayWithOrdinal} ${formattedDate.split(" ").slice(1).join(" ")}`;
  };
  const exportToCSV = () => {
    if (!currentTicketData?.allTickets || currentTicketData.allTickets.length === 0) {
      toast.error("No data available to export!");
      return;
    }
  
    const rows = [];
  
    currentTicketData.allTickets.forEach((ticket, index) => {
      rows.push([`Ticket: ${ticket.ticketName}`]);
      rows.push(["RSVP Deadline", "Name of Ticket Type", "RSVP Capacity"]);
      rows.push([
        convertToLocal(ticket?.rsvpDeadline),
        ticket?.ticketName,
        ticket?.originalNoOfTickets,
      ]);
  
      if (allFields?.[index]) {
        rows.push(allFields[index]);
      }
  

      ticket?.allUsers?.forEach((userRow) => {
        rows.push(Object.values(userRow[0]));
      }) 
      rows.push([]);
    });
  
    const csvContent = rows.map((row) => row.join(",")).join("\n");
  
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", `${currentTicketData?.eventName || "RSVP_Tickets"}-Data.csv`);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };
  
  const handleSalesRsvpClick = () => {
    navigate(-1);
  };
  return (
    <>
      {loading && <ScreenLoader />}
      <div style={containerStyle}>
        <style>{scrollbarStyles}</style>
    <div className="sales__rvsp__ticketing__main__container">
      <div className="sales__rvsp__ticketing__main__container__1">
        <div
          style={{
            marginBottom: "0px",
            cssText: "margin-bottom: 0px !important;",
          }}
          className="Organiser_Detail_HostedEvents_Sales_Analytics_Main_heading_and_image"
        >
          <img
            onClick={handleSalesRsvpClick}
            style={{ cursor: "pointer" }}
            src="/Payout_Images/graphlogo.svg"
            alt="Graph logo"
          />
          <h2>RSVP Ticketing</h2>
        </div>

        <div
          onClick={exportToCSV}
          style={{
            paddingRight: "0px",
            cssText: "padding-right: 0px !important;",
          }}
          className="export-button"
        >
          <button>Export</button>
        </div>
      </div>

      {currentTicketData?.allTickets?.length > 0 ? (
        currentTicketData?.allTickets?.map((ticket, index) => (
          <>
            <div className="ticket__type__detail__main__container">
              <div className="ticket__type__detail__main__container__1">
                <div className="ticket__type__detail__main__container__2">
                  <p className="ticket__name__Sales__1">Name of Ticket Type</p>
                  <p className="ticket__detail__Sales__1">
                    {ticket?.ticketName}
                  </p>
                </div>
                <div className="ticket__type__detail__main__container__2">
                  <p className="ticket__name__Sales__1">RSVP Deadline</p>
                  <p className="ticket__detail__Sales__1">
                    {convertToLocal(ticket?.rsvpDeadline)}
                  </p>
                </div>
                <div className="ticket__type__detail__main__container__2">
                  <p className="ticket__name__Sales__1">RSVP Capacity</p>
                  <p className="ticket__detail__Sales__1">
                    {ticket?.originalNoOfTickets}
                  </p>
                </div>
              </div>
            </div>
            <Box>
              <Box
                sx={{ height: "0px", backgroundColor: "#000", mb: "16px" }}
              ></Box>

              <TableContainer className="Organizer_detail_Follower_Main_____"
              
                component={Paper}
                sx={{
                  backgroundColor: "rgba(255, 255, 255, 0.06)",
                  overflowX: "auto",
                }}
              >
                <Table>
                  <TableHead
                    sx={{
                      background:
                        "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)",
                      borderBottom: "1px solid #292929",
                    }}
                    className="to-apply-table-border-color"
                  >
                    <TableRow>
                      {allFields?.[index]?.map((fieldName, i) => (
                        <TableCell
                          sx={{
                            borderBottom: "1px solid #292929",
                            width: "105px",
                            color:'white'
                          }}
                          className="even-name-text-style-for_sales"
                        >
                          {fieldName}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  <TableRow
                    sx={{
                      height: "16px",
                      backgroundColor: "#030303",
                      width: "220px",

                    }}
                  >
                    <TableCell colSpan={5}></TableCell>
                  </TableRow>

                  <TableBody
                    sx={{
                      background:
                        "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)",
                    }}
                    className="for-to-give-the-padding1"
                  >
                    {ticket?.allUsers?.map((row, rowIndex) => (
                      <TableRow
                        key={index}
                        className="main-even-name-text-style-for_sales1"
                      >
                        {Object.keys(row[0]).map((key) => (
                          <TableCell
                            sx={{
                              borderBottom: "1px solid transparent",
                              whiteSpace: "nowrap",
                             
                            }}
                            className="main-even-name-text-style-for_sales-padding-margin-name__sales1"
                          >
                            {row[0][key]}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </>
        ))
      ) : (
        <></>
      )}

      {/* <div className="ticket__type__detail__main__container_two">
        <div className="ticket__type__detail__main__container">
          <div className="ticket__type__detail__main__container__1">
            {ticketData.map((ticket, index) => (
              <div
                key={index}
                className="ticket__type__detail__main__container2"
              >
                <p className="ticket__name__Sales__1">{ticket.name}</p>
                <p className="ticket__detail__Sales__1">{ticket.detail}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Box>
        <Box sx={{ height: "24px", backgroundColor: "#000", mb: "16px" }}></Box>

        <TableContainer
          component={Paper}
          sx={{
            backgroundColor: "rgba(255, 255, 255, 0.06)",
            overflowX: "auto",
          }}
        >
          <Table>
            <TableHead
              sx={{
                background:
                  "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)",
                borderBottom: "1px solid #292929",
              }}
              className="to-apply-table-border-color"
            >
              <TableRow>
                <TableCell
                  sx={{ borderBottom: "1px solid #292929", width: "105px" }}
                  className="even-name-text-style-for_sales"
                >
                  Name
                </TableCell>
                <TableCell
                  sx={{ borderBottom: "1px solid #292929", width: "220px" }}
                  className="even-name-text-style-for_sales"
                >
                  Email
                </TableCell>
                <TableCell
                  sx={{ borderBottom: "1px solid #292929", width: "220px" }}
                  className="even-name-text-style-for_sales"
                >
                  Phone
                </TableCell>
                <TableCell
                  sx={{
                    borderBottom: "1px solid rgba(41, 41, 41, 1)",
                    width: "220px",
                    whiteSpace: "nowrap",
                  }}
                  className="even-name-text-style-for_sales"
                >
                  Additional Field
                </TableCell>
                <TableCell
                  sx={{
                    borderBottom: "1px solid rgba(41, 41, 41, 1)",
                    width: "220px",
                    whiteSpace: "nowrap",
                  }}
                  className="even-name-text-style-for_sales"
                >
                  Additional Field 2
                </TableCell>
              </TableRow>
            </TableHead>

            <TableRow
              sx={{
                height: "16px",
                backgroundColor: "#030303",
                width: "220px",
              }}
            >
              <TableCell colSpan={5}></TableCell>
            </TableRow>

            <TableBody
              sx={{
                background:
                  "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)",
              }}
              className="for-to-give-the-padding"
            >
              {data.map((row, index) => (
                <TableRow
                  key={index}
                  className="main-even-name-text-style-for_sales"
                >
                  <TableCell
                    sx={{
                      borderBottom: "1px solid transparent",
                      whiteSpace: "nowrap",
                    }}
                    className="main-even-name-text-style-for_sales-padding-margin-name__sales"
                  >
                    {row.eventName}
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: "1px solid transparent",
                      width: "105px",
                    }}
                    className="main-even-name-text-style-for_sales-padding-margin-name__sales"
                  >
                    {row.ticketType}
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: "1px solid transparent",
                      width: "220px",
                    }}
                    className="main-even-name-text-style-for_sales-padding-margin-name__sales"
                  >
                    {row.price}
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: "1px solid transparent",
                      width: "220px",
                    }}
                    className="main-even-name-text-style-for_sales-padding-margin-name__sales"
                  >
                    {row.date}
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: "1px solid transparent",
                      width: "220px",
                    }}
                    className="main-even-name-text-style-for_sales-padding-margin-name__sales"
                  >
                    {row.additionalfield1}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box> */}
    </div>
    </div>
    </>
  );
}

export default Sales_RvspTicketing;
