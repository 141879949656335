import React, { useEffect, useState } from "react";
import "./waitlist.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllWaitlist } from "../../services/redux/middleware/getAllWaitList";
import ScreenLoader from "../../Components/loader/screenLoader";
const Waitlist_table = () => {
  const containerStyle = {
    borderWidth: '0px',
    borderStyle: 'solid',
    borderImageSlice: 1,
    borderImageSource:
      'linear-gradient(180deg, rgba(251, 251, 251, 0.6) 0%, rgba(251, 251, 251, 0.7) 100%)',
    borderRadius: '0.5rem',
    backgroundColor: 'transparent',
    height: '100vh',
    overflowY: 'scroll',
    // padding: '20px',
  };

  const scrollbarStyles = `
    ::-webkit-scrollbar {
      width: 5px;
    }
    ::-webkit-scrollbar-track {
      background: transparent;
    }
    ::-webkit-scrollbar-thumb {
      background: #13FF7A;
      border-radius: 5px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #13ff79b9;
    }
  `;

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const WaitlistData = useSelector(
    (state) => state?.getAllWaitList?.userData?.data?.data
  );
  console.log("waitlist data is...", WaitlistData);

  useEffect(() => {
    setLoading(true);
    dispatch(getAllWaitlist()).finally(() => setLoading(false));
  }, [dispatch]);

  const navigate = useNavigate();
  const handleClickCard = (eventId) => {
    console.log("Navigating to event:", eventId);
    navigate(`/Waitlist_Detail/${eventId}`);
  };

  return (
    <>
    {loading && <ScreenLoader />}
    <div className="main__event__table__1" >
    <div style={containerStyle}>
      <style>{scrollbarStyles}</style> 
      <div className="event__main__container">
        <div className="events-container">
          {WaitlistData?.map((row, index) => (
            <div
              key={index}
              onClick={() => {
                console.log("wait list event id passing is ......", row?.eventId);
                handleClickCard(row?.eventId);
              }}
              className="event-card"
              style={{ backgroundImage: `url(${row?.event?.coverEventImage})` }}
            >
              <p className="event-title">{row?.event?.name}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
    </div>
  </>
  
  );
};

export default Waitlist_table;
