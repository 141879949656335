import React from "react";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "./After___QA__PayoutHistory1.css";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getPayoutHistory } from "../../services/redux/middleware/getPayoutHistory";
import ScreenLoader from "../loader/screenLoader";

const After___QA__PayoutHistory1 = () => {
  const containerStyle = {
    borderWidth: "0px",
    borderStyle: "solid",
    borderImageSlice: 1,
    borderImageSource:
      "linear-gradient(180deg, rgba(251, 251, 251, 0.6) 0%, rgba(251, 251, 251, 0.7) 100%)",
    borderRadius: "0.5rem",
    backgroundColor: "transparent",
    height: "100vh",
    overflowY: "scroll",
    // padding: '20px',
  };

  const scrollbarStyles = `
    ::-webkit-scrollbar {
      width: 5px;
    }
    ::-webkit-scrollbar-track {
      background: transparent;
    }
    ::-webkit-scrollbar-thumb {
      background: #13FF7A;
      border-radius: 5px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #13ff79b9;
    }
  `;
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const paidHistoryData = useSelector(
    (state) => state?.getPayoutHistory?.getPayoutHistoryData?.data?.data
  );
  console.log("paid history  data is...", paidHistoryData);

  useEffect(() => {
    setLoading(true);
    dispatch(getPayoutHistory()).finally(() => setLoading(false));
  }, [dispatch]);

  const exportToCSV = () => {
    if (!paidHistoryData || paidHistoryData.length === 0) {
      toast.error("No data available to export!");
      return;
    }
    const csvHeaders = [
      "Name",
      "Amount",
      "Withdrawal Method",
      "Account Name",
      "Acc No/Wallet Address",
      "City/Chain",
      "Date",
    ];

    const csvRows = paidHistoryData?.map((row) => [
      row?.user?.fullname || "----",
      row?.amountWithdrawn || "----",
      row?.types || "----",
      row?.accountTitle || "----",
      row?.IBAN || row?.walletAddress || "----",
      row?.city || row?.chain || "----",
      row?.createdAt ? row?.createdAt.split("T")[0] : "----",
    ]);

    const csvContent = [
      csvHeaders.join(","),
      ...csvRows.map((row) => row.join(",")),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "Payout_History.csv";
    link.click();
    URL.revokeObjectURL(url);
  };

  return (
    <>
      {loading && <ScreenLoader />}
      <div style={containerStyle}>
        <style>{scrollbarStyles}</style>

        <div className="after__Qa__payout__main_container">
          <div
            style={{
              paddingRight: "0px",
              cssText: "padding-right: 0px !important;",
            }}
            className="export__button__container_kyc"
          >
            <button onClick={exportToCSV} className="export-button-kyc">
              Export
            </button>
          </div>
          {!paidHistoryData ? (
            <div className="empty__state__main__cintainer__2">
              <Typography className="empty_state_text" variant="h6">
                There’s No Payout History
              </Typography>
            </div>
          ) : (
            <TableContainer
              component={Paper}
              className="Organizer_detail_Follower_Main"
            >
              <Table
                sx={{ minWidth: 750, maxWidth: 1077 }}
                aria-label="simple table"
              >
                {paidHistoryData?.length > 0 && (
                  <TableHead
                    style={{ backgroundColor: "rgba(255, 255, 255, 0.06)" }}
                  >
                    <TableRow>
                      <TableCell className="Organizer_detail_Follower_Main___Row">
                        Name
                      </TableCell>
                      <TableCell className="Organizer_detail_Follower_Main___Row">
                        Amount
                      </TableCell>
                      <TableCell
                        style={{ whiteSpace: "nowrap" }}
                        className="Organizer_detail_Follower_Main___Row"
                      >
                        Withdrawal Method
                      </TableCell>
                      <TableCell
                        style={{ whiteSpace: "nowrap" }}
                        className="Organizer_detail_Follower_Main___Row"
                      >
                        Account Name
                      </TableCell>
                      <TableCell
                        style={{ whiteSpace: "nowrap" }}
                        className="Organizer_detail_Follower_Main___Row"
                      >
                        Acc No/ Wallet Address
                      </TableCell>
                      <TableCell className="Organizer_detail_Follower_Main___Row">
                        City/Chain
                      </TableCell>
                      <TableCell className="Organizer_detail_Follower_Main___Row">
                        Date
                      </TableCell>
                    </TableRow>
                  </TableHead>
                )}

                {/* Spacer row for gap */}
                {paidHistoryData?.length > 0 && (
                  <TableRow sx={{ height: "0px", backgroundColor: "#030303" }}>
                    <TableCell colSpan={6}></TableCell>
                  </TableRow>
                )}
                <TableBody>
                  <TableRow>
                    <TableCell
                      colSpan={4}
                      style={{
                        height: "16px",
                        backgroundColor: "transparent",
                        borderBottom: "none",
                        padding: "0px",
                      }}
                    ></TableCell>
                  </TableRow>

                  {paidHistoryData?.map((row, index) => (
                    <TableRow
                      key={index}
                      className="rganizer_detail_Follower_Main___Body_Top"
                    >
                      <TableCell className="Organizer_detail_Follower_Main___Body">
                        {row?.user?.fullname ? row?.user?.fullname : "----"}
                      </TableCell>
                      <TableCell className="Organizer_detail_Follower_Main___Body1">
                        {row?.amountWithdrawn ? row?.amountWithdrawn : "0"}
                      </TableCell>
                      <TableCell className="Organizer_detail_Follower_Main___Body1">
                        {row?.types ? row?.types : "----"}
                      </TableCell>
                      <TableCell className="Organizer_detail_Follower_Main___Body1">
                        {row?.accountTitle ? row?.accountTitle : "----"}
                      </TableCell>
                      <TableCell className="Organizer_detail_Follower_Main___Body1">
                        {row?.IBAN || row?.walletAddress || "----"}
                      </TableCell>
                      <TableCell className="Organizer_detail_Follower_Main___Body1">
                        {row?.city || row?.chain || "----"}
                      </TableCell>
                      <TableCell
                        style={{ whiteSpace: "nowrap" }}
                        className="Organizer_detail_Follower_Main___Body1"
                      >
                        {row?.createdAt.split("T")[0]
                          ? row?.createdAt.split("T")[0]
                          : "----"}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>
      </div>
    </>
  );
};

export default After___QA__PayoutHistory1;
