import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import "./KycAndAproval.css";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
} from "@mui/material";
import { Check, Close } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getKycApproval } from "../../services/redux/middleware/getKyc";
import ScreenLoader from "../loader/screenLoader";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Stack from "@mui/material/Stack";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Dropdown } from "react-bootstrap";
import { toast } from "react-toastify";
function KycAndAproval() {
  const containerStyle = {
    borderWidth: "0px",
    borderStyle: "solid",
    borderImageSlice: 1,
    borderImageSource:
      "linear-gradient(180deg, rgba(251, 251, 251, 0.6) 0%, rgba(251, 251, 251, 0.7) 100%)",
    borderRadius: "0.5rem",
    backgroundColor: "transparent",
    height: "100vh",
    overflowY: "scroll",
  };

  const scrollbarStyles = `
    ::-webkit-scrollbar {
      width: 5px;
    }
    ::-webkit-scrollbar-track {
      background: transparent;
    }
    ::-webkit-scrollbar-thumb {
      background: #13FF7A;
      border-radius: 5px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #13ff79b9;
    }
  `;
  const theme = useTheme();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { kycId } = useParams();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  const [act, setact] = useState("A");
  const handlebtnclick = (active) => {
    setact(active);
  };
  const isActive = (path) => location.pathname === path;
  const { kycApprovalData, pagination } = useSelector((state) => {
    const data = state?.getKycApproval?.kycApprovalData?.data || {};
    return {
      kycApprovalData: data.data || [],
      pagination: data.pagination || {},
    };
  });

  const { currentPage, totalPages } = pagination;

  console.log("kyc approval data is...", kycApprovalData);

  const uniqueUserData = [
    ...new Map(kycApprovalData.map((item) => [item.userId, item])).values(),
  ];

  useEffect(() => {
    setLoading(true);
    dispatch(getKycApproval({ page: currentPage })).finally(() =>
      setLoading(false)
    );
  }, [dispatch, currentPage]);

  const handlePageChange = (event, value) => {
    dispatch(getKycApproval({ page: value }));
  };
  const filteredData = kycApprovalData.filter((row) =>
    act === "A" ? row?.approved : !row?.approved
  );

  const handleExportCSV = () => {
    if (!filteredData || filteredData.length === 0) {
      toast.error("No data available to export");
      return;
    }
  
    const headers = [
      "Full Name",
      "Email",
      "DOB",
      "Organization Website",
      "Date",
      "Status",
    ];
  
    const rows = filteredData.map((row) => [
      row?.user?.fullname || "-----",
      row?.user?.email || "-----",
      row?.Individual?.[0]?.DOB || "---",
      row?.Individual?.[0]?.organizationWebsite || "---",
      row?.createdAt.split("T")[0] || "---", // Handling createdAt properly
      row?.approved ? "Approved" : "Unapproved",
    ]);
  
    const csvContent =
      "data:text/csv;charset=utf-8," +
      [headers, ...rows].map((e) => e.join(",")).join("\n");
  
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "kyc_approval_data.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const navigateKycApproval = (userId) => {
    navigate(`/kyc_Approval__table/${userId}`);
  };
  return (
    <>
      {loading && <ScreenLoader />}

      <div className="kyc__approval__main__div" style={containerStyle}>
        <style>{scrollbarStyles}</style>

        <div className="export__button__container_kyc">
          <button className="export-button-kyc" onClick={handleExportCSV}>
            Export
          </button>
        </div>
        <div className="approved__button__main__container">
          <div className="top-buttons___new_try">
            <button
              style={{
                background:
                  act === "A"
                    ? "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)padding-box, linear-gradient(167deg, #13FF7A 0.2%, #002B12 50.2%, #13FF7A 100.2%)border-box "
                    : "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)padding-box, linear-gradient(360deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.06) 100%)border-box",

                color: act === "A" ? "#00A849" : "#FFFFFF",
                border:
                  act === "A"
                    ? "1px solid  transparent"
                    : "1px solid  transparent",
              }}
              className={isActive("/personal-information") ? "active" : ""}
              onClick={() => handlebtnclick("A")}
            >
              Approved
            </button>
            <button
              style={{
                background:
                  act === "B"
                    ? "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)padding-box, linear-gradient(167deg, #13FF7A 0.2%, #002B12 50.2%, #13FF7A 100.2%)border-box "
                    : "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)padding-box, linear-gradient(360deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.06) 100%)border-box",

                color: act === "B" ? "#00A849" : "#FFFFFF",
                border:
                  act === "B"
                    ? "1px solid  transparent"
                    : "1px solid  transparent",
              }}
              className={isActive("/event-tickets") ? "active" : ""}
              onClick={() => handlebtnclick("B")}
            >
              Unapproved
            </button>
          </div>
        </div>
        {/* <div className="filter__button__top_container">
      <div className="filter__button__main_container">
        <p className="filter__text">Filter</p>

        <Dropdown>
          <Dropdown.Toggle
            id="dropdown-custom-components"
            className="filter-icon-btn"
          >
            <img
              src="Images/Kyc_Updated_ver/filterIcon.svg"
              alt="filter icon"
              style={{ cursor: "pointer" }}
            />
          </Dropdown.Toggle>

          <Dropdown.Menu className="dropdown___menu__container">
            <Dropdown.Item className="dropdown__filter__text" >Approved</Dropdown.Item>
            <Dropdown.Item className="dropdown__filter__text" >Unapproved</Dropdown.Item>
           
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div> */}

        <div className="given-to-the-width-to-all__1">
          <Box>
            <Box
              sx={{ height: "16px", backgroundColor: "#000", mb: "16px" }}
            ></Box>

            <TableContainer
              className="Organizer_detail_Follower_Main_____"
              component={Paper}
              sx={{
                backgroundColor: "rgba(255, 255, 255, 0.06)",
                overflowX: "auto",
              }}
            >
              <Table>
                <TableHead
                  sx={{ backgroundColor: "rgba(255, 255, 255, 0.06)" }}
                  className="to-apply-table-border-color"
                >
                  <TableRow>
                    <TableCell
                      sx={{ borderBottom: "1px solid #292929" }}
                      className="even-name-text-style-for"
                    >
                      Name
                    </TableCell>
                    <TableCell
                      sx={{ borderBottom: "1px solid #292929" }}
                      className="even-name-text-style-for"
                    >
                      Email
                    </TableCell>
                    <TableCell
                      sx={{ borderBottom: "1px solid rgba(41, 41, 41, 1)" }}
                      className="even-name-text-style-for"
                    >
                      DOB
                    </TableCell>
                    {/* <TableCell
                        sx={{ borderBottom: "1px solid rgba(41, 41, 41, 1)" }}
                        className="even-name-text-style-for"
                      >
                        Role
                      </TableCell> */}

                    <TableCell
                      sx={{ borderBottom: "1px solid rgba(41, 41, 41, 1)" }}
                      className="even-name-text-style-for"
                    >
                      Website
                    </TableCell>

                    <TableCell
                      sx={{ borderBottom: "1px solid rgba(41, 41, 41, 1)" }}
                      className="even-name-text-style-for"
                    >
                      Date
                    </TableCell>
                    <TableCell
                      sx={{ borderBottom: "1px solid rgba(41, 41, 41, 1)" }}
                      className="even-name-text-style-for"
                    >
                      Status
                    </TableCell>
                  </TableRow>
                </TableHead>

                {/* Spacer row for gap */}
                <TableRow sx={{ height: "16px", backgroundColor: "#030303" }}>
                  <TableCell colSpan={7}></TableCell>
                </TableRow>

                <TableBody
                  style={{
                    background:
                      "linear-gradient(360deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.06) 100%)",
                  }}
                >
                  {filteredData?.map((row, index) => (
                    <TableRow
                      key={index}
                      className="main-even-name-text-style-for"
                    >
                      <TableCell
                        onClick={() => {
                          console.log(
                            "User ID passed from KYC table is..........",
                            row?.userId
                          );
                          navigateKycApproval(row?.userId);
                        }}
                        sx={{
                          borderBottom: "1px solid #292929",
                          width: "275px",
                          textTransform: "none",
                          cursor:'pointer'
                        }}
                        className="main-even-name-text-style-for-padding-margin-name"
                      >
                        {row?.user?.fullname ? row?.user?.fullname : "-----"}
                      </TableCell>

                      <TableCell
                        sx={{
                          borderBottom: "1px solid #292929",
                          width: "275px",
                        }}
                        className="main-even-name-text-style-for-padding-margin-type"
                      >
                        {row?.user?.email ? row?.user?.email : "-----"}
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: "1px solid #292929",
                          width: "275px",
                        }}
                        className="main-even-name-text-style-for-padding-margin-type"
                      >
                        {row?.Individual?.[0]?.DOB || "---"}
                      </TableCell>
                      {/* <TableCell
                          sx={{
                            borderBottom: "1px solid #292929",
                            width: "275px",
                          }}
                          className="main-even-name-text-style-for-padding-margin-type"
                        >
                          {row?.userType ? row?.userType : "----"}
                        </TableCell> */}
                      <TableCell
                        sx={{
                          borderBottom: "1px solid #292929",
                          width: "275px",
                        }}
                        className="main-even-name-text-style-for-padding-margin-type"
                      >
                       {row?.Individual?.[0]?.organizationWebsite || "---"}
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: "1px solid #292929",
                          width: "275px",
                        }}
                        className="main-even-name-text-style-for-padding-margin-type"
                      >
                        {row?.createdAt.split("T")[0]}
                      </TableCell>

                      <TableCell
                        sx={{
                          borderBottom: "1px solid #292929",
                          width: "275px",
                        }}
                        className="main-even-name-text-style-for-padding-margin-type"
                      >
                        {row?.approved ? "Approved" : "Unpproved"}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          {/* Pagination */}
          <Stack
            spacing={2}
            sx={{
              backgroundColor: "#121212",
              padding: "1rem",
              borderRadius: "8px",
            }}
          >
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
              sx={{
                "& .MuiPagination-ul": {
                  display: "flex",
                  gap: "10px",
                  justifyContent: "flex-end",
                },
              }}
              renderItem={(item) => (
                <PaginationItem
                  slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                  {...item}
                  sx={{
                    color: "#fff",
                    borderRadius: "10px",
                    border: "1px solid rgba(255, 255, 255, 0.5)",
                    "&:hover": {
                      backgroundColor: "rgba(255, 255, 255, 0.1)",
                    },
                    "&.Mui-selected": {
                      backgroundColor: "#00d059",
                      color: "#fff",
                      borderRadius: "10px",
                    },
                  }}
                />
              )}
            />
          </Stack>
        </div>
      </div>
    </>
  );
}

export default KycAndAproval;
