import React, { useEffect, useState } from "react";
import "./Feedback_Pizdez_table.css";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
} from "@mui/material";
import ScreenLoader from "../../Components/loader/screenLoader";
import { getFeedBackEvents } from "../../services/redux/middleware/getFeedbackEvents";
import FeedbackModal from "../../Components/Feedbackpopup/Feedbackpopup";
function Feedback_Pizdez_table() {
  const containerStyle = {
    borderWidth: "0px",
    borderStyle: "solid",
    borderImageSlice: 1,
    borderImageSource:
      "linear-gradient(180deg, rgba(251, 251, 251, 0.6) 0%, rgba(251, 251, 251, 0.7) 100%)",
    borderRadius: "0.5rem",
    backgroundColor: "transparent",
    height: "100vh",
    overflowY: "scroll",
    // padding: '20px',
  };

  const scrollbarStyles = `
    ::-webkit-scrollbar {
      width: 5px;
    }
    ::-webkit-scrollbar-track {
      background: transparent;
    }
    ::-webkit-scrollbar-thumb {
      background: #13FF7A;
      border-radius: 5px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #13ff79b9;
    }
  `;
  const [modalShow, setModalShow] = useState(false);
  const [modalText, setModalText] = useState("");
  const [modalData, setModalData] = useState(null);
  const { eventId } = useParams();
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const feedbackData = useSelector(
    (state) => state?.getFeedBackEvents?.getfeedBackEventsData?.data?.feedback
  );

  console.log("Feedback events data is...", feedbackData);

  useEffect(() => {
    setLoading(true);
    if (eventId) {
      dispatch(getFeedBackEvents(eventId)).finally(() => {
        setLoading(false);
      });
    }
  }, [dispatch, eventId]);

  const navigate = useNavigate();

  const navigateFeedback = () => {
    navigate(-1);
  };

  const handleDownloadCSV = () => {
    if (!feedbackData || feedbackData.length === 0) {
      toast.error("No data available to export!");
      return;
    }

    const headers = ["Name", "Email", "Date", "Feedback"];
    const rows = feedbackData.map((row) => [
      row?.userFullname || "---",
      row?.userEmail || "---",
      row?.createdAt.split("T")[0] || "---",
      "View Feedback",
    ]);

    const csvContent = [
      headers.join(","),
      ...rows.map((row) => row.join(",")),
    ].join("\n");

    const encodedUri = encodeURI(`data:text/csv;charset=utf-8,${csvContent}`);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "feedback_events.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const handleClose = () => setModalShow(false);

  return (
    <>
      {loading && <ScreenLoader />}
      <div style={containerStyle}>
        <style>{scrollbarStyles}</style>
        <div className="personal-info-detail-container-new">
          <div
            onClick={navigateFeedback}
            className="Organiser_Detail_HostedEvents_Sales_Analytics_Main_heading_and_image"
          >
            <img
              style={{ cursor: "pointer" }}
              src="/Payout_Images/graphlogo.svg"
            />
            <h2>{feedbackData?.[0]?.eventName || "Event Feedback"}</h2>
          </div>
          <div className="CollectiblesOwned-to-give-width-attendeed">
            <div
              style={{
                paddingRight: "0px",
                cssText: "padding-right: 0px !important;",
              }}
              className="export-button"
            >
              <button onClick={handleDownloadCSV}>Export</button>
            </div>
            <Box>
              <Box
                sx={{ height: "50px", backgroundColor: "#000", mb: "16px" }}
              ></Box>
              {!feedbackData || feedbackData.length === 0 ? (
               
                  <div className="empty__state__main__cintainer__1"
                  
                  >
                    <Typography
                      className="empty_state_text"
                      variant="h6"
                      sx={{
                        marginBottom: "16px",
                        fontSize: "1rem",
                        fontWeight: 500,
                      }}
                    >
                      There’s No Feedback Data
                    </Typography>
                  </div>
               
              ) : (
                <TableContainer
                  className="Organizer_detail_Follower_Main_____"
                  component={Paper}
                  sx={{
                    backgroundColor: "rgba(255, 255, 255, 0.06)",
                    overflowX: "auto",
                  }}
                >
                  <Table>
                    {feedbackData?.length > 0 && (
                      <TableHead
                        sx={{ backgroundColor: "rgba(255, 255, 255, 0.06)" }}
                        className="to-apply-table-border-color"
                      >
                        <TableRow>
                          <TableCell
                            sx={{
                              borderBottom: "1px solid #292929",
                              width: "105px",
                            }}
                            className="even-name-text-style-for"
                          >
                            Name
                          </TableCell>
                          <TableCell
                            sx={{
                              borderBottom: "1px solid #292929",
                              width: "220px",
                            }}
                            className="even-name-text-style-for"
                          >
                            Email
                          </TableCell>
                          <TableCell
                            sx={{
                              borderBottom: "1px solid #292929",
                              width: "220px",
                            }}
                            className="even-name-text-style-for"
                          >
                            Date
                          </TableCell>
                          <TableCell
                            sx={{
                              borderBottom: "1px solid rgba(41, 41, 41, 1)",
                              width: "220px",
                            }}
                            className="even-name-text-style-for"
                          >
                            Feedback
                          </TableCell>
                        </TableRow>
                      </TableHead>
                    )}

                    {/* Spacer row for gap */}
                    {feedbackData?.length > 0 && (
                      <TableRow
                        sx={{ height: "0px", backgroundColor: "#030303" }}
                      >
                        <TableCell colSpan={6}></TableCell>
                      </TableRow>
                    )}

                    <TableRow
                      sx={{
                        height: "16px",
                        backgroundColor: "#030303",
                        width: "220px",
                      }}
                    >
                      <TableCell colSpan={4}></TableCell>
                    </TableRow>

                    <TableBody className="for-to-give-the-padding">
                      {feedbackData?.map((row, index) => (
                        <TableRow
                          key={index}
                          className="main-even-name-text-style-for"
                        >
                          <TableCell
                            sx={{ borderBottom: "1px solid #292929" }}
                            className="main-even-name-text-style-for-padding-margin-name"
                          >
                            {row?.userFullname ? row?.userFullname : "-----"}
                          </TableCell>
                          <TableCell
                            sx={{
                              borderBottom: "1px solid #292929",
                              width: "105px",
                            }}
                            className="main-even-name-text-style-for-padding-margin-name_new"
                          >
                            {row?.userEmail ? row?.userEmail : "-----"}
                          </TableCell>
                          <TableCell
                            sx={{
                              borderBottom: "1px solid #292929",
                              width: "220px",
                            }}
                            className="main-even-name-text-style-for-padding-margin-type"
                          >
                            {row?.createdAt.split("T")[0]
                              ? row?.createdAt.split("T")[0]
                              : "----"}
                          </TableCell>
                          <TableCell
                            onClick={() => {
                              setModalText(`${row?.text}`);
                              setModalShow(true);
                            }}
                            sx={{
                              borderBottom: "1px solid #292929",
                              width: "220px",
                              cursor: "pointer",
                              whiteSpace: "nowrap",
                            }}
                            className="main-even-name-text-style-for-padding-margin-type-newcolor"
                          >
                            View Feedback
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Box>
          </div>
          {modalShow && (
            <FeedbackModal
              show={modalShow}
              handleClose={handleClose}
              text={modalText}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default Feedback_Pizdez_table;
