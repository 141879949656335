import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
} from "@mui/material";
import { TableFooter } from "@mui/material";
//import Image from "react-image"; // Replace Next.js Image with a React.js alternative
import { getTicketsByEventNTicketIndex } from "../../services/redux/middleware/salesanalytics";
import ScreenLoader from "../loader/screenLoader";
import { toast } from "react-toastify";
import "./passwordEvents.css";
import { useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
function PasswordEvents() {
    const containerStyle = {
        borderWidth: "0px",
        borderStyle: "solid",
        borderImageSlice: 1,
        borderImageSource:
          "linear-gradient(180deg, rgba(251, 251, 251, 0.6) 0%, rgba(251, 251, 251, 0.7) 100%)",
        borderRadius: "0.5rem",
        backgroundColor: "transparent",
        height: "100vh",
        overflowY: "scroll",
        // padding: '20px',
      };
    
      const scrollbarStyles = `
        ::-webkit-scrollbar {
          width: 5px;
        }
        ::-webkit-scrollbar-track {
          background: transparent;
        }
        ::-webkit-scrollbar-thumb {
          background: #13FF7A;
          border-radius: 5px;
        }
        ::-webkit-scrollbar-thumb:hover {
          background: #13ff79b9;
        }
      `;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentTicketData, setCurrentTicketData] = useState("");
  const [eventId, setEventID] = useState(0);
  const [act, setAct] = useState("Sales"); // Initial state set to "A"

  const handlebtnclick = (type) => {
    setAct(type); // Update the state to reflect the selected button
  };
  useEffect(() => {
    const storedTicketData = localStorage.getItem("currentTicketData");
    if (storedTicketData) {
      const parsedData = JSON.parse(storedTicketData);
      setCurrentTicketData(parsedData);
      setEventID(parsedData?.eventId);
    }
  }, []);

  const [isloading, setIsLoading] = useState(false);
  const [allTickets, setAllTickets] = useState(null);
  const [allTicketAttendees, setAllTicketAttendees] = useState([]);
  useEffect(() => {
    console.log("Triggered Ticket Name ===> ", currentTicketData?.name);
  }, [currentTicketData]);
  useEffect(() => {
    const fetchData = async () => {
      console.log(
        "Fetching ticket data ==> ",
        currentTicketData?.name,
        "Indexes ==> ",
        currentTicketData?.tIndexes
      );
      setAllTickets(currentTicketData?.allTickets);
      try {
        setIsLoading(true);
        for (const t_index of currentTicketData?.tIndexes || []) {
          const data = {
            eventId: currentTicketData?.eventId,
            ticketIndex: t_index,
          };
          const res = await dispatch(getTicketsByEventNTicketIndex(data));
          console.log(`Data for ticket index ${t_index} ==> `, res);
          if (res?.payload?.status === 200) {
            const attendeesData = res.payload.data;
            setAllTicketAttendees((prev) => {
              const nameTicket =
                attendeesData[0]?.ticketTypes?.ticketName || "Unnamed Ticket";
              const attendeesObject = {
                t_name: nameTicket,
                allAattendees: attendeesData,
              };
              const isDuplicate = prev.some(
                (item) => item.t_name === attendeesObject.t_name
              );
              return isDuplicate ? prev : [...prev, attendeesObject];
            });
          } else {
            // toast.error("Something went wrong!");
          }
        }
      } catch (error) {
        console.error("Error fetching tickets:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [currentTicketData, dispatch]);
  const convertToLocal = (utcDate) => {
    const date = new Date(utcDate);
    const getOrdinal = (n) => {
      const suffixes = ["th", "st", "nd", "rd"];
      const value = n % 100;
      return suffixes[(value - 20) % 10] || suffixes[value] || suffixes[0];
    };
    const formattedDate = new Intl.DateTimeFormat("en-GB", {
      day: "numeric",
      month: "long",
      year: "numeric",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    }).format(date);
    const dayWithOrdinal = `${date.getDate()}${getOrdinal(date.getDate())}`;
    const finalFormattedDate = `${dayWithOrdinal} ${formattedDate
      .split(" ")
      .slice(1)
      .join(" ")
      .replace(":00", "")
      .replace(",", " -")}`;
    return finalFormattedDate;
  };
  const getLocalDate = (utcDateTime) => {
    const date = new Date(utcDateTime);
    return date.toLocaleDateString(undefined, {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    });
  };
  const getLocalTime = (utcDateTime) => {
    const date = new Date(utcDateTime);
    return date.toLocaleTimeString(undefined, {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    });
  };
  const decryptArrayWithErrors = (encryptedArray) => {
    return encryptedArray.map((encryptedString) => {
      try {
        const bytes = CryptoJS.AES.decrypt(
          encryptedString,
          "naitramV2SecretKey"
        );
        const decrypted = bytes.toString(CryptoJS.enc.Utf8);
        if (!decrypted) throw new Error("Decryption failed");
        return decrypted;
      } catch (error) {
        toast.error("Decryption Failed");
        return null;
      }
    });
  };
  const checkEmailStatus = (currentEmail, ticketIndex) => {
    const allAttendees = allTicketAttendees?.[ticketIndex]?.allAattendees;
    return (
      allAttendees?.some((attendee) => attendee?.email === currentEmail) ||
      false
    );
  };
  const getEmailPercent = (ticketIndex) => {
    const allAttendees = allTicketAttendees?.[ticketIndex]?.allAattendees;
    const allEmails = [
      ...(allTickets?.[ticketIndex]?.privateEventAdditionalFields || []),
      ...(allTickets?.[ticketIndex]?.csvEmails || []),
    ];
    const countMatch = allEmails.filter((email) =>
      allAttendees?.some((attendee) => attendee?.email === email)
    ).length;
    return ((allEmails.length - countMatch) / allEmails.length) * 100;
  };
  function exportToCSV(act, allTickets, allTicketAttendees) {
    const actType = typeof act === 'string' ? act.toLowerCase() : '';
  
    let headers = [];
    let rows = [];
    let data = []; 
  
    if (actType === "sales") {
      headers = [
        "Ticket Name",
        "Start Date/Time",
        "End Date/Time",
        "Tickets Sold",
        "Percentage of Emails That Bought",
        "Emails",
        "Status"
      ];
  
      rows = allTickets.map((ticket, t_Idx) => {
        const ticketName = ticket?.ticketName ?? "---";
        const startDate = convertToLocal(ticket?.ticketStartDT) ?? "---";
        const endDate = convertToLocal(ticket?.ticketEndDT) ?? "---";
        const ticketsSold = (ticket?.originalNoOfTickets ?? 0) - (ticket?.noOfTickets ?? 0);
        const emailPercent = getEmailPercent(t_Idx).toFixed(1); //
        const emailList = [
          ...ticket?.privateEventAdditionalFields,
          ...(ticket?.csvEmails ?? []),
        ];

        const ticketRow = [
          ticketName,
          startDate,
          endDate,
          ticketsSold,
          emailPercent,
        ];
  
    
        const emailRows = emailList.map((email, e_Idx) => {
          return [
            "",
            "", 
            "", 
            "", 
            "", 
            email ?? "---",
            checkEmailStatus(email, t_Idx) ? "Bought" : "Didn't Buy", 
          ];
        });
  
        return [ticketRow, ...emailRows];
      }).flat(); 
    } else if (actType === "attendance") {
      headers = [
        "Ticket Name",
        "Attendee Name",
        "Email",
        "Scanned Time"
      ];
  
      rows = allTicketAttendees.map(t_attend => {
        return t_attend?.allAattendees?.map(atnde => {
          if (atnde?.scan) {
            return [
              t_attend?.ticketName ?? "---",
              atnde?.fullName ?? "---",
              atnde?.email ?? "---",
              atnde?.scannedDate ?? "---"
            ];
          }
          return null;
        }).filter(row => row !== null);
      }).flat();
    } else if (act === "Users") { 
      headers = [
        "Full Name",
        "Email",
        "Created Date",
        "Created Time"
      ];
  
      allTicketAttendees?.forEach((t_attend) => {
        t_attend?.allAattendees?.forEach((atnde) => {
          data.push([
            atnde?.fullName ?? "---",
            atnde?.email ?? "---",
            getLocalDate(atnde?.createdAt) ?? "---",
            getLocalTime(atnde?.createdAt) ?? "---",
          ]);
        });
      });
  
      rows = data; 
    }
    if (!rows || rows.length === 0) {
        toast.error("No data available to export!");
        return;
      }
    const csvContent = [
      headers.join(","),
      ...rows.map(row => row.join(","))
    ].join("\n");
  
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', `${actType}_data.csv`);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
  
  const handlenavigate = () => {
    navigate(-1);
  };
  return (
    <>
     {isloading && <ScreenLoader />}
     <div style={containerStyle}>
        <style>{scrollbarStyles}</style>
      <div className="private___ticketing__main__container__1">
        <div className="private___ticketing__main__container__2">
          <div
            style={{
              marginBottom: "0px",
              cssText: "margin-bottom: 0px !important;",
            }}
            className="Organiser_Detail_HostedEvents_Sales_Analytics_Main_heading_and_image"
          >
            <img
              onClick={handlenavigate}
              style={{ cursor: "pointer" }}
              src="/Payout_Images/graphlogo.svg"
              alt="Graph logo"
            />
            <h2>Passworded / Discounted Voucher Event </h2>
          </div>
          <div className="top-buttons___new_try__sales">
            <button
              style={{
                background:
                  act === "Sales"
                    ? "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%) padding-box, linear-gradient(167deg, #13FF7A 0.2%, #002B12 50.2%, #13FF7A 100.2%) border-box"
                    : "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%) padding-box, linear-gradient(360deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.06) 100%) border-box",
                color: act === "Sales" ? "#00A849" : "#FFFFFF",
                border: "1px solid transparent",
              }}
              className={act === "Sales" ? "active" : ""}
              onClick={() => handlebtnclick("Sales")}
            >
              Sales
            </button>

            <button
              style={{
                background:
                  act === "Attendance"
                    ? "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%) padding-box, linear-gradient(167deg, #13FF7A 0.2%, #002B12 50.2%, #13FF7A 100.2%) border-box"
                    : "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%) padding-box, linear-gradient(360deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.06) 100%) border-box",
                color: act === "Attendance" ? "#00A849" : "#FFFFFF",
                border: "1px solid transparent",
              }}
              className={act === "Attendance" ? "active" : ""}
              onClick={() => handlebtnclick("Attendance")}
            >
              Attendance
            </button>

            <button
              style={{
                background:
                  act === "Users"
                    ? "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%) padding-box, linear-gradient(167deg, #13FF7A 0.2%, #002B12 50.2%, #13FF7A 100.2%) border-box"
                    : "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%) padding-box, linear-gradient(360deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.06) 100%) border-box",
                color: act === "Users" ? "#00A849" : "#FFFFFF",
                border: "1px solid transparent",
              }}
              className={act === "Users" ? "active" : ""}
              onClick={() => handlebtnclick("Users")}
            >
              Users
            </button>
          </div>
        </div>
        <div
          style={{
            marginTop: "0px",
            cssText: "margin-top: 0px !important;",
          }}
          className="export-button"
        >
          <button
              onClick={() => exportToCSV(act, allTickets, allTicketAttendees)}
            >
              Export
            </button>
        </div>
        {act === "Sales" ? (
          <div>
            {allTickets?.map((ticket, t_Idx) => (
              <>
                {/* Meta Data Card */}
                <div className="ticket__type__detail__main__container">
                  <div className="ticket__type__detail__main__container__1">
                    <div className="ticket__type__detail__main__container__2">
                      <p className="ticket__name__Sales__1">
                        Name of Ticket Type
                      </p>
                      <p className="ticket__detail__Sales__1">
                      {allTickets?.[t_Idx]?.ticketName ?? "---"}
                      </p>
                    </div>
                    <div className="ticket__type__detail__main__container__2">
                      <p className="ticket__name__Sales__1">Start Date/Time</p>
                      <p className="ticket__detail__Sales__1">
                      {convertToLocal(allTickets?.[t_Idx]?.ticketStartDT) ??
                            "---"}
                      </p>
                    </div>
                    <div className="ticket__type__detail__main__container__2">
                      <p className="ticket__name__Sales__1">End Date/Time</p>
                      <p className="ticket__detail__Sales__1">
                      {convertToLocal(allTickets?.[t_Idx]?.ticketEndDT) ??
                            "---"}
                      </p>
                    </div>
                  </div>
                </div>
                {/* passwords */}
                <div className="table__uper__content__wraper">
                  <p className="your__password__text">Your Passwords</p>
                  <Box>
                    <Box
                      sx={{
                        height: "24px",
                        backgroundColor: "transparent",
                        mb: "16px",
                      }}
                    ></Box>

                    <TableContainer
                     className="Organizer_detail_Follower_Main_____"
                      component={Paper}
                      sx={{
                        backgroundColor: "rgba(255, 255, 255, 0.06)",
                        overflowX: "auto",
                        borderBottom: "none",
                      }}
                    >
                      <Table>
                        <TableHead
                          sx={{
                            borderBottom: "1px solid #292929",
                          }}
                          className="to-apply-table-border-color"
                        >
                          <TableRow>
                            <TableCell
                              sx={{
                                borderBottom: "1px solid #292929",
                                width: "0px",
                              }}
                              className="even-name-text-style-for_sales"
                            >
                              #
                            </TableCell>
                            <TableCell
                              sx={{
                                borderBottom: "1px solid #292929",
                                width: "220px",
                              }}
                              className="even-name-text-style-for_sales"
                            >
                              Passwords
                            </TableCell>
                          </TableRow>
                        </TableHead>

                        <TableBody
                          sx={{
                            background:
                              "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)",
                          }}
                          className="for-to-give-the-padding"
                        >
                          {decryptArrayWithErrors([
                            ...(ticket?.autoPasswordFields || []),
                            ...(ticket?.passwordFields || []),
                          ]).map((password, p_index) => (
                            <TableRow
                              key={p_index}
                              className="main-even-name-text-style-for_sales"
                            >
                              <TableCell
                                sx={{
                                  borderBottom: "1px solid transparent",
                                  whiteSpace: "nowrap",
                                }}
                                className="main-even-name-text-style-for_sales-padding-margin-name__sales_privateticketing"
                                align="center"
                              >
                                {p_index + 1}
                              </TableCell>
                              <TableCell
                                sx={{
                                  borderBottom: "1px solid transparent",
                                  width: "220px",
                                }}
                                className="main-even-name-text-style-for_sales-padding-margin-name__sales_privateticketing"
                              >
                                {password ?? "---"}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </div>
                {/* Emails Table Body */}
                <div className="table__uper__content__wraper">
                  <div className="table__uper__content__wraper_1">
                    <div className="box_uper_text-wraper">
                      <p className="box_uper_text">Tickets Sold</p>
                      <p className="box_uper_text2">
                        {(ticket?.originalNoOfTickets ?? 0) -
                          (ticket?.noOfTickets ?? 0)}
                      </p>
                    </div>
                    <div className="box_uper_text-wraper">
                      <p className="box_uper_text">
                        Percentage of emails that bought
                      </p>
                      <p className="box_uper_text2">
                        {getEmailPercent(t_Idx).toFixed(1)}%
                      </p>
                    </div>
                  </div>

                  <Box>
                    <Box
                      sx={{
                        height: "24px",
                        backgroundColor: "transparent",
                        mb: "16px",
                      }}
                    ></Box>

                    <TableContainer
                     className="Organizer_detail_Follower_Main_____"
                      component={Paper}
                      sx={{
                        backgroundColor: "rgba(255, 255, 255, 0.06)",
                        overflowX: "auto",
                        borderBottom: "none",
                      }}
                    >
                      <Table>
                        <TableHead
                          sx={{
                            borderBottom: "1px solid #292929",
                          }}
                          className="to-apply-table-border-color"
                        >
                          <TableRow>
                            <TableCell
                              sx={{
                                borderBottom: "1px solid #292929",
                                width: "105px",
                              }}
                              className="even-name-text-style-for_sales"
                            >
                              #
                            </TableCell>
                            <TableCell
                              sx={{
                                borderBottom: "1px solid #292929",
                                width: "220px",
                              }}
                              className="even-name-text-style-for_sales"
                            >
                              Emails
                            </TableCell>
                            <TableCell
                              sx={{
                                borderBottom: "1px solid #292929",
                                width: "220px",
                              }}
                              className="even-name-text-style-for_sales"
                            >
                              Status
                            </TableCell>
                          </TableRow>
                        </TableHead>

                        <TableBody
                          sx={{
                            background:
                              "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)",
                          }}
                          className="for-to-give-the-padding"
                        >
                          {[
                            ...ticket?.privateEventAdditionalFields,
                            ...(ticket?.csvEmails ?? []),
                          ].map((email, e_Idx) => (
                            <TableRow
                              key={e_Idx}
                              className="main-even-name-text-style-for_sales"
                            >
                              <TableCell
                                sx={{
                                  borderBottom: "1px solid transparent",
                                  whiteSpace: "nowrap",
                                }}
                                className="main-even-name-text-style-for_sales-padding-margin-name__sales_privateticketing"
                                align="center"
                              >
                                {e_Idx + 1}
                              </TableCell>
                              <TableCell
                                sx={{
                                  borderBottom: "1px solid transparent",
                                  width: "220px",
                                }}
                                className="main-even-name-text-style-for_sales-padding-margin-name__sales_privateticketing"
                              >
                                {email ?? "---"}
                              </TableCell>
                              <TableCell
                                sx={{
                                  borderBottom: "1px solid transparent",
                                  width: "220px",
                                }}
                                className="main-even-name-text-style-for_sales-padding-margin-name__sales_privateticketing"
                              >
                                {checkEmailStatus(email, t_Idx)
                                  ? "Bought"
                                  : "Didn't Buy"}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </div>
              </>
            ))}
          </div>
        ) : act === "Attendance" ? (
          <>
            {isloading && <ScreenLoader />}
            <div className="attendance__events__main__container">
              {allTicketAttendees?.map((t_attend, a_Idx) => (
                <div key={a_Idx}>
                  <div className="ticket__type__detail__main__container">
                    <div className="ticket__type__detail__main__container__1">
                      <div className="ticket__type__detail__main__container__2">
                        <p className="ticket__name__Sales__1">
                          Name of Ticket Type
                        </p>
                        <p className="ticket__detail__Sales__1">
                          {allTickets?.[a_Idx]?.ticketName ?? "---"}
                        </p>
                      </div>
                      <div className="ticket__type__detail__main__container__2">
                        <p className="ticket__name__Sales__1">
                          Start Date/Time
                        </p>
                        <p className="ticket__detail__Sales__1">
                          {convertToLocal(allTickets?.[a_Idx]?.ticketStartDT) ??
                            "---"}
                        </p>
                      </div>
                      <div className="ticket__type__detail__main__container__2">
                        <p className="ticket__name__Sales__1">End Date/Time</p>
                        <p className="ticket__detail__Sales__1">
                          {convertToLocal(allTickets?.[a_Idx]?.ticketEndDT) ??
                            "---"}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div>
                    <Box>
                      <Box
                        sx={{
                          height: "24px",
                          backgroundColor: "transparent",
                          mb: "16px",
                        }}
                      ></Box>

                      <TableContainer
                       className="Organizer_detail_Follower_Main_____"
                        component={Paper}
                        sx={{
                          backgroundColor: "rgba(255, 255, 255, 0.06)",
                          overflowX: "auto",
                          borderBottom: "none",
                        }}
                      >
                        <Table>
                          <TableHead
                            sx={{
                              background:
                                "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)",
                              boxShadow: "0px 0.96px 1.91px 0px #1E1E240F",
                              backdropFilter: "blur(202.8699951171875px)",
                              borderBottom: "1px solid #292929",
                            }}
                            className="to-apply-table-border-color"
                          >
                            <TableRow>
                              <TableCell
                                sx={{
                                  borderBottom: "1px solid #292929",
                                  width: "220px",
                                }}
                                className="even-name-text-style-for_sales"
                              >
                                Name
                              </TableCell>
                              <TableCell
                                sx={{
                                  borderBottom: "1px solid #292929",
                                  width: "220px",
                                }}
                                className="even-name-text-style-for_sales"
                              >
                                Email
                              </TableCell>
                              <TableCell
                                sx={{
                                  borderBottom: "1px solid #292929",
                                  width: "220px",
                                  whiteSpace:'nowrap'
                                }}
                                className="even-name-text-style-for_sales"
                              >
                                Scanned Time
                              </TableCell>
                            </TableRow>
                          </TableHead>

                          <Box
                            sx={{
                              height: "20px",
                              backgroundColor: "transparent",
                            }}
                          ></Box>
                          <TableBody
                            sx={{
                              background:
                                "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)",
                            }}
                            className="for-to-give-the-padding"
                          >
                            {t_attend?.allAattendees?.map(
                              (atnde, atnd_Index) =>
                                atnde?.scan && (
                                  <TableRow
                                    key={atnd_Index}
                                    className="main-even-name-text-style-for_sales"
                                  >
                                    <TableCell
                                      sx={{
                                        borderBottom: "1px solid transparent",
                                        whiteSpace: "nowrap",
                                      }}
                                      className="main-even-name-text-style-for_sales-padding-margin-name__sales_privateticketing"
                                    >
                                      {atnde?.fullName ?? "---"}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        borderBottom: "1px solid transparent",
                                        width: "220px",
                                      }}
                                      className="main-even-name-text-style-for_sales-padding-margin-name__sales_privateticketing"
                                    >
                                      {atnde?.email ?? "---"}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        borderBottom: "1px solid transparent",
                                        width: "220px",
                                      }}
                                      className="main-even-name-text-style-for_sales-padding-margin-name__sales_privateticketing"
                                    >
                                      {atnde?.scannedDate ?? "---"}
                                    </TableCell>
                                  </TableRow>
                                )
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  </div>
                </div>
              ))}
            </div>
          </>
        ) : (
          <>
            {isloading && <ScreenLoader />}
            <div className="wallet-container">
              {allTicketAttendees?.map((t_attend, a_Idx) => (
                <div key={a_Idx}>
                  <div className="ticket__type__detail__main__container">
                    <div className="ticket__type__detail__main__container__1">
                      <div className="ticket__type__detail__main__container__2">
                        <p className="ticket__name__Sales__1">
                          Name of Ticket Type
                        </p>
                        <p className="ticket__detail__Sales__1">
                          {allTickets?.[a_Idx]?.ticketName ?? "---"}
                        </p>
                      </div>
                      <div className="ticket__type__detail__main__container__2">
                        <p className="ticket__name__Sales__1">
                          Start Date/Time
                        </p>
                        <p className="ticket__detail__Sales__1">
                          {convertToLocal(allTickets?.[a_Idx]?.ticketStartDT) ??
                            "---"}
                        </p>
                      </div>
                      <div className="ticket__type__detail__main__container__2">
                        <p className="ticket__name__Sales__1">End Date/Time</p>
                        <p className="ticket__detail__Sales__1">
                          {convertToLocal(allTickets?.[a_Idx]?.ticketEndDT) ??
                            "---"}
                        </p>
                      </div>
                    </div>
                  </div>
                  <Box>
                    <Box
                      sx={{
                        height: "24px",
                        backgroundColor: "transparent",
                        mb: "16px",
                      }}
                    ></Box>

                    <TableContainer
                      component={Paper}
                      sx={{
                        backgroundColor: "rgba(255, 255, 255, 0.06)",
                        overflowX: "auto",
                        borderBottom: "none",
                      }}
                      className="Organizer_detail_Follower_Main_____"
                    >
                      <Table>
                        <TableHead
                          sx={{
                            background:
                              "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)",
                            boxShadow: "0px 0.96px 1.91px 0px #1E1E240F",
                            backdropFilter: "blur(202.8699951171875px)",
                            borderBottom: "1px solid #292929",
                          }}
                          className="to-apply-table-border-color"
                        >
                          <TableRow>
                            <TableCell
                              sx={{
                                borderBottom: "1px solid #292929",
                                width: "120px",
                              }}
                              className="even-name-text-style-for_sales"
                            >
                              PFP
                            </TableCell>
                            <TableCell
                              sx={{
                                borderBottom: "1px solid #292929",
                                width: "220px",
                              }}
                              className="even-name-text-style-for_sales"
                            >
                              Name
                            </TableCell>
                            <TableCell
                              sx={{
                                borderBottom: "1px solid #292929",
                                width: "220px",
                              }}
                              className="even-name-text-style-for_sales"
                            >
                              Email
                            </TableCell>
                            <TableCell
                              sx={{
                                borderBottom: "1px solid #292929",
                                width: "180px",
                              }}
                              className="even-name-text-style-for_sales"
                            >
                              Date
                            </TableCell>
                            <TableCell
                              sx={{
                                borderBottom: "1px solid #292929",
                                width: "180px",
                              }}
                              className="even-name-text-style-for_sales"
                            >
                              Time
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <Box
                          sx={{
                            height: "20px",
                            backgroundColor: "transparent",
                          }}
                        ></Box>
                        <TableBody
                          sx={{
                            background:
                              "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)",
                            gap: "20px",
                          }}
                          className="for-to-give-the-padding"
                        >
                          {t_attend?.allAattendees?.map((atnde, atnd_Index) => (
                            <TableRow
                              key={`${a_Idx}-${atnd_Index}`}
                              className="main-even-name-text-style-for_sales"
                            >
                              <TableCell
                                sx={{
                                  borderBottom: "1px solid transparent",
                                  whiteSpace: "nowrap",
                                }}
                                className="main-even-name-text-style-for_sales-padding-margin-name__sales_privateticketing"
                              >
                                <img
                                  alt="user img"
                                  src={
                                    atnde?.user?.profilePicture ||
                                    "/Images/dashboard/naitram.png"
                                  }
                                  width={32}
                                  height={32}
                                  style={{
                                    borderRadius: "50%",
                                    objectFit: "cover",
                                  }}
                                />
                              </TableCell>
                              <TableCell
                                sx={{
                                  borderBottom: "1px solid transparent",
                                }}
                                className="main-even-name-text-style-for_sales-padding-margin-name__sales_privateticketing"
                              >
                                {atnde?.user?.fullname ?? "---"}
                              </TableCell>
                              <TableCell
                                sx={{
                                  borderBottom: "1px solid transparent",
                                }}
                                className="main-even-name-text-style-for_sales-padding-margin-name__sales_privateticketing"
                              >
                                {atnde?.email ?? "---"}
                              </TableCell>
                              <TableCell
                                sx={{
                                  borderBottom: "1px solid transparent",
                                }}
                                className="main-even-name-text-style-for_sales-padding-margin-name__sales_privateticketing"
                              >
                                {getLocalDate(atnde?.createdAt) ?? "---"}
                              </TableCell>
                              <TableCell
                                sx={{
                                  borderBottom: "1px solid transparent",
                                }}
                                className="main-even-name-text-style-for_sales-padding-margin-name__sales_privateticketing"
                              >
                                {getLocalTime(atnde?.createdAt) ?? "---"}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
      </div>
    </>
  );
}

export default PasswordEvents;
