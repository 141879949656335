import React, { useEffect, useState } from "react";
// import './CollectiblesOwned.css'
import "./Pizdez-table.css";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ScreenLoader from "../../Components/loader/screenLoader";
import { getEventReportDetail } from "../../services/redux/middleware/getEventReportById";
import ReportModal from "../../Components/ReportModal/reportModal";
function Pizdez_table() {
  const containerStyle = {
    borderWidth: "0px",
    borderStyle: "solid",
    borderImageSlice: 1,
    borderImageSource:
      "linear-gradient(180deg, rgba(251, 251, 251, 0.6) 0%, rgba(251, 251, 251, 0.7) 100%)",
    borderRadius: "0.5rem",
    backgroundColor: "transparent",
    height: "100vh",
    overflowY: "scroll",
    // padding: '20px',
  };

  const scrollbarStyles = `
    ::-webkit-scrollbar {
      width: 5px;
    }
    ::-webkit-scrollbar-track {
      background: transparent;
    }
    ::-webkit-scrollbar-thumb {
      background: #13FF7A;
      border-radius: 5px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #13ff79b9;
    }
  `;
  const { eventId } = useParams();
  const [modalData, setModalData] = useState(null);
  const [modalText, setModalText] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const EventReportData = useSelector(
    (state) =>
      state?.getEventreportById?.getEventReportDetailData?.data?.reports
  );

  console.log("Event report data is...", EventReportData);

  useEffect(() => {
    setLoading(true);
    if (eventId) {
      dispatch(getEventReportDetail(eventId)).finally(() => {
        setLoading(false);
      });
    }
  }, [dispatch, eventId]);

  const handleDownloadCSV = () => {
    if (!EventReportData || EventReportData.length === 0) {
      toast.error("No data available to export!");
      return;
    }

    const headers = ["Reported by Name", "Reported by Email", "Date", "Reason"];
    const rows = EventReportData.map((row) => [
      row?.users?.fullname || "---",
      row?.users?.email || "---",
      row?.createdAt.split("T")[0] || "---",
      "View Reason",
    ]);

    const csvContent = [
      headers.join(","),
      ...rows.map((row) => row.join(",")),
    ].join("\n");

    const encodedUri = encodeURI(`data:text/csv;charset=utf-8,${csvContent}`);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "event_report_details.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const navigate = useNavigate();

  const navigateReport = () => {
    navigate(-1);
    
  };
  const handleClose = () => setModalShow(false);
  return (
    <>
      {loading && <ScreenLoader />}
      <div style={containerStyle}>
        <style>{scrollbarStyles}</style>
        <div className="personal-info-detail-container-new">
          <div
            onClick={navigateReport}
            className="Organiser_Detail_HostedEvents_Sales_Analytics_Main_heading_and_image"
          >
            <img
              style={{ cursor: "pointer" }}
              src="/Payout_Images/graphlogo.svg"
            />
            <h2>
              {EventReportData && EventReportData.length > 0
                ? EventReportData[0].eventName
                : "No Event Available"}
            </h2>
          </div>
          <div className="CollectiblesOwned-to-give-width-attendeed">
            <div
              style={{
                paddingRight: "0px",
                cssText: "padding-right: 0px !important;",
              }}
              className="export-button"
            >
              <button onClick={handleDownloadCSV}>Export</button>
            </div>
            <Box>
              <Box
                sx={{ height: "50px", backgroundColor: "#000", mb: "16px" }}
              ></Box>
              {!EventReportData || EventReportData.length === 0 ? (
                <div className="empty__state__main__cintainer__1">
                  <Typography
                    className="empty_state_text"
                    variant="h6"
                    sx={{
                      marginBottom: "16px",
                      fontSize: "1rem",
                      fontWeight: 500,
                    }}
                  >
                    There’s No Report Data
                  </Typography>
                </div>
              ) : (
                <TableContainer
                  className="Organizer_detail_Follower_Main_____"
                  component={Paper}
                  sx={{
                    backgroundColor: "rgba(255, 255, 255, 0.06)",
                    overflowX: "auto",
                  }}
                >
                  <Table>
                    <TableHead
                      sx={{ backgroundColor: "rgba(255, 255, 255, 0.06)" }}
                      className="to-apply-table-border-color"
                    >
                      {EventReportData?.length > 0 && (
                        <TableRow>
                          <TableCell
                            sx={{
                              borderBottom: "1px solid #292929",
                              width: "105px",
                            }}
                            className="even-name-text-style-for"
                          >
                            Reported by Name
                          </TableCell>
                          <TableCell
                            sx={{
                              borderBottom: "1px solid #292929",
                              width: "220px",
                            }}
                            className="even-name-text-style-for"
                          >
                            Reported by Email
                          </TableCell>
                          <TableCell
                            sx={{
                              borderBottom: "1px solid #292929",
                              width: "220px",
                            }}
                            className="even-name-text-style-for"
                          >
                            Date
                          </TableCell>
                          <TableCell
                            sx={{
                              borderBottom: "1px solid rgba(41, 41, 41, 1)",
                              width: "220px",
                            }}
                            className="even-name-text-style-for"
                          >
                            Reason
                          </TableCell>
                        </TableRow>
                      )}
                    </TableHead>

                    {/* Spacer row for gap */}
                    {EventReportData?.length > 0 && (
                      <TableRow
                        sx={{ height: "0px", backgroundColor: "#030303" }}
                      >
                        <TableCell colSpan={4}></TableCell>
                      </TableRow>
                    )}

                    <TableBody className="for-to-give-the-padding">
                      {EventReportData?.map((row, index) => (
                        <TableRow
                          key={index}
                          className="main-even-name-text-style-for"
                        >
                          <TableCell
                            sx={{ borderBottom: "1px solid #292929" }}
                            className="main-even-name-text-style-for-padding-margin-name"
                          >
                            {row?.users?.fullname
                              ? row?.users?.fullname
                              : "-----"}
                          </TableCell>
                          <TableCell
                            sx={{
                              borderBottom: "1px solid #292929",
                              width: "105px",
                            }}
                            className="main-even-name-text-style-for-padding-margin-name_new"
                          >
                            {row?.users?.email ? row?.users?.email : "-----"}
                          </TableCell>
                          <TableCell
                            sx={{
                              borderBottom: "1px solid #292929",
                              width: "220px",
                            }}
                            className="main-even-name-text-style-for-padding-margin-type"
                          >
                            {row?.createdAt.split("T")[0]
                              ? row?.createdAt.split("T")[0]
                              : "----"}
                          </TableCell>
                          <TableCell
                            onClick={() => {
                              setModalText(`${row?.text}`);
                              setModalShow(true);
                            }}
                            sx={{
                              borderBottom: "1px solid #292929",
                              width: "220px",
                              cursor: "pointer",
                              whiteSpace: "nowrap",
                            }}
                            className="main-even-name-text-style-for-padding-margin-type-newcolor"
                          >
                            View Reason
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Box>
          </div>
          {modalShow && (
            <ReportModal
              show={modalShow}
              text={modalText}
              handleClose={handleClose}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default Pizdez_table;
