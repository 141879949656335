import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./feedbackPage.css";
import { useNavigate, useLocation } from "react-router-dom"; // Import `useLocation`
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Stack from "@mui/material/Stack";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { getFeedback } from "../../services/redux/middleware/getFeedback";
import ScreenLoader from "../../Components/loader/screenLoader";

function FeedbackPage() {
  const containerRef = useRef(null);

  const containerStyle = {
    borderWidth: "0px",
    borderStyle: "solid",
    borderImageSlice: 1,
    borderImageSource:
      "linear-gradient(180deg, rgba(251, 251, 251, 0.6) 0%, rgba(251, 251, 251, 0.7) 100%)",
    borderRadius: "0.5rem",
    backgroundColor: "transparent",
    height: "100vh",
    overflowY: "scroll",
  };

  const scrollbarStyles = `
    ::-webkit-scrollbar {
      width: 5px;
    }
    ::-webkit-scrollbar-track {
      background: transparent;
    }
    ::-webkit-scrollbar-thumb {
      background: #13FF7A;
      border-radius: 5px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #13ff79b9;
    }
  `;

  const navigate = useNavigate();
  const location = useLocation();  // To get the current URL
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);

  const feedbackData = useSelector(
    (state) => state?.getFeedback?.getFeedbackData?.data || {}
  );
  const { feedback = [], pagination = {} } = feedbackData;
  const { currentPage: serverPage, totalPages = 1 } = pagination;

  useEffect(() => {
  
    const queryParams = new URLSearchParams(location.search);
    const pageFromURL = queryParams.get("page") || localStorage.getItem("feedbackPage") || 1;
    setCurrentPage(Number(pageFromURL));

    setLoading(true);
    dispatch(getFeedback({ page: pageFromURL })).finally(() => setLoading(false));
  }, [dispatch, location.search]); 

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    localStorage.setItem("feedbackPage", value); 

    navigate(`?page=${value}`, { replace: true });

    if (containerRef.current) {
      containerRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const navigateFeedback = (eventId) => {
    navigate(`/feedbackPizdeztable/${eventId}`);
  };

  return (
    <>
      {loading && <ScreenLoader />}
      <div className="main__event__table__1">
        <div className="event__main__container" style={containerStyle} ref={containerRef}>
          <style>{scrollbarStyles}</style>
          <div className="events-container">
            {feedback.map((row, index) => (
              <div
                key={index}
                onClick={() => navigateFeedback(row?.eventId)}
                className="event-card"
                style={{ backgroundImage: `url(${row?.coverEventImage})` }}
              >
                <p className="event-title">
                  {row?.eventName ? row?.eventName : "-----"}
                </p>
              </div>
            ))}
          </div>

          <Stack
            spacing={2}
            sx={{
              padding: "1rem",
              borderRadius: "8px",
              paddingBottom: "180px",
            }}
          >
            <Pagination
              count={totalPages}
              page={serverPage || currentPage}
              onChange={handlePageChange}
              sx={{
                "& .MuiPagination-ul": {
                  display: "flex",
                  gap: "10px",
                  justifyContent: "flex-end",
                },
              }}
              renderItem={(item) => (
                <PaginationItem
                  slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                  {...item}
                  sx={{
                    color: "#fff",
                    borderRadius: "10px",
                    border: "1px solid rgba(255, 255, 255, 0.5)",
                    "&:hover": {
                      backgroundColor: "rgba(255, 255, 255, 0.1)",
                    },
                    "&.Mui-selected": {
                      backgroundColor: "#00d059",
                      color: "#fff",
                      borderRadius: "10px",
                    },
                  }}
                />
              )}
            />
          </Stack>
        </div>
      </div>
    </>
  );
}

export default FeedbackPage;
