import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "./MrtRewards.css";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import ScreenLoader from "../../Components/loader/screenLoader";
import { getMrtReward } from "../../services/redux/middleware/getMrtReward";
const MrtRewards = ({ mrtReward, reward }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [mrtData, setMrtData] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (reward?.getClaimUserData?.data?.history) {
      setLoading(false);
    } else {
      const timer = setTimeout(() => {
        setLoading(false);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [reward?.getClaimUserData?.data?.history]);

  console.log("my reward data is ", reward?.getClaimUserData?.data?.history);
  useEffect(() => {
    if (mrtReward?.getOrganizationProfileData?.data) {
      console.log(
        "MRT reward data is:",
        mrtReward?.getOrganizationProfileData?.data
      );
      setMrtData(mrtReward.getOrganizationProfileData.data);
    }
  }, [mrtReward]);
  const exportToCSV = () => {
    if (
      !reward?.getClaimUserData?.data?.history ||
      reward?.getClaimUserData?.data?.history.length === 0
    ) {
      toast.error("No data available to export!");
      return;
    }
  
    const csvHeaders = ["Reward Name", "MRT Reward", "Date", "Time"];
    const csvRows = reward?.getClaimUserData?.data?.history.map((row) => [
      row?.type || "----",
      row?.amount || "----",
      row?.date || "----",
      row?.time || "----",
    ]);
  
    const csvContent = [
      csvHeaders.join(","),
      ...csvRows.map((row) => row.join(",")),
    ].join("\n");
  
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.download = "MrtRewards_History.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };
  
  return (
    <div
      style={{ paddingBottom: "100px" }}
      className="personal-info-detail-container-mrt-rewards"
    >
      {loading ? (
        <ScreenLoader />
      ) : (
        <>
          <div className="export-button">
            <button onClick={exportToCSV}>Export</button>
          </div>
          <div className="mtr-owned-center-part">
            <p>MRT Owned</p>
            <h2 className="smjh-se-bahr-q">${mrtData?.totalAmount} MRT</h2>
          </div>

          <div className="box-main-both-head-and-body">
            <Box>
              <Box
                sx={{ height: "30px", backgroundColor: "#000", mb: "16px" }}
              ></Box>
              {!reward?.getClaimUserData?.data?.history?.length ? (
                <div>
                  <div className="empty__state__main__cintainer__1">
                    <Typography className="empty_state_text" variant="h6">
                      There’s No MRT Rewards
                    </Typography>
                  </div>
                </div>
              ) : (
                <TableContainer
                  className="Organizer_detail_Follower_Main_____"
                  component={Paper}
                  sx={{
                    backgroundColor: "rgba(255, 255, 255, 0.06)",
                    overflowX: "auto",
                  }}
                >
                  <Table>
                    {reward?.getClaimUserData?.data?.history?.length > 0 && (
                      <TableHead
                        sx={{ backgroundColor: "rgba(255, 255, 255, 0.06)" }}
                        className="to-apply-table-border-color"
                      >
                        <TableRow>
                          <TableCell
                            sx={{
                              borderBottom: "1px solid #292929",
                              width: "275px",
                            }}
                            className="even-name-text-style-for"
                          >
                            Reward Name
                          </TableCell>
                          <TableCell
                            sx={{
                              borderBottom: "1px solid rgba(41, 41, 41, 1)",
                              width: "275px",
                            }}
                            className="even-name-text-style-for"
                          >
                            MRT Reward
                          </TableCell>
                          <TableCell
                            sx={{
                              borderBottom: "1px solid rgba(41, 41, 41, 1)",
                              width: "275px",
                            }}
                            className="even-name-text-style-for"
                          >
                            Date
                          </TableCell>
                          <TableCell
                            sx={{
                              borderBottom: "1px solid rgba(41, 41, 41, 1)",
                              width: "275px",
                            }}
                            className="even-name-text-style-for"
                          >
                            Time
                          </TableCell>
                        </TableRow>
                      </TableHead>
                    )}

                    {/* Spacer row for gap */}
                    {reward?.getClaimUserData?.data?.history?.length > 0 && (
                      <TableRow
                        sx={{ height: "16px", backgroundColor: "#030303" }}
                      >
                        <TableCell colSpan={4}></TableCell>
                      </TableRow>
                    )}

                    <TableBody
                      style={{
                        background:
                          "linear-gradient(360deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.06) 100%)",
                      }}
                    >
                      {/* Loop through the data */}
                      {reward?.getClaimUserData?.data?.history?.map(
                        (row, index) => (
                          <TableRow
                            key={index}
                            className="main-even-name-text-style-for"
                          >
                            <TableCell
                              sx={{ borderBottom: "1px solid #292929" }}
                              className="main-even-name-text-style-for-padding-margin-name"
                            >
                              {row?.type || "----"}
                            </TableCell>
                            <TableCell
                              sx={{ borderBottom: "1px solid #292929" }}
                              className="main-even-name-text-style-for-padding-margin-type"
                            >
                              {row?.amount || "----"}
                            </TableCell>
                            <TableCell
                              sx={{ borderBottom: "1px solid #292929" }}
                              className="main-even-name-text-style-for-padding-margin-type"
                            >
                              {row?.date || "----"}
                            </TableCell>
                            <TableCell
                              sx={{ borderBottom: "1px solid #292929" }}
                              className="main-even-name-text-style-for-padding-margin-type"
                            >
                              {row?.time || "----"}
                            </TableCell>
                          </TableRow>
                        )
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Box>
          </div>
        </>
      )}
    </div>
  );
};

export default MrtRewards;
