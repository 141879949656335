"use client";

import React, { createContext, useContext, useState, useEffect } from "react";


// Create the context
const EventContext = createContext(null);

// Provider component
export const EventProvider = ({ children }) => {
  const [currentTicketData, setCurrentTicketData] = useState(() => {
    if (typeof window !== "undefined") {
      const savedEventData = localStorage.getItem("currentTicketData");
      return savedEventData ? JSON.parse(savedEventData) : null;
    }
    return null;
  });

  useEffect(() => {
    if (currentTicketData) {
      localStorage.setItem("currentTicketData", JSON.stringify(currentTicketData));
    }
  }, [currentTicketData]);

  return <EventContext.Provider value={{ currentTicketData, setCurrentTicketData }}>{children}</EventContext.Provider>;
};

// Hook to use the context
export const useEvent = () => {
  const context = useContext(EventContext);

  if (!context) {
    throw new Error("useEvent must be used within an EventProvider");
  }

  return context;
};
