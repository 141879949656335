import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "./Organiser_Detail_Liked_Events__Table.css";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";
import ScreenLoader from "../loader/screenLoader";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getLikedEventsOrganizer } from "../../services/redux/middleware/getLikedEventsOrganizer";

const Organiser_Detail_Liked_Events__Table = () => {
  const [eventsData, setEventsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const { organizerId } = useParams();

  const likedEvents = useSelector(
    (state) =>
      state?.getLikedEventsOrganizer?.getLikedEventsData?.data?.events || []
  );

  useEffect(() => {
    setLoading(true);
    dispatch(getLikedEventsOrganizer({ userId: organizerId }));
  }, [dispatch, organizerId]);

  useEffect(() => {
    if (likedEvents) {
      setEventsData(likedEvents);
      setLoading(false);
    }
  }, [likedEvents]);

  const generateCSVData = () => {
    const headers = [
      "Picture URL",
      "Event Name",
      "Organiser Name",
      "Category",
      "Likes",
    ];
    const rows = eventsData.map((row) => [
      row?.coverEventImage || "----",
      row?.name || "----",
      row?.user?.fullname || "----",
      row?.category?.join(", ") || "----", // Handle non-array categories
      row?.likeCount || "0",
    ]);

    const csvContent = [
      headers.join(","),
      ...rows.map((row) => row.join(",")),
    ].join("\n");

    return csvContent;
  };

  const downloadCSV = () => {
    if (!eventsData || eventsData.length === 0) {
      toast.error("No data available to export!");
      return;
    }
    const csvData = generateCSVData();
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "liked_events_data.csv";
    link.click();
  };

  return (
    <>
      {loading && <ScreenLoader />}
      <div style={{ paddingBottom: "100px" }}>
        <div className="export-button">
          <button onClick={downloadCSV} className="User__detail_Scanner___Btn">
            Export
          </button>
        </div>
        {eventsData.length === 0 ? (
          <div className="empty__state__main__cintainer__2">
            <Typography className="empty_state_text" variant="h6">
              There’s No Liked Events
            </Typography>
          </div>
        ) : (
          <TableContainer
            component={Paper}
            className="Organizer_detail_Follower_Main"
          >
            <Table
              sx={{ minWidth: 750, maxWidth: 1020 }}
              aria-label="liked events table"
            >
              {eventsData?.length > 0 && (
                <>
                  <TableHead
                    sx={{
                      backgroundColor: "rgba(255, 255, 255, 0.06)",
                      width: "275px",
                      borderBottom: "1px solid #292929",
                    }}
                  >
                    <TableRow>
                      <TableCell className="Organizer_detail_Follower_Main___Row">
                        Picture
                      </TableCell>
                      <TableCell className="Organizer_detail_Follower_Main___Row">
                        Event Name
                      </TableCell>
                      <TableCell
                        style={{ whiteSpace: "nowrap" }}
                        className="Organizer_detail_Follower_Main___Row"
                      >
                        Organiser Name
                      </TableCell>
                      <TableCell className="Organizer_detail_Follower_Main___Row">
                        Category
                      </TableCell>
                      <TableCell className="Organizer_detail_Follower_Main___Row">
                        Likes
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  {/* Spacer row for gap */}
                  <TableRow sx={{ height: "16px", backgroundColor: "#030303" }}>
                    <TableCell colSpan={7}></TableCell>
                  </TableRow>
                </>
              )}
              <TableBody>
                {eventsData?.map((row, index) => (
                  <TableRow
                    key={index}
                    className="Organizer_detail_Follower_Main___Body_Top"
                  >
                    <TableCell className="Organizer_detail_Follower_Main___Body">
                      <img
                        style={{
                          height: "40px",
                          width: "40px",
                          borderRadius: "8px",
                        }}
                        src={
                          row?.coverEventImage ||
                          "/Images/dashboard/naitram.png"
                        }
                        alt="Event Cover"
                      />
                    </TableCell>
                    <TableCell className="Organizer_detail_Follower_Main___Body">
                      {row?.name || "---"}
                    </TableCell>
                    <TableCell className="Organizer_detail_Follower_Main___Body1">
                      {row?.user?.fullname || "----"}
                    </TableCell>
                    <TableCell className="Organizer_detail_Follower_Main___Body1">
                      {Array.isArray(row?.category)
                        ? row.category.map((cat, idx) => (
                            <span key={idx}>
                              {cat}
                              {idx < row.category.length - 1 ? ", " : ""}
                            </span>
                          ))
                        : "----"}
                    </TableCell>
                    <TableCell className="Organizer_detail_Follower_Main___Body1">
                      {row?.likeCount || "0"}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
    </>
  );
};

export default Organiser_Detail_Liked_Events__Table;
