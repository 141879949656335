import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
} from "@mui/material";
import { TableFooter } from "@mui/material";
//import Image from "react-image"; // Replace Next.js Image with a React.js alternative
import { getTicketsByEventNTicketIndex } from "../../services/redux/middleware/salesanalytics";
import ScreenLoader from "../loader/screenLoader";
import { toast } from "react-toastify";
import "./whiteListTicketing.css";
import { useNavigate } from "react-router-dom";

const  WhiteListEventTicketing = () => {
  const containerStyle = {
    borderWidth: "0px",
    borderStyle: "solid",
    borderImageSlice: 1,
    borderImageSource:
      "linear-gradient(180deg, rgba(251, 251, 251, 0.6) 0%, rgba(251, 251, 251, 0.7) 100%)",
    borderRadius: "0.5rem",
    backgroundColor: "transparent",
    height: "100vh",
    overflowY: "scroll",
    // padding: '20px',
  };

  const scrollbarStyles = `
    ::-webkit-scrollbar {
      width: 5px;
    }
    ::-webkit-scrollbar-track {
      background: transparent;
    }
    ::-webkit-scrollbar-thumb {
      background: #13FF7A;
      border-radius: 5px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #13ff79b9;
    }
  `;
  //   const SelectedOption = "Sales" | "Attendance" | "Users" | null;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentTicketData, setCurrentTicketData] = useState("");
  const [eventId, setEventID] = useState(0);
  const [act, setAct] = useState("Sales"); // Initial state set to "A"

  const handlebtnclick = (type) => {
    setAct(type); // Update the state to reflect the selected button
  };
  useEffect(() => {
    const storedTicketData = localStorage.getItem("currentTicketData");
    if (storedTicketData) {
      const parsedData = JSON.parse(storedTicketData);
      setCurrentTicketData(parsedData);
      setEventID(parsedData?.eventId);
    }
  }, []);
  // Use useSelector directly

  console.log("data", currentTicketData);

  const [selected, setSelected] = useState("Sales");
  const [isloading, setIsLoading] = useState(false);
  const [allTickets, setAllTickets] = useState([]);
  const [allTicketAttendees, setAllTicketAttendees] = useState([]);

  useEffect(() => {
    console.log("Triggered Ticket Name ===> ", currentTicketData?.name);
  }, [currentTicketData]);

  useEffect(() => {
    const fetchData = async () => {
      console.log(
        "sdkjhsdkjfh ==> ",
        currentTicketData?.name,
        " All indexes are as==> ",
        currentTicketData?.tIndexes
      );
      console.log(
        " All Ticket are ==> ",
        currentTicketData?.allTickets,
        " Event ID==> ",
        currentTicketData?.eventId
      );

      setAllTickets(currentTicketData?.allTickets);

      try {
        setIsLoading(true);
        for (const t_index of currentTicketData?.tIndexes) {
          const data = {
            eventId: currentTicketData?.eventId,
            ticketIndex: t_index,
          };
          const res = await dispatch(getTicketsByEventNTicketIndex(data));
          console.log(`Data of ${t_index} Ticket ==> `, res);

          if (res?.payload?.status === 200) {
            setIsLoading(false);
            setAllTicketAttendees((prev) => {
              let totalAttendees = [];
              let nameTicket = "";
              const attendeesData = res?.payload?.data;

              attendeesData?.forEach((attnde) => {
                nameTicket = attnde?.ticketTypes?.ticketName;
                totalAttendees.push(attnde);
              });

              const attendeesObject = {
                t_name: nameTicket,
                allAattendees: totalAttendees,
              };

              const isDuplicate = prev.some(
                (item) => item.t_name === attendeesObject.t_name
              );

              if (!isDuplicate) {
                return [...prev, attendeesObject];
              }
              return prev;
            });
          } else {
            setIsLoading(false);
            toast("Something went wrong!");
          }
        }
      } catch (error) {
        setIsLoading(false);
        console.error("Error fetching tickets:", error);
      }
    };

    fetchData();
  }, [currentTicketData, dispatch]);

  // Convert UTC date to local time with ordinal day
  const convertToLocal = (utcDate) => {
    const date = new Date(utcDate);

    // Function to get the ordinal suffix (st, nd, rd, th) for day
    function getOrdinal(n) {
      const suffixes = ["th", "st", "nd", "rd"];
      const value = n % 100;
      return suffixes[(value - 20) % 10] || suffixes[value] || suffixes[0];
    }

    // Format the date
    const formattedDate = new Intl.DateTimeFormat("en-GB", {
      day: "numeric",
      month: "long",
      year: "numeric",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    }).format(date);

    // Get the day with the ordinal suffix
    const dayWithOrdinal = `${date.getDate()}${getOrdinal(date.getDate())}`;

    // Final formatted string
    const finalFormattedDate = `${dayWithOrdinal} ${formattedDate
      .split(" ")
      .slice(1)
      .join(" ")
      .replace(":00", "")
      .replace(",", " -")}`;

    console.log(finalFormattedDate); // Output: "31st December, 2024 - 5 PM"
    return finalFormattedDate;
  };

  // Method to get the local date in the format "MM/DD/YY"
  const getLocalDate = (utcDateTime) => {
    const date = new Date(utcDateTime); // Parse UTC string
    const options = {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    };

    // Format the date to "MM/DD/YY"
    return date.toLocaleDateString(undefined, options);
  };

  // Method to get the local time in the format "HH:MM:SS"
  const getLocalTime = (utcDateTime) => {
    const date = new Date(utcDateTime); // Parse UTC string
    const options = {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    };

    // Format the time to "HH:MM:SS"
    return date.toLocaleTimeString(undefined, options);
  };

  // Check if the email is in the attendees list
  const checkEmailStatus = (currentEmail, ticketIndex) => {
    const allAttendees = allTicketAttendees?.[ticketIndex]?.allAattendees;
    if (allAttendees?.some((obj) => obj?.email === currentEmail)) {
      return true;
    } else {
      return false;
    }
  };

  // Get percentage of emails that have bought the tickets
  const getEmailPercent = (ticketIndex) => {
    const allAttendees = allTicketAttendees?.[ticketIndex]?.allAattendees;
    let countMatch = 0;
    const allPresenceEmail = [
      ...(allTickets?.[ticketIndex]?.privateEventAdditionalFields ?? []),
      ...(allTickets?.[ticketIndex]?.csvEmails ?? []),
    ];

    allPresenceEmail?.forEach((e) => {
      if (allAttendees?.some((obj) => obj?.email === e)) {
        countMatch += 1;
      }
    });

    const percent =
      ((allPresenceEmail?.length - countMatch) / allPresenceEmail?.length) *
      100;
    return percent;
  };

  // Example of useEffect to log data on load (can be used for fetching data)
  useEffect(() => {
    console.log("All Ticket Attendees ===> ", allTicketAttendees);
  }, [allTicketAttendees]);

  // const convertToCSV = (selectedTab) => {
  //   let header = [];
  //   let rows = [];

  //   // Conditionally handle each tab's data structure
  //   switch (selectedTab) {
  //     case 'Sales':
  //       header = ['Index', 'Start Date/Time', 'End Date/Time', 'Price', 'Sales', 'Revenue'];
  //       rows = currentTicketData?.allTickets?.map((ticket, d_Index) =>
  //         ticket?.festivalEventDates?.map(f_date => [
  //           d_Index + 1,
  //           convertToLocal(f_date?.eventStartDateTime) ?? "---",
  //           convertToLocal(f_date?.eventEndDateTime) ?? "---",
  //           `£${ticket?.ticketPrice}`,
  //           `${Number(ticket?.originalNoOfTickets) - Number(ticket?.noOfTickets)}`,
  //           `£${ticket?.ticketPrice * (Number(ticket?.originalNoOfTickets) - Number(ticket?.noOfTickets))}`
  //         ])
  //       );
  //       break;

  //     case 'Attendance':
  //       header = ['Full Name', 'Email', 'Scanned Date'];
  //       rows = allTicketAttendees?.map(atnde => [
  //         atnde?.fullName ?? "---",
  //         atnde?.email ?? "---",
  //         atnde?.scannedDate ?? "---"
  //       ]);

  //       break;

  //     case 'Users':
  //       header = ['Profile Picture', 'Full Name', 'Email', 'Date Created', 'Time Created'];
  //       rows = allTicketAttendees.map(atnde => [
  //         atnde?.user?.profilePicture || "/Images/dashboard/naitram.png",
  //         atnde?.user?.fullname ?? "---",
  //         atnde?.email ?? "---",
  //         getLocalDate(atnde?.createdAt) ?? "---",
  //         getLocalTime(atnde?.createdAt) ?? "---"
  //       ]);
  //       break;

  //     default:
  //       // Default header and rows if no tab matches
  //       header = ['Default Data'];
  //       rows = currentTicketData?.allTickets?.map(item => [item ?? '---']);
  //   } // Flatten the array of rows

  //   // Combine header and rows into CSV format
  //   const csvContent = [
  //     header.join(','),
  //     ...rows.map(row => Array.isArray(row) ? row.join(',') : row).join('\n')
  //   ].join('\n');

  //   return JSON.parse(csvContent);
  // };

  // // Function to trigger CSV download
  // const downloadCSV = (selectedTab) => {
  //   const csvContent = convertToCSV(selectedTab);

  //   const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  //   const link = document.createElement('a');
  //   if (link.download !== undefined) {
  //     const url = URL.createObjectURL(blob);
  //     link.setAttribute('href', url);
  //     link.setAttribute('download', `${JSON.stringify(selectedTab)}_data.csv`); // Tab name added to the filename
  //     link.click();
  //   }
  // };

  // const handleExportClick = (selectedTab) => {

  //   downloadCSV(selectedTab);
  // };

  function exportToCSV(act, allTickets, allTicketAttendees) {
    const actType = typeof act === "string" ? act.toLowerCase() : "";

    let headers = [];
    let rows = [];
    let data = [];

    if (actType === "sales") {
      headers = [
        "Ticket Name",
        "Event Days",
        "Start Date/Time",
        "End Date/Time",
        "Price",
        "Sales",
        "Revenue",
      ];

      rows = allTickets
        .map((ticket) => {
          const ticketName = ticket?.ticketName ?? "---";
          return ticket?.festivalEventDates?.map((f_date, d_Index) => {
            const eventDay = d_Index + 1;
            const startDate =
              convertToLocal(f_date?.eventStartDateTime) ?? "---";
            const endDate = convertToLocal(f_date?.eventEndDateTime) ?? "---";
            const price = `£${ticket?.ticketPrice ?? 0}`;
            const sales =
              Number(ticket?.originalNoOfTickets) - Number(ticket?.noOfTickets);
            const revenue = `£${(ticket?.ticketPrice * sales).toFixed(2)}`;

            return [
              ticketName,
              eventDay,
              startDate,
              endDate,
              price,
              sales,
              revenue,
            ];
          });
        })
        .flat(); 
    } else if (actType === "attendance") {
      headers = ["Ticket Name", "Attendee Name", "Email", "Scanned Time"];

      rows = allTicketAttendees
        .map((t_attend) => {
          return t_attend?.allAattendees
            ?.map((atnde) => {
              if (atnde?.scan) {
                return [
                  t_attend?.ticketName ?? "---",
                  atnde?.fullName ?? "---",
                  atnde?.email ?? "---",
                  atnde?.scannedDate ?? "---",
                ];
              }
              return null;
            })
            .filter((row) => row !== null);
        })
        .flat();
    } else if (act === "Users") {
      headers = ["Full Name", "Email", "Created Date", "Created Time"];

      allTicketAttendees?.forEach((t_attend) => {
        t_attend?.allAattendees?.forEach((atnde) => {
          data.push([
            atnde?.fullName ?? "---",
            atnde?.email ?? "---",
            getLocalDate(atnde?.createdAt) ?? "---",
            getLocalTime(atnde?.createdAt) ?? "---",
          ]);
        });
      });

      rows = data;
    }
    if (!rows || rows.length === 0) {
      toast.error("No data available to export!");
      return;
    }

    const csvContent = [
      headers.join(","),
      ...rows.map((row) => row.join(",")),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", `${actType}_data.csv`);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  const handlenavigate = () => {
    navigate(-1);
  };
  return (
    <>
      {isloading && <ScreenLoader />}
      <div style={containerStyle}>
        <style>{scrollbarStyles}</style>
        <div className="private___ticketing__main__container__1">
          <div className="private___ticketing__main__container__2">
            <div
              style={{
                marginBottom: "0px",
                cssText: "margin-bottom: 0px !important;", // Unnecessary but retained
              }}
              className="Organiser_Detail_HostedEvents_Sales_Analytics_Main_heading_and_image"
            >
              <img
                onClick={handlenavigate}
                style={{ cursor: "pointer" }}
                src="/Payout_Images/graphlogo.svg"
                alt="Graph logo"
              />
              <h2>Festivals/Multi-Day Ticketing</h2>
            </div>
            <div className="top-buttons___new_try__sales">
              <button
                style={{
                  background:
                    act === "Sales"
                      ? "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%) padding-box, linear-gradient(167deg, #13FF7A 0.2%, #002B12 50.2%, #13FF7A 100.2%) border-box"
                      : "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%) padding-box, linear-gradient(360deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.06) 100%) border-box",
                  color: act === "Sales" ? "#00A849" : "#FFFFFF",
                  border: "1px solid transparent",
                }}
                className={act === "Sales" ? "active" : ""}
                onClick={() => handlebtnclick("Sales")}
              >
                Sales
              </button>

              <button
                style={{
                  background:
                    act === "Attendance"
                      ? "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%) padding-box, linear-gradient(167deg, #13FF7A 0.2%, #002B12 50.2%, #13FF7A 100.2%) border-box"
                      : "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%) padding-box, linear-gradient(360deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.06) 100%) border-box",
                  color: act === "Attendance" ? "#00A849" : "#FFFFFF",
                  border: "1px solid transparent",
                }}
                className={act === "Attendance" ? "active" : ""}
                onClick={() => handlebtnclick("Attendance")}
              >
                Attendance
              </button>

              <button
                style={{
                  background:
                    act === "Users"
                      ? "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%) padding-box, linear-gradient(167deg, #13FF7A 0.2%, #002B12 50.2%, #13FF7A 100.2%) border-box"
                      : "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%) padding-box, linear-gradient(360deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.06) 100%) border-box",
                  color: act === "Users" ? "#00A849" : "#FFFFFF",
                  border: "1px solid transparent",
                }}
                className={act === "Users" ? "active" : ""}
                onClick={() => handlebtnclick("Users")}
              >
                Users
              </button>
            </div>
          </div>
          <div
            style={{
              marginTop: "0px",
              cssText: "margin-top: 0px !important;",
            }}
            className="export-button"
          >
            <button
              onClick={() => exportToCSV(act, allTickets, allTicketAttendees)}
            >
              Export
            </button>
          </div>
          {act === "Sales" ? (
            <div>
              {allTickets?.map((ticket, t_Idx) => (
                <>
                  {/* Tickets Table Body */}
                  {t_Idx > 0 ? (
                    <p
                      className="ticket__detail__Sales__1_text"
                      style={{
                        marginTop: "50px",
                        cssText: "margin-top: 50px !important;",
                      }}
                    >
                      {ticket?.ticketName}
                    </p>
                  ) : (
                    <p className="ticket__detail__Sales__1">
                      {ticket?.ticketName}
                    </p>
                  )}

                  <Box>
                    <Box
                      sx={{
                        height: "24px",
                        backgroundColor: "transparent",
                        mb: "16px",
                      }}
                    ></Box>

                    <TableContainer
                      className="Organizer_detail_Follower_Main_____"
                      component={Paper}
                      sx={{
                        backgroundColor: "rgba(255, 255, 255, 0.06)",
                        overflowX: "auto",
                        borderBottom: "none",
                      }}
                    >
                      <Table>
                        <TableHead
                          sx={{
                            background:
                              "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)",
                            boxShadow: "0px 0.96px 1.91px 0px #1E1E240F",
                            backdropFilter: "blur(202.8699951171875px)",
                            borderBottom: "1px solid #292929",
                          }}
                          className="to-apply-table-border-color"
                        >
                          <TableRow>
                            <TableCell
                              sx={{
                                borderBottom: "1px solid #292929",
                                width: "105px",
                                whiteSpace: "nowrap",
                              }}
                              className="even-name-text-style-for_sales"
                            >
                              Event Days
                            </TableCell>
                            <TableCell
                              sx={{
                                borderBottom: "1px solid #292929",
                                width: "220px",
                              }}
                              className="even-name-text-style-for_sales"
                            >
                              Start Date/Time
                            </TableCell>
                            <TableCell
                              sx={{
                                borderBottom: "1px solid #292929",
                                width: "220px",
                              }}
                              className="even-name-text-style-for_sales"
                            >
                              End Date/Time
                            </TableCell>
                            <TableCell
                              sx={{
                                borderBottom: "1px solid #292929",
                                width: "220px",
                              }}
                              className="even-name-text-style-for_sales"
                            >
                              Price
                            </TableCell>
                            <TableCell
                              sx={{
                                borderBottom: "1px solid #292929",
                                width: "220px",
                              }}
                              className="even-name-text-style-for_sales"
                            >
                              Sales
                            </TableCell>
                            <TableCell
                              sx={{
                                borderBottom: "1px solid #292929",
                                width: "220px",
                              }}
                              className="even-name-text-style-for_sales"
                            >
                              Revenue
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <Box
                          sx={{
                            height: "10px",
                            backgroundColor: "transparent",
                          }}
                        ></Box>
                        <TableBody
                          sx={{
                            background:
                              "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)",
                          }}
                          className="for-to-give-the-padding"
                        >
                          {ticket?.festivalEventDates?.map(
                            (f_date, d_Index) => (
                              <TableRow
                                key={d_Index}
                                className="main-even-name-text-style-for_sales"
                              >
                                <TableCell
                                  sx={{
                                    borderBottom: "1px solid transparent",
                                    whiteSpace: "nowrap",
                                  }}
                                  className="main-even-name-text-style-for_sales-padding-margin-name__sales_privateticketing"
                                  align="center"
                                >
                                  {d_Index + 1}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    borderBottom: "1px solid transparent",
                                    width: "220px",
                                  }}
                                  className="main-even-name-text-style-for_sales-padding-margin-name__sales_privateticketing"
                                >
                                  {convertToLocal(f_date?.eventStartDateTime) ??
                                    "---"}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    borderBottom: "1px solid transparent",
                                    width: "220px",
                                  }}
                                  className="main-even-name-text-style-for_sales-padding-margin-name__sales_privateticketing"
                                >
                                  {convertToLocal(f_date?.eventEndDateTime) ??
                                    "---"}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    borderBottom: "1px solid transparent",
                                    width: "220px",
                                  }}
                                  className="main-even-name-text-style-for_sales-padding-margin-name__sales_privateticketing"
                                >
                                  £{ticket?.ticketPrice}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    borderBottom: "1px solid transparent",
                                    width: "220px",
                                  }}
                                  className="main-even-name-text-style-for_sales-padding-margin-name__sales_privateticketing"
                                >
                                  {Number(ticket?.originalNoOfTickets) -
                                    Number(ticket?.noOfTickets)}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    borderBottom: "1px solid transparent",
                                    width: "220px",
                                  }}
                                  className="main-even-name-text-style-for_sales-padding-margin-name__sales_privateticketing"
                                >
                                  £
                                  {ticket?.ticketPrice *
                                    (Number(ticket?.originalNoOfTickets) -
                                      Number(ticket?.noOfTickets))}
                                </TableCell>
                              </TableRow>
                            )
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </>
              ))}
            </div>
          ) : act === "Attendance" ? (
            <div className="attendance__events__main__container">
              {allTicketAttendees?.map((t_attend, a_Idx) => (
                <div key={a_Idx}>
                  <div>
                    <p className="ticket__detail__Sales__1">
                      {allTickets?.[a_Idx]?.ticketName ?? "---"}
                    </p>
                  </div>

                  <div>
                    <Box>
                      <Box
                        sx={{
                          height: "24px",
                          backgroundColor: "transparent",
                          mb: "16px",
                        }}
                      ></Box>

                      <TableContainer
                        component={Paper}
                        sx={{
                          backgroundColor: "rgba(255, 255, 255, 0.06)",
                          overflowX: "auto",
                          borderBottom: "none",
                        }}
                      >
                        <Table>
                          <TableHead
                            sx={{
                              background:
                                "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)",
                              boxShadow: "0px 0.96px 1.91px 0px #1E1E240F",
                              backdropFilter: "blur(202.8699951171875px)",
                              borderBottom: "1px solid #292929",
                            }}
                            className="to-apply-table-border-color"
                          >
                            <TableRow>
                              <TableCell
                                sx={{
                                  borderBottom: "1px solid #292929",
                                  width: "220px",
                                }}
                                className="even-name-text-style-for_sales"
                              >
                                Name
                              </TableCell>
                              <TableCell
                                sx={{
                                  borderBottom: "1px solid #292929",
                                  width: "220px",
                                }}
                                className="even-name-text-style-for_sales"
                              >
                                Email
                              </TableCell>
                              <TableCell
                                sx={{
                                  borderBottom: "1px solid #292929",
                                  width: "220px",
                                }}
                                className="even-name-text-style-for_sales"
                              >
                                Scanned Time
                              </TableCell>
                            </TableRow>
                          </TableHead>

                          <Box
                            sx={{
                              height: "20px",
                              backgroundColor: "transparent",
                            }}
                          ></Box>
                          <TableBody
                            sx={{
                              background:
                                "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)",
                            }}
                            className="for-to-give-the-padding"
                          >
                            {t_attend?.allAattendees?.map(
                              (atnde, atnd_Index) =>
                                atnde?.scan && (
                                  <TableRow
                                    key={atnd_Index}
                                    className="main-even-name-text-style-for_sales"
                                  >
                                    <TableCell
                                      sx={{
                                        borderBottom: "1px solid transparent",
                                        whiteSpace: "nowrap",
                                      }}
                                      className="main-even-name-text-style-for_sales-padding-margin-name__sales_privateticketing"
                                    >
                                      {atnde?.fullName ?? "---"}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        borderBottom: "1px solid transparent",
                                        width: "220px",
                                      }}
                                      className="main-even-name-text-style-for_sales-padding-margin-name__sales_privateticketing"
                                    >
                                      {atnde?.email ?? "---"}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        borderBottom: "1px solid transparent",
                                        width: "220px",
                                      }}
                                      className="main-even-name-text-style-for_sales-padding-margin-name__sales_privateticketing"
                                    >
                                      {atnde?.scannedDate ?? "---"}
                                    </TableCell>
                                  </TableRow>
                                )
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="wallet-container">
              {allTicketAttendees?.map((t_attend, a_Idx) => (
                <div key={a_Idx}>
                  <div>
                    <p className="ticket__detail__Sales__1">
                      {t_attend?.t_name}
                    </p>
                  </div>
                  <Box>
                    <Box
                      sx={{
                        height: "24px",
                        backgroundColor: "transparent",
                        mb: "16px",
                      }}
                    ></Box>

                    <TableContainer
                      component={Paper}
                      sx={{
                        backgroundColor: "rgba(255, 255, 255, 0.06)",
                        overflowX: "auto",
                        borderBottom: "none",
                      }}
                      className="w-full xl:w-[900px]"
                    >
                      <Table>
                        <TableHead
                          sx={{
                            background:
                              "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)",
                            boxShadow: "0px 0.96px 1.91px 0px #1E1E240F",
                            backdropFilter: "blur(202.8699951171875px)",
                            borderBottom: "1px solid #292929",
                          }}
                          className="to-apply-table-border-color"
                        >
                          <TableRow>
                            <TableCell
                              sx={{
                                borderBottom: "1px solid #292929",
                                width: "120px",
                              }}
                              className="even-name-text-style-for_sales"
                            >
                              PFP
                            </TableCell>
                            <TableCell
                              sx={{
                                borderBottom: "1px solid #292929",
                                width: "220px",
                              }}
                              className="even-name-text-style-for_sales"
                            >
                              Name
                            </TableCell>
                            <TableCell
                              sx={{
                                borderBottom: "1px solid #292929",
                                width: "220px",
                              }}
                              className="even-name-text-style-for_sales"
                            >
                              Email
                            </TableCell>
                            <TableCell
                              sx={{
                                borderBottom: "1px solid #292929",
                                width: "180px",
                              }}
                              className="even-name-text-style-for_sales"
                            >
                              Date
                            </TableCell>
                            <TableCell
                              sx={{
                                borderBottom: "1px solid #292929",
                                width: "180px",
                              }}
                              className="even-name-text-style-for_sales"
                            >
                              Time
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <Box
                          sx={{
                            height: "20px",
                            backgroundColor: "transparent",
                          }}
                        ></Box>
                        <TableBody
                          sx={{
                            background:
                              "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)",
                            gap: "20px",
                          }}
                          className="for-to-give-the-padding"
                        >
                          {t_attend?.allAattendees?.map((atnde, atnd_Index) => (
                            <TableRow
                              key={`${a_Idx}-${atnd_Index}`}
                              className="main-even-name-text-style-for_sales"
                            >
                              <TableCell
                                sx={{
                                  borderBottom: "1px solid transparent",
                                  whiteSpace: "nowrap",
                                }}
                                className="main-even-name-text-style-for_sales-padding-margin-name__sales_privateticketing"
                              >
                                <img
                                  alt="user img"
                                  src={
                                    atnde?.user?.profilePicture ||
                                    "/Images/dashboard/naitram.png"
                                  }
                                  width={32}
                                  height={32}
                                  style={{
                                    borderRadius: "50%",
                                    objectFit: "cover",
                                  }}
                                />
                              </TableCell>
                              <TableCell
                                sx={{
                                  borderBottom: "1px solid transparent",
                                }}
                                className="main-even-name-text-style-for_sales-padding-margin-name__sales_privateticketing"
                              >
                                {atnde?.user?.fullname ?? "---"}
                              </TableCell>
                              <TableCell
                                sx={{
                                  borderBottom: "1px solid transparent",
                                }}
                                className="main-even-name-text-style-for_sales-padding-margin-name__sales_privateticketing"
                              >
                                {atnde?.email ?? "---"}
                              </TableCell>
                              <TableCell
                                sx={{
                                  borderBottom: "1px solid transparent",
                                }}
                                className="main-even-name-text-style-for_sales-padding-margin-name__sales_privateticketing"
                              >
                                {getLocalDate(atnde?.createdAt) ?? "---"}
                              </TableCell>
                              <TableCell
                                sx={{
                                  borderBottom: "1px solid transparent",
                                }}
                                className="main-even-name-text-style-for_sales-padding-margin-name__sales_privateticketing"
                              >
                                {getLocalTime(atnde?.createdAt) ?? "---"}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default WhiteListEventTicketing;
