import React, { useEffect, useState } from "react";
import { Navigate, useParams, Link, useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
} from "@mui/material";
import "./Launch__party.css";
import linkicon from "../../Assets/Launchparty/linkarrow.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  getEventByEventId,
  getEventById,
} from "../../services/redux/middleware/event";
import { toast } from "react-toastify";
import ScreenLoader from "../loader/screenLoader";
const Launch__party = () => {
  const containerStyle = {
    borderWidth: "0px",
    borderStyle: "solid",
    borderImageSlice: 1,
    borderImageSource:
      "linear-gradient(180deg, rgba(251, 251, 251, 0.6) 0%, rgba(251, 251, 251, 0.7) 100%)",
    borderRadius: "0.5rem",
    backgroundColor: "transparent",
    height: "100vh",
    overflowY: "scroll",
    // padding: '20px',
  };

  const scrollbarStyles = `
    ::-webkit-scrollbar {
      width: 5px;
    }
    ::-webkit-scrollbar-track {
      background: transparent;
    }
    ::-webkit-scrollbar-thumb {
      background: #13FF7A;
      border-radius: 5px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #13ff79b9;
    }
  `;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const { eventId } = useParams();

  const [sumData, setSumData] = useState(0);
  const [totalSales, setSumSales] = useState(0);
  const [totalRevenue, setTotalRevenue] = useState(0.0);
  const [dataRows, setDataRows] = useState([]);
  const [totalNumerator, setTotalNumerator] = useState(0);
  const [totalDenominator, setTotalDenominator] = useState(0);
  const [event, setEvent] = useState({});
  // const eventById = useSelector((state) => state?.getEventByEventID?.eventIdEvents);
  const eventById = useSelector(
    (state) => state?.getEventByEventId.eventIdEvents?.data
  );

  useEffect(() => {
    setLoading(true);
    if (eventId) {
      dispatch(getEventByEventId(eventId)).finally(() => setLoading(false));
    }
  }, [dispatch, eventId]);
  console.log("launch party data is ........", eventById);

  const eventLoader = useSelector((state) => state?.getEventByEventID?.loading);
  useEffect(() => {
    setSumData(0);
    setSumSales(0);
    setTotalRevenue(0.0);
    setDataRows([]);
    setTotalNumerator(0);
    setTotalDenominator(0);
    setEvent({});
  }, []);

  useEffect(() => {
    const currentUrl = window.location.href;
    if (currentUrl) {
      const url = new URL(currentUrl);
      const pathname = url.pathname;
      const parts = pathname.split("/");
      const eventId = parts[parts.length - 1];
      console.log("my event id is", eventId);
      dispatch(getEventByEventId(eventId));
    }
  }, [dispatch]);

  useEffect(() => {
    setEvent(eventById);
    setDataRows([]);
    if (eventById?.tickets?.length) {
      const totalSales = eventById.tickets.reduce((sum, ticket) => {
        const originalTickets = Number(ticket?.originalNoOfTickets) || 0;
        const remainingTickets = Number(ticket?.noOfTickets) || 0;
        return sum + (originalTickets - remainingTickets);
      }, 0); // Start sum at 0

      setSumSales(totalSales);
    } else {
      setSumSales(0); // Default to 0 if no tickets
    }
    const totalRevenue = eventById?.tickets?.reduce((sumOfRevenue, ticket) => {
      const ticketPrice = ticket?.ticketPrice ?? 0;
      return (
        sumOfRevenue +
        ticketPrice * (ticket?.originalNoOfTickets - ticket?.noOfTickets)
      );
    }, 0.0);
    setTotalRevenue(totalRevenue);
    const updatedRows = [];
    eventById?.tickets?.forEach((ticket) => {
      const existingRowIndex = updatedRows.findIndex((row) =>
        row?.links?.some(
          (link) =>
            link?.type?.trim() === ticket?.selectedEventTicketType?.trim()
        )
      );
      const status = Number(ticket?.noOfTickets) === 0 ? "Sold Out" : "On Sale";
      const nomiNator = Number(ticket?.noOfTickets) || 0;
      const deNominator = Number(ticket?.originalNoOfTickets) || 0;
      const ticketRevenue = ticket?.ticketPrice
        ? ticket?.ticketPrice *
          (ticket?.originalNoOfTickets - ticket?.noOfTickets)
        : 0;
      const navLink =
        ticket?.selectedEventTicketType ===
        "Festivals/Multi-Day Tickets/Season Passes"
          ? "/festivals-tickets"
          : ticket?.selectedEventTicketType === "RSVP Ticketing"
          ? "/Sales_Rvsp_ticketing"
          : ticket?.selectedEventTicketType === "Private Event Ticketing"
          ? "/private-event-ticketing"
          : ticket?.selectedEventTicketType ===
            "Passworded/Discounted Voucher Event"
          ? "/passworded-event"
          : "/customer-ticket";
      if (existingRowIndex === -1) {
        updatedRows.push({
          name: ticket?.ticketName,
          status,
          sales: `${nomiNator}/${deNominator}`,
          price: ticketRevenue,
          links: [
            { type: ticket?.selectedEventTicketType.trim(), url: navLink },
          ],
        });
      } else {
        const existingRow = updatedRows[existingRowIndex];
        const [existingNumerator, existingDenominator] = existingRow.sales
          .split("/")
          .map(Number);
        existingRow.sales = `${existingNumerator + nomiNator}/${
          existingDenominator + deNominator
        }`;
        existingRow.price += ticketRevenue;
      }
    });
    setDataRows(updatedRows);
    const sumNumerators = updatedRows.reduce((sum, row) => {
      const [numerator] = row.sales.split("/").map(Number);
      return sum + numerator;
    }, 0);
    const sumDenominators = updatedRows.reduce((sum, row) => {
      const [, denominator] = row.sales.split("/").map(Number);
      return sum + denominator;
    }, 0);
    setTotalNumerator(sumNumerators);
    setTotalDenominator(sumDenominators);
    const totalPrice = updatedRows.reduce((sum, row) => sum + row.price, 0.0);
    setSumData(totalPrice);
  }, [eventById]);
  // console.log("This is The event data ===> ", eventById);
  console.log("total sales", totalSales);
  console.log("data rws is,", dataRows);

  const navigateToTicketPage = (link, ticketAllIndexes) => {
    // Initialize navigate function

    // For festival Tickets
    if (link === "/Festivals_Multitask__tickets") {
      let allTickets = [];
      let ticketIndexes = [];
      
      ticketAllIndexes?.forEach((item, index) => {
        const isFestivalTicket = item?.selectedEventTicketType === "Festivals/Multi-Day Tickets/Season Passes"; // Check for "Private Event Ticketing"
    
        console.log("isFestivalTicket: ", isFestivalTicket); // Check if the current item is a "Private Event Ticketing" ticket
    
        if (isFestivalTicket) {
          // Filter tickets where ticketName contains "festival"
          const matchingTickets = eventById?.tickets?.filter((t) => t?.selectedEventTicketType?.toLowerCase().includes("festival"));
          // Initialize allTickets if it's not already initialized
          allTickets = allTickets ?? [];
          // Avoid duplicates: Use ticketName as a unique identifier
          matchingTickets.forEach((ticket) => {
            if (!allTickets.some((t) => t.ticketName === ticket.ticketName)) {
              allTickets.push(ticket); // Add ticket only if it's not already in allTickets
            }
          });
    
          // Store the index of the ticket in ticketIndexes
          ticketIndexes.push(index);
        }
      });

      console.log("tindexes",ticketIndexes)
      const eventId = eventById?.id;
      localStorage.setItem("currentTicketData", "")
      localStorage.setItem(
        "currentTicketData",
        JSON.stringify({
          name: "Festival Ticket ",
          allTickets,
          tIndexes: ticketIndexes,
          eventId,
        })
      );
      navigate(link);// Use navigate instead of router.push
    }

    // For RSVP Tickets
    if (link === "/Sales_Rvsp_ticketing") {
      let allTickets = [];
      let ticketIndexes = [];
      
      ticketAllIndexes?.forEach((item, index) => {
        const isPrivateTicket = item?.selectedEventTicketType === "RSVP Ticketing"; // Check for "Private Event Ticketing"
    
        console.log("isPrivateTicket: ", isPrivateTicket); // Check if the current item is a "Private Event Ticketing" ticket
    
        if (isPrivateTicket) {
          // Filter tickets where ticketName contains "Private"
          const matchingTickets = eventById?.tickets?.filter((t) => t?.selectedEventTicketType?.toLowerCase().includes("rsvp"));
          // Initialize allTickets if it's not already initialized
          allTickets = allTickets ?? [];
          // Avoid duplicates: Use ticketName as a unique identifier
          matchingTickets.forEach((ticket) => {
            if (!allTickets.some((t) => t.ticketName === ticket.ticketName)) {
              allTickets.push(ticket); // Add ticket only if it's not already in allTickets
            }
          });
    
          // Store the index of the ticket in ticketIndexes
          ticketIndexes.push(index);
        }
      });

      console.log("tindexes",ticketIndexes)
      const eventId = eventById?.id;
      localStorage.setItem("currentTicketData", "")
      localStorage.setItem(
        "currentTicketData",
        JSON.stringify({
          name: "RSVP Tickets ",
          allTickets,
          tIndexes: ticketIndexes,
          eventId,
        })
      );
      navigate(link); // Use navigate instead of router.push
    }

    // For private Tickets
    if (link === "/private_ticketing") {
      let allTickets = [];
      let ticketIndexes = [];
      
      ticketAllIndexes?.forEach((item, index) => {
        const isPrivateTicket = item?.selectedEventTicketType === "Private Event Ticketing"; // Check for "Private Event Ticketing"
    
        console.log("isPrivateTicket: ", isPrivateTicket); // Check if the current item is a "Private Event Ticketing" ticket
    
        if (isPrivateTicket) {
          // Filter tickets where ticketName contains "Private"
          const matchingTickets = eventById?.tickets?.filter((t) => t?.selectedEventTicketType?.toLowerCase().includes("private"));
          // Initialize allTickets if it's not already initialized
          allTickets = allTickets ?? [];
          // Avoid duplicates: Use ticketName as a unique identifier
          matchingTickets.forEach((ticket) => {
            if (!allTickets.some((t) => t.ticketName === ticket.ticketName)) {
              allTickets.push(ticket); // Add ticket only if it's not already in allTickets
            }
          });
    
          // Store the index of the ticket in ticketIndexes
          ticketIndexes.push(index);
        }
      });

      console.log("tindexes",ticketIndexes)
      const eventId = eventById?.id;
      localStorage.setItem("currentTicketData", "")
      localStorage.setItem(
        "currentTicketData",
        JSON.stringify({
          name: "Private Ticket ",
          allTickets,
          tIndexes: ticketIndexes,
          eventId,
        })
      );
      navigate(link); // Use navigate instead of router.push
    }

    // For passworded Tickets
    if (link === "/passworded-event") {
      let allTickets = [];
      let ticketIndexes = [];
      
      ticketAllIndexes?.forEach((item, index) => {
        const isFestivalTicket = item?.selectedEventTicketType === "Passworded/Discounted Voucher Event"; // Check for "Private Event Ticketing"
    
        console.log("isFestivalTicket: ", isFestivalTicket); // Check if the current item is a "Private Event Ticketing" ticket
    
        if (isFestivalTicket) {
          // Filter tickets where ticketName contains "festival"
          const matchingTickets = eventById?.tickets?.filter((t) => t?.selectedEventTicketType?.toLowerCase().includes("password"));
          // Initialize allTickets if it's not already initialized
          allTickets = allTickets ?? [];
          // Avoid duplicates: Use ticketName as a unique identifier
          matchingTickets.forEach((ticket) => {
            if (!allTickets.some((t) => t.ticketName === ticket.ticketName)) {
              allTickets.push(ticket); // Add ticket only if it's not already in allTickets
            }
          });
    
          // Store the index of the ticket in ticketIndexes
          ticketIndexes.push(index);
        }
      });

      console.log("tindexes",ticketIndexes)
      const eventId = eventById?.id;
      localStorage.setItem("currentTicketData", "")
      localStorage.setItem(
        "currentTicketData",
        JSON.stringify({
          name: "Passworded Ticket ",
          allTickets,
          tIndexes: ticketIndexes,
          eventId,
        })
      );
      navigate(link); // Use navigate instead of router.push
    }

    // For Custom Tickets
    if (link === "/customer-ticket") {
      let allTickets = [];
      let ticketIndexes = [];
      
      ticketAllIndexes?.forEach((item, index) => {
        const isCustomTicket = item?.selectedEventTicketType === "Custom Ticketing"; // Check for "Private Event Ticketing"
    
        console.log("isCustomTicket: ", isCustomTicket); // Check if the current item is a "Private Event Ticketing" ticket
    
        if (isCustomTicket) {
          // Filter tickets where ticketName contains "festival"
          const matchingTickets = eventById?.tickets?.filter((t) => t?.selectedEventTicketType?.toLowerCase().includes("custom"));
          // Initialize allTickets if it's not already initialized
          allTickets = allTickets ?? [];
          // Avoid duplicates: Use ticketName as a unique identifier
          matchingTickets.forEach((ticket) => {
            if (!allTickets.some((t) => t.ticketName === ticket.ticketName)) {
              allTickets.push(ticket); // Add ticket only if it's not already in allTickets
            }
          });
    
          // Store the index of the ticket in ticketIndexes
          ticketIndexes.push(index);
        }
      });

      console.log("tindexes",ticketIndexes)
      const eventId = eventById?.id;
      localStorage.setItem("currentTicketData", "")
      localStorage.setItem(
        "currentTicketData",
        JSON.stringify({
          name: "Custom Tickets ",
          allTickets,
          tIndexes: ticketIndexes,
          eventId,
        })
      );
      navigate(link); // Use navigate instead of router.push
    }

    if (link === "/whitelist-ticket") {
      let allTickets = [];
      let ticketIndexes = [];
      
      ticketAllIndexes?.forEach((item, index) => {
        const isWhiteListTicket = item?.selectedEventTicketType === "Whitelist Event Ticketing"; // Check for "Private Event Ticketing"
    
        console.log("isWhiteListTicket: ", isWhiteListTicket); // Check if the current item is a "Private Event Ticketing" ticket
    
        if (isWhiteListTicket) {
          // Filter tickets where ticketName contains "festival"
          const matchingTickets = eventById?.tickets?.filter((t) => t?.selectedEventTicketType?.toLowerCase().includes("whitelist"));
          // Initialize allTickets if it's not already initialized
          allTickets = allTickets ?? [];
          // Avoid duplicates: Use ticketName as a unique identifier
          matchingTickets.forEach((ticket) => {
            if (!allTickets.some((t) => t.ticketName === ticket.ticketName)) {
              allTickets.push(ticket); // Add ticket only if it's not already in allTickets
            }
          });
    
          // Store the index of the ticket in ticketIndexes
          ticketIndexes.push(index);
        }
      });

      console.log("tindexes",ticketIndexes)
      const eventId = eventById?.id;
      localStorage.setItem("currentTicketData", "")
      localStorage.setItem(
        "currentTicketData",
        JSON.stringify({
          name: "Custom Tickets ",
          allTickets,
          tIndexes: ticketIndexes,
          eventId,
        })
      );
      navigate(link); // Use navigate instead of router.push
    }
  };
  const exportToCSV = () => {
    if (!dataRows || dataRows.length === 0) {
      toast.error("No data available to export!");
      return;
    }

    const rows = [
      ["Ticket Name", "Ticket Type", "Status", "Sales", "Revenue", "Link"],
      ...dataRows.map((row) => [
        row.name,
        row.links.map((link) => link.type).join(", "),
        row.status,
        row.sales,
        row.price.toFixed(2),
        row.links.map((link) => link.url).join(", "),
      ]),
    ];

    const csvContent = rows.map((row) => row.join(",")).join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", `${eventById?.name}-Sales-Analytics.csv`);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };
  const handleSalesAnalyticlick = () => {
    navigate(-1);
  };
  return (
    <>
      {loading && <ScreenLoader />}
      <div style={containerStyle}>
        <style>{scrollbarStyles}</style>
        <div className="launch__main">
          <div className="Organiser_Detail_HostedEvents_Sales_Analytics_Main_heading_and_image">
            <img
              onClick={handleSalesAnalyticlick}
              style={{ cursor: "pointer" }}
              src="/Payout_Images/graphlogo.svg"
              alt="Graph logo"
            />
            <h2>Sales Analytics</h2>
          </div>
          <div
            onClick={exportToCSV}
            style={{
              paddingRight: "0px",
              cssText: "padding-right: 0px !important;",
            }}
            className="export-button"
          >
            <button>Export</button>
          </div>
          <div className="launch_party_inerdiv">
            <p className="launch_party_text">{eventById?.name}</p>
            <div className="launch_sales_revenue-wraper">
              <div className="launch_sales-wrape">
                <p className="launch_sales_text">Total Sales</p>
                <p className="launch_sales_number">
                  {JSON.stringify(totalSales)}
                </p>
              </div>
              <div className="launch_sales-wrape">
                <p className="launch_sales_text">Total Revenue</p>
                <p className="launch_sales_number"> £ {totalRevenue}</p>
              </div>
            </div>
          </div>
          <p className="ticket__types__heading">Ticket Types</p>
          <Box>
            <Box
              sx={{ height: "32px", backgroundColor: "#000", mb: "16px" }}
            ></Box>

            <TableContainer
             className="Organizer_detail_Follower_Main_____"
              component={Paper}
              sx={{
                backgroundColor: "rgba(255, 255, 255, 0.06)",
                overflowX: "auto",
              }}
            >
              <Table>
                <TableHead
                  sx={{
                    background:
                      "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)",
                    borderBottom: "1px solid #292929",
                  }}
                  className="to-apply-table-border-color"
                >
                  <TableRow>
                    <TableCell
                      sx={{ borderBottom: "1px solid #292929", width: "105px" }}
                      className="even-name-text-style-for_sales"
                    >
                      Ticket Names
                    </TableCell>
                    <TableCell
                      sx={{ borderBottom: "1px solid #292929", width: "220px" }}
                      className="even-name-text-style-for_sales"
                    >
                      Ticket Type
                    </TableCell>
                    <TableCell
                      sx={{ borderBottom: "1px solid #292929", width: "220px" }}
                      className="even-name-text-style-for_sales"
                    >
                      Status
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: "1px solid rgba(41, 41, 41, 1)",
                        width: "220px",
                      }}
                      className="even-name-text-style-for_sales"
                    >
                      Sales
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: "1px solid rgba(41, 41, 41, 1)",
                        width: "220px",
                      }}
                      className="even-name-text-style-for_sales"
                    >
                      Revenue
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: "1px solid rgba(41, 41, 41, 1)",
                        width: "220px",
                      }}
                      className="even-name-text-style-for_sales"
                    >
                      Link
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableRow
                  sx={{
                    height: "16px",
                    backgroundColor: "#030303",
                    width: "220px",
                  }}
                >
                  <TableCell colSpan={6}></TableCell>
                </TableRow>

                <TableBody
                  sx={{
                    background:
                      "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)",
                  }}
                  className="for-to-give-the-padding"
                >
                  {dataRows?.map((row, rowIndex) => (
                    <TableRow
                      key={rowIndex}
                      className="main-even-name-text-style-for_sales"
                    >
                      <TableCell
                        sx={{ borderBottom: "1px solid transparent" }}
                        className="main-even-name-text-style-for_sales-padding-margin-name__sales__launch"
                      >
                        {row?.name}
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: "1px solid transparent",
                          width: "220px",
                        }}
                        className="main-even-name-text-style-for_sales-padding-margin-name__sales__launch"
                      >
                        {row.links ? (
                          row.links.map((links, index) => (
                            <p key={index}>{links.type}</p>
                          ))
                        ) : (
                          <span>No Data</span>
                        )}
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: "1px solid transparent",
                          width: "220px",
                        }}
                        className="main-even-name-text-style-for_sales-padding-margin-name__sales__launch"
                      >
                        {row?.status}
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: "1px solid transparent",
                          width: "220px",
                        }}
                        className="main-even-name-text-style-for_sales-padding-margin-name__sales__launch"
                      >
                        {row.sales}
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: "1px solid transparent",
                          width: "220px",
                        }}
                        className="main-even-name-text-style-for_sales-padding-margin-name__sales__launch"
                      >
                        £ {row.price}
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: "1px solid transparent",
                          width: "220px",
                        }}
                        className="main-even-name-text-style-for_sales-padding-margin-name__sales__launch"
                      >
                        <img
                          src={linkicon}
                          alt="Eye View"
                          style={{ width: "24px", cursor: "pointer" }}
                          onClick={() => {
                            const navLink =
                              row?.links?.[0]?.type ===
                              "Festivals/Multi-Day Tickets/Season Passes"
                                ? "/Festivals_Multitask__tickets"
                                : row?.links?.[0]?.type === "RSVP Ticketing"
                                ? "/Sales_Rvsp_ticketing"
                                : row?.links?.[0]?.type ===
                                  "Private Event Ticketing"
                                ? "/private_ticketing"
                                : row?.links?.[0]?.type ===
                                  "Passworded/Discounted Voucher Event"
                                ? "/passworded-event"
                                : "/customer-ticket";

                            navigateToTicketPage(navLink, eventById?.tickets);
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </div>
      </div>
    </>
  );
};

export default Launch__party;
