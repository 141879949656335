import React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getKycById } from "../../services/redux/middleware/getKycById";
import "./RequestS_Details_Representative.css";
import { approveStatus } from "../../services/redux/middleware/approveStatus";
import ScreenLoader from "../loader/screenLoader";
const RequestS_Details_Representative = () => {
  const { kycId } = useParams();
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const kycDataById = useSelector(
    (state) => state?.getKycById?.kycRequestData?.data?.data
  );
  console.log("kyc approval data by id is...", kycDataById);

  useEffect(() => {
    setLoading(true);
    dispatch(getKycById({ userId: kycId })).finally(() => setLoading(false));
  }, [dispatch]);

  useEffect(() => {
    if (kycDataById && Array.isArray(kycDataById)) {
      kycDataById.forEach((record, index) => {
        console.log(
          `User ID from KYC data at index ${index}: `,
          record?.userId
        );

        if (record?.Representative && Array.isArray(record?.Representative)) {
          record.Representative.forEach((individual) => {
            console.log(`DOB for reprsenstative : `, individual?.DOB);
          });
        }
      });
    }
  }, [kycDataById]);

 
const handleApprove = async () => {

  if (kycDataById && Array.isArray(kycDataById)) {
    try {
      setLoading(true); 
      let approvalSuccess = false;

      for (const record of kycDataById) {
        if (record?.userId) {
          const data = { userId: record.userId, approved: 1 };
          console.log("Approving status with data:", data);

          const result = await dispatch(approveStatus(data));
          console.log("Dispatch result:", result);

          if (result.type === "approveStatus/fulfilled") {
            if (result.payload?.status === 201) {
              approvalSuccess = true;
            }
          }
        }
      }

      if (approvalSuccess) {
        toast.success("Approved successfully");
      } else {
        toast.success("Approved successfully");
      }
    } catch (error) {
      console.error("Error during approval:", error);
      toast.error(error.message || "An unexpected error occurred");
    } finally {
      setLoading(false); 
    }
  } else {
    toast.error("No KYC data available for approval");
  }
};

  const handleDeny = async () => {
    toast.error("Kyc details not approved");
  };

  return (
    <>
      {loading && <ScreenLoader />}
      <div>
        <div className="RequestS_Details_Representative_divlast">
          <button
            onClick={handleApprove}
            className="RequestS_Details_Representative_divlastBtn1"
          >
            Approve
          </button>
          <button
            onClick={handleDeny}
            className="RequestS_Details_Representative_divlastBtn2"
          >
            Deny
          </button>
        </div>
        <TableContainer
          component={Paper}
          className="Organizer_detail_Follower_Main"
        >
          <Table
            sx={{ minWidth: 850, maxWidth: 1070 }}
            aria-label="simple table"
          >
            {kycDataById && kycDataById.length > 0 && (
              <TableHead>
                <TableRow
                  style={{ backgroundColor: "rgba(255, 255, 255, 0.06)" }}
                >
                  <TableCell className="Organizer_detail_Follower_Main___Row ">
                    Name
                  </TableCell>
                  <TableCell className="Organizer_detail_Follower_Main___Row">
                    Relationship
                  </TableCell>
                  <TableCell className="Organizer_detail_Follower_Main___Row">
                    DOB
                  </TableCell>
                  <TableCell className="Organizer_detail_Follower_Main___Row">
                    Address
                  </TableCell>
                  <TableCell className="Organizer_detail_Follower_Main___Row">
                    Town/City
                  </TableCell>
                  <TableCell className="Organizer_detail_Follower_Main___Row">
                    Country
                  </TableCell>
                </TableRow>
              </TableHead>
            )}

            {/* Spacer row for gap */}
            {kycDataById && kycDataById.length > 0 && (
              <TableRow sx={{ height: "0px", backgroundColor: "#030303" }}>
                <TableCell colSpan={6}></TableCell>
              </TableRow>
            )}

            <TableBody>
              <TableRow>
                <TableCell
                  colSpan={6}
                  style={{
                    height: "16px",
                    backgroundColor: "transparent",
                    borderBottom: "none",
                    padding: "0px",
                  }}
                ></TableCell>
              </TableRow>

              {/* Check if kycDataById has any Representative */}
              {kycDataById && kycDataById.length > 0 ? (
                kycDataById.map((record, index) => {
                  // Ensure the 'Representative' array exists and has items
                  if (
                    record?.Representative &&
                    Array.isArray(record?.Representative) &&
                    record.Representative.length > 0
                  ) {
                    return record.Representative.map(
                      (individual, individualIndex) => (
                        <TableRow
                          key={`${index}-${individualIndex}`}
                          className="Organizer_detail_Follower_Main___Body_Top"
                        >
                          <TableCell className="Organizer_detail_Follower_Main___Body">
                            {`${individual?.FirstName || "N/A"} ${
                              individual?.LastName || "N/A"
                            }`}
                          </TableCell>
                          <TableCell className="Organizer_detail_Follower_Main___Body1">
                            {individual?.relationship || "N/A"}
                          </TableCell>
                          <TableCell className="Organizer_detail_Follower_Main___Body1">
                            {individual?.DOB || "N/A"}
                          </TableCell>
                          <TableCell className="Organizer_detail_Follower_Main___Body1">
                            {individual?.Address1 || "N/A"}
                          </TableCell>
                          <TableCell className="Organizer_detail_Follower_Main___Body1">
                            {individual?.City || "N/A"}
                          </TableCell>
                          <TableCell className="Organizer_detail_Follower_Main___Body1">
                            {individual?.Country || "N/A"}
                          </TableCell>
                        </TableRow>
                      )
                    );
                  } else {
                    return null;
                  }
                })
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={8}
                    sx={{
                      textAlign: "center",
                      color: "#fff",
                      padding: "94px 337px",
                      background:
                        "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)",
                      borderBottom: "none",
                      borderRadius: "8px",
                      maxWidth: "904px",
                    }}
                  >
                    <Typography className="empty_state_text" variant="h6">
                      There’s No Representative
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};

export default RequestS_Details_Representative;
