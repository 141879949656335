import React, { useEffect, useState } from "react";
import "./getSponsored.css";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import { getAllSponsores } from "../../services/redux/middleware/getAllSponsores";
import ScreenLoader from "../../Components/loader/screenLoader";
import { toast } from "react-toastify";

function Sponsored_Request_table() {
  const containerStyle = {
    borderWidth: "0px",
    borderStyle: "solid",
    borderImageSlice: 1,
    borderImageSource:
      "linear-gradient(180deg, rgba(251, 251, 251, 0.6) 0%, rgba(251, 251, 251, 0.7) 100%)",
    borderRadius: "0.5rem",
    backgroundColor: "transparent",
    height: "100vh",
    overflowY: "scroll",
    // padding: '20px',
  };

  const scrollbarStyles = `
    ::-webkit-scrollbar {
      width: 5px;
    }
    ::-webkit-scrollbar-track {
      background: transparent;
    }
    ::-webkit-scrollbar-thumb {
      background: #13FF7A;
      border-radius: 5px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #13ff79b9;
    }
  `;
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const SponsoredData = useSelector(
    (state) => state?.getAllSponsers?.getAllSponsores?.data?.data
  );
  console.log("sponsored data is...", SponsoredData);

  useEffect(() => {
    setLoading(true);
    dispatch(getAllSponsores()).finally(() => setLoading(false));
  }, [dispatch]);
  const handleDownloadCSV = () => {
    if (!SponsoredData || SponsoredData.length === 0) {
      toast.error("No data available to export!");
      return;
    }

    const headers = ["Name", "Email", "Date", "Request"];
    const rows = SponsoredData.map((row) => [
      row?.firstName && row?.lastName
        ? `${row.firstName} ${row.lastName}`
        : "---",
      row?.email || "---",
      row?.createdAt.split("T")[0] || "---",
      "View Request",
    ]);

    const csvContent = [
      headers.join(","),
      ...rows.map((row) => row.join(",")),
    ].join("\n");

    const encodedUri = encodeURI(`data:text/csv;charset=utf-8,${csvContent}`);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "supponsred_requests.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const handleViewRequest = (id) => {
    navigate(`/Requests_Sponsored/${id}`);
  };

  return (
    <>
      {loading && <ScreenLoader />}
      <div style={containerStyle}>
        <style>{scrollbarStyles}</style>
        <div className="support-info-detail-container-new1">
          <div className="CollectiblesOwned-to-give-width-attendeed">
            <div onClick={handleDownloadCSV} className="export-button">
              <button>Export</button>
            </div>
            <Box sx={{ pb: "100px" }}>
              <Box
                sx={{ height: "50px", backgroundColor: "#000", mb: "16px" }}
              ></Box>

              <TableContainer
                className="Organizer_detail_Follower_Main_____"
                component={Paper}
                sx={{
                  backgroundColor: "rgba(255, 255, 255, 0.06)",
                  overflowX: "auto",
                }}
              >
                <Table>
                  <TableHead
                    sx={{ backgroundColor: "rgba(255, 255, 255, 0.06)" }}
                    className="to-apply-table-border-color"
                  >
                    <TableRow>
                      <TableCell
                        sx={{
                          borderBottom: "1px solid #292929",
                          width: "105px",
                        }}
                        className="even-name-text-style-for"
                      >
                        Name
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: "1px solid #292929",
                          width: "220px",
                        }}
                        className="even-name-text-style-for"
                      >
                        Email
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: "1px solid #292929",
                          width: "220px",
                        }}
                        className="even-name-text-style-for"
                      >
                        Date
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: "1px solid rgba(41, 41, 41, 1)",
                          width: "220px",
                        }}
                        className="even-name-text-style-for"
                      >
                        Requests
                      </TableCell>
                      {/* <TableCell
                sx={{ borderBottom: "1px solid rgba(41, 41, 41, 1)"  , width:'220px'}}
                className="even-name-text-style-for"
              >
               Date
              </TableCell> */}
                    </TableRow>
                  </TableHead>

                  <TableRow
                    sx={{
                      height: "16px",
                      backgroundColor: "#030303",
                      width: "220px",
                    }}
                  >
                    <TableCell colSpan={5}></TableCell>
                  </TableRow>

                  <TableBody
                    style={{
                      background:
                        "linear-gradient(360deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.06) 100%)",
                    }}
                    className="for-to-give-the-padding"
                  >
                    {!SponsoredData || SponsoredData.length === 0 ? (
                      <TableRow
                        sx={{
                          height: "94px",
                          backgroundColor: "#0F0F0F",
                        }}
                      >
                        <TableCell
                          colSpan={4}
                          sx={{
                            textAlign: "center",
                            color: "#fff",
                            background:
                              "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)",
                            borderBottom: "1px solid #292929",
                            borderRadius: "8px",
                            padding: "94px 337px",
                          }}
                        >
                          <Typography
                            className="empty_state_text"
                            variant="h6"
                            sx={{
                              marginBottom: "16px",
                              fontSize: "1rem",
                              fontWeight: 500,
                            }}
                          >
                            There’s No Get Sponsored Requests
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ) : (
                      SponsoredData?.map((row, index) => (
                        <TableRow
                          key={index}
                          className="main-even-name-text-style-for"
                        >
                          <TableCell
                            sx={{ borderBottom: "1px solid #292929" }}
                            className="main-even-name-text-style-for-padding-margin-name"
                          >
                            {row?.firstName && row?.lastName
                              ? `${row.firstName} ${row.lastName}`
                              : "-----"}
                          </TableCell>
                          <TableCell
                            sx={{
                              borderBottom: "1px solid #292929",
                              width: "105px",
                            }}
                            className="main-even-name-text-style-for-padding-margin-name_new"
                          >
                            {row?.email ? row?.email : "-----"}
                          </TableCell>
                          <TableCell
                            sx={{
                              borderBottom: "1px solid #292929",
                              width: "220px",
                            }}
                            className="main-even-name-text-style-for-padding-margin-type"
                          >
                            {row?.createdAt.split("T")[0]
                              ? row?.createdAt.split("T")[0]
                              : "-----"}
                          </TableCell>
                          <TableCell
                            onClick={() => {
                              console.log(
                                "Sponsored id passed is.........",
                                row?.id
                              );
                              handleViewRequest(row?.id);
                            }}
                            sx={{
                              borderBottom: "1px solid #292929",
                              width: "220px",
                              cursor: "pointer",
                              whiteSpace: "nowrap",
                            }}
                            className="main-even-name-text-style-for-padding-margin-type-newcolor"
                          >
                            View Request
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </div>
        </div>
      </div>
    </>
  );
}

export default Sponsored_Request_table;
