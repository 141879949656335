import React, { useEffect, useState } from "react";
import "./getSponsoredRequest.css";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getSponsoredById } from "../../services/redux/middleware/getSponsoredById";
import ScreenLoader from "../../Components/loader/screenLoader";
import { toast } from "react-toastify"; // Ensure you have react-toastify installed if needed for error handling

function GetSupportRequest() {
  const containerStyle = {
    borderWidth: "0px",
    borderStyle: "solid",
    borderImageSlice: 1,
    borderImageSource:
      "linear-gradient(180deg, rgba(251, 251, 251, 0.6) 0%, rgba(251, 251, 251, 0.7) 100%)",
    borderRadius: "0.5rem",
    backgroundColor: "transparent",
    height: "100vh",
    overflowY: "scroll",
    // padding: '20px',
  };

  const scrollbarStyles = `
    ::-webkit-scrollbar {
      width: 5px;
    }
    ::-webkit-scrollbar-track {
      background: transparent;
    }
    ::-webkit-scrollbar-thumb {
      background: #13FF7A;
      border-radius: 5px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #13ff79b9;
    }
  `;
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  console.log("Received sponsored id is .........", id);

  const SpnsoredByIdData = useSelector(
    (state) => state?.getSponsoredById?.getSponsoredById?.data?.data
  );

  useEffect(() => {
    if (id) {
      setLoading(true);
      dispatch(getSponsoredById({ id })).finally(() => {
        setLoading(false);
      });
    }
  }, [dispatch, id]);

  console.log("Sponsored by id data is ...........", SpnsoredByIdData);

  const handleViewRequest = () => {
    navigate(-1);
  };

  const handleDownloadCSV = () => {
    if (!SpnsoredByIdData || Object.keys(SpnsoredByIdData).length === 0) {
      toast.error("No data available to export!");
      return;
    }

    const headers = [
      "Subject",
      "Email",
      "Message",
      "Organization",
      "Role",
      "Contact Number",
    ];
    const rows = [
      [
        SpnsoredByIdData?.firstName && SpnsoredByIdData?.lastName
          ? `${SpnsoredByIdData.firstName} ${SpnsoredByIdData.lastName}`
          : "---",
        SpnsoredByIdData?.email || "---",
        SpnsoredByIdData?.message || "---",
        SpnsoredByIdData?.organizationName || "---",
        SpnsoredByIdData?.role || "---",
        SpnsoredByIdData?.contactNo || "---",
      ],
    ];
    

    const csvContent = [
      headers.join(","),
      ...rows.map((row) => row.join(",")),
    ].join("\n");

    const encodedUri = encodeURI(`data:text/csv;charset=utf-8,${csvContent}`);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "sponsored_request_details.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      {loading && <ScreenLoader />}
      <div style={containerStyle}>
        <style>{scrollbarStyles}</style>

        <div className="support-info-detail-container-new">
          <div className="Organiser_Detail_HostedEvents_Sales_Analytics_Main_heading_and_image">
            <img
              style={{ cursor: "pointer" }}
              onClick={handleViewRequest}
              src="/Payout_Images/graphlogo.svg"
              alt="Graph Logo"
            />
            <h2>Request</h2>
          </div>
          <div className="CollectiblesOwned-to-give-width-attendeed">
            <div className="export-button">
              <button onClick={handleDownloadCSV}>Export</button>
            </div>
          </div>
          <div className="support__detail__Subject_container">
            <div className="support__Detail__subject_container-1">
              <p className="subject__text__1">Name</p>
              <p className="subject__text__description">
                {" "}
                {SpnsoredByIdData?.firstName && SpnsoredByIdData?.lastName
                  ? `${SpnsoredByIdData.firstName} ${SpnsoredByIdData.lastName}`
                  : "-----"}
              </p>
            </div>
            <div className="support__Detail__subject_container-1">
              <p className="subject__text__1">Email</p>
              <p className="subject__text__description_detail">
                {SpnsoredByIdData?.email ? SpnsoredByIdData?.email : "----"}
              </p>
            </div>
            <div className="support__Detail__subject_container-1">
              <p className="subject__text__1">Message</p>
              <p className="subject__text__description_detail">
                {SpnsoredByIdData?.message
                  ? SpnsoredByIdData?.message
                  : "-----"}
              </p>
            </div>
            <div className="support__Detail__subject_container-1">
              <p className="subject__text__1">Organization</p>
              <p className="subject__text__description_detail">
                {SpnsoredByIdData?.organizationName
                  ? SpnsoredByIdData?.organizationName
                  : "----"}
              </p>
            </div>
            <div className="support__Detail__subject_container-1">
              <p className="subject__text__1">Role</p>
              <p className="subject__text__description_detail">
                {SpnsoredByIdData?.role ? SpnsoredByIdData?.role : "-----"}
              </p>
            </div>
            <div className="support__Detail__subject_container-1">
              <p className="subject__text__1">Contact Number</p>
              <p className="subject__text__description_detail">
                {SpnsoredByIdData?.contactNo
                  ? SpnsoredByIdData?.contactNo
                  : "-----"}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default GetSupportRequest;
