import React from "react";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";
import "./After___QA__PayoutHistory2.css";
import { useDispatch, useSelector } from "react-redux";
import { getPayoutAccount } from "../../services/redux/middleware/getPayOutAccount";
import ScreenLoader from "../loader/screenLoader";
const After___QA__PayoutHistory2 = () => {
  const containerStyle = {
    borderWidth: "0px",
    borderStyle: "solid",
    borderImageSlice: 1,
    borderImageSource:
      "linear-gradient(180deg, rgba(251, 251, 251, 0.6) 0%, rgba(251, 251, 251, 0.7) 100%)",
    borderRadius: "0.5rem",
    backgroundColor: "transparent",
    height: "100vh",
    overflowY: "scroll",
    // padding: '20px',
  };

  const scrollbarStyles = `
    ::-webkit-scrollbar {
      width: 5px;
    }
    ::-webkit-scrollbar-track {
      background: transparent;
    }
    ::-webkit-scrollbar-thumb {
      background: #13FF7A;
      border-radius: 5px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #13ff79b9;
    }
  `;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const paidAccountData = useSelector(
    (state) => state?.getPayoutAccount?.payoutSummaryData?.data?.data
  );
  console.log("paid account  data is...", paidAccountData);

  useEffect(() => {
    setLoading(true);
    dispatch(getPayoutAccount()).finally(() => setLoading(false));
  }, [dispatch]);
  const exportToCSV = () => {
    if (!paidAccountData || paidAccountData.length === 0) {
      toast.error("No data available to export!");
      return;
    }
    const csvHeaders = [
      "Organiser Name",
      "Bank/Wallet Name",
      "Acc No/Wallet Address",
      "Amount",
      "Chain/City",
    ];
    const csvRows = paidAccountData?.map((row) => [
      row?.organizerProfile?.name || "----",
      row?.bankName || "----",
      row?.IBAN || row?.walletAddress || "----",
      row?.amountWithdrawn || "----",
      row?.chain || row?.city || "----",
    ]);

    const csvContent = [
      csvHeaders.join(","),
      ...csvRows.map((row) => row.join(",")),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "Payout_Account.csv";
    link.click();
    URL.revokeObjectURL(url);
  };

  return (
    <>
      {loading && <ScreenLoader />}
      <div style={containerStyle}>
        <style>{scrollbarStyles}</style>

        <div className="qa__payout__main__container">
          <div
            style={{
              paddingRight: "0px",
              cssText: "padding-right: 0px !important;",
            }}
            className="export__button__container_kyc"
          >
            <button onClick={exportToCSV} className="export-button-kyc">
              Export
            </button>
          </div>
          {!paidAccountData ? (
            <div className="empty__state__main__cintainer__2">
              <Typography className="empty_state_text" variant="h6">
                There’s No Payout Accounts
              </Typography>
            </div>
          ) : (
            <TableContainer
              component={Paper}
              className="Organizer_detail_Follower_Main"
            >
              <Table
                sx={{ minWidth: 750, maxWidth: 1070 }}
                aria-label="simple table"
              >
                {paidAccountData?.length > 0 && (
                  <TableHead
                    style={{ backgroundColor: "rgba(255, 255, 255, 0.06)" }}
                  >
                    <TableRow>
                      <TableCell className="Organizer_detail_Follower_Main___Row">
                        Organiser Name
                      </TableCell>
                      <TableCell
                        style={{ whiteSpace: "nowrap" }}
                        className="Organizer_detail_Follower_Main___Row"
                      >
                        Bank/Wallet Name
                      </TableCell>
                      <TableCell
                        style={{ whiteSpace: "nowrap" }}
                        className="Organizer_detail_Follower_Main___Row"
                      >
                        Acc No/Wallet Address
                      </TableCell>
                      <TableCell className="Organizer_detail_Follower_Main___Row">
                        Amount
                      </TableCell>
                      <TableCell className="Organizer_detail_Follower_Main___Row">
                        Chain/City
                      </TableCell>
                    </TableRow>
                  </TableHead>
                )}

                {/* Spacer row for gap */}
                {paidAccountData?.length > 0 && (
                  <TableRow sx={{ height: "0px", backgroundColor: "#030303" }}>
                    <TableCell colSpan={6}></TableCell>
                  </TableRow>
                )}

                <TableBody>
                  <TableRow>
                    <TableCell
                      colSpan={5}
                      style={{
                        height: "16px",
                        backgroundColor: "transparent",
                        borderBottom: "none",
                        padding: "0px",
                      }}
                    ></TableCell>
                  </TableRow>

                  {paidAccountData?.map((row, index) => (
                    <TableRow
                      key={index}
                      className="rganizer_detail_Follower_Main___Body_Top"
                    >
                      <TableCell className="Organizer_detail_Follower_Main___Body">
                        {row?.user?.fullname ? row?.user?.fullname : "----"}
                      </TableCell>
                      <TableCell className="Organizer_detail_Follower_Main___Body1">
                        {row?.bankName || row?.bankName || "----"}
                      </TableCell>
                      <TableCell className="Organizer_detail_Follower_Main___Body1">
                        {row?.IBAN || row?.walletAddress || "----"}
                      </TableCell>
                      <TableCell className="Organizer_detail_Follower_Main___Body1">
                        {row?.amountWithdrawn ? row?.amountWithdrawn : "0"}
                      </TableCell>
                      <TableCell className="Organizer_detail_Follower_Main___Body1">
                        {row?.chain || row?.city || "----"}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>
      </div>
    </>
  );
};

export default After___QA__PayoutHistory2;
