import React from "react";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "./FollowingTicket.css";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
} from "@mui/material";
import { Check, Close } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import ScreenLoader from "../../Components/loader/screenLoader";
const FollowingTicket = (followingData) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [loading, setLoading] = useState(true);
  const [profileData, setProfileData] = useState(null);
  useEffect(() => {
    if (followingData?.followingData?.getOrganizationProfileData?.data?.data) {
      console.log(
        "Fetched Profile Data:",
        followingData.followingData.getOrganizationProfileData.data.data
      );

      setLoading(true);
      setProfileData(
        followingData.followingData.getOrganizationProfileData.data.data
      );
      setLoading(false);
    }
  }, [followingData]);
  console.log("profileData state:", profileData);
  const convertToCSV = (data) => {
    const header = ["Organiser Name", "Email", "Events", "Followers", "Sales"];
    const rows = data?.followerDetails.map((follower) => [
      follower?.name || "----",
      follower?.email || "----",
      follower?.totalEvents || "0",
      data?.totalFollowers || "0",
      follower?.followerTotalSales || "0",
    ]);

    const csvContent = [
      header.join(","),
      ...rows.map((row) => row.join(",")),
    ].join("\n");

    return csvContent;
  };

  const downloadCSV = () => {
    if (
      !profileData?.followerDetails ||
      profileData.followerDetails.length === 0
    ) {
      toast.error("No data available to export!");
      return;
    }

    const csvData = convertToCSV(profileData);
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = "profile_data.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };
  return (
    <>
      {loading && <ScreenLoader />}
      <div style={{ paddingBottom: "100px" }}>
        <div className="export-button">
          <button onClick={downloadCSV}>Export</button>
        </div>
        <div className="personal-info-detail-container-----FOLLOWING">
          <div className="given-to-the-width-to-all__1">
            <Box>
              <Box
                sx={{ height: "50px", backgroundColor: "#000", mb: "16px" }}
              ></Box>
              {!profileData?.followerDetails ||
              profileData.followerDetails.length === 0 ? (
                <div>
                  <div className="empty__state__main__cintainer__1">
                    <Typography className="empty_state_text" variant="h6">
                      There’s No Following
                    </Typography>
                  </div>
                </div>
              ) : (
                <TableContainer
                  className="Organizer_detail_Follower_Main_____"
                  component={Paper}
                  sx={{
                    backgroundColor: "rgba(255, 255, 255, 0.06)",
                    overflowX: "auto",
                  }}
                >
                  <Table>
                    {profileData?.followerDetails?.length > 0 && (
                      <>
                        <TableHead
                          sx={{ backgroundColor: "rgba(255, 255, 255, 0.06)" }}
                          className="to-apply-table-border-color"
                        >
                          <TableRow>
                            <TableCell
                              sx={{ borderBottom: "1px solid #292929" }}
                              className="even-name-text-style-for"
                            >
                              Organiser Name
                            </TableCell>
                            <TableCell
                              sx={{ borderBottom: "1px solid #292929" }}
                              className="even-name-text-style-for"
                            >
                              Email
                            </TableCell>
                            <TableCell
                              sx={{
                                borderBottom: "1px solid rgba(41, 41, 41, 1)",
                              }}
                              className="even-name-text-style-for"
                            >
                              Events
                            </TableCell>
                            <TableCell
                              sx={{
                                borderBottom: "1px solid rgba(41, 41, 41, 1)",
                              }}
                              className="even-name-text-style-for"
                            >
                              Followers
                            </TableCell>
                            <TableCell
                              sx={{
                                borderBottom: "1px solid rgba(41, 41, 41, 1)",
                              }}
                              className="even-name-text-style-for"
                            >
                              Sales
                            </TableCell>
                          </TableRow>
                        </TableHead>

                        {/* Spacer row for gap */}
                        <TableRow
                          sx={{ height: "16px", backgroundColor: "#030303" }}
                        >
                          <TableCell colSpan={5}></TableCell>
                        </TableRow>
                      </>
                    )}

                    <TableBody
                      style={{
                        background:
                          "linear-gradient(360deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.06) 100%)",
                      }}
                    >
                      {profileData?.followerDetails.map((follower, index) => (
                        <TableRow
                          key={index}
                          className="main-even-name-text-style-for"
                        >
                          <TableCell
                            sx={{
                              borderBottom: "1px solid #292929",
                              width: "275px",
                              textTransform: "none !important",
                            }}
                            className="main-even-name-text-style-for-padding-margin-name"
                          >
                            {follower?.name || "----"}
                          </TableCell>

                          <TableCell
                            sx={{
                              borderBottom: "1px solid #292929",
                              width: "275px",
                            }}
                            className="main-even-name-text-style-for-padding-margin-type"
                          >
                            {follower?.email || "----"}
                          </TableCell>

                          <TableCell
                            sx={{
                              borderBottom: "1px solid #292929",
                              width: "275px",
                            }}
                            className="main-even-name-text-style-for-padding-margin-type"
                          >
                            {follower?.totalEvents || "0"}
                          </TableCell>

                          <TableCell
                            sx={{
                              borderBottom: "1px solid #292929",
                              width: "275px",
                            }}
                            className="main-even-name-text-style-for-padding-margin-type"
                          >
                            {profileData?.totalFollowers || "0"}
                          </TableCell>

                          <TableCell
                            sx={{
                              borderBottom: "1px solid #292929",
                              width: "275px",
                            }}
                            className="main-even-name-text-style-for-padding-margin-type"
                          >
                            {follower?.followerTotalSales || "0"}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Box>
          </div>
        </div>
      </div>
    </>
  );
};

export default FollowingTicket;
