import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "./PersonalInfoUserOrganizer.css";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import EventTicket from "../EventTicket/EventTicket";
import MrtRewards from "../MRTRewards/MrtRewards";
import CollectiblesOwned from "../CollectiblesOwned/CollectiblesOwned";
import PhotoRoll from "../PhotoRoll/PhotoRoll";
import LikedEvents from "../LikedEvents/LikedEvents";
import FollowingTicket from "../FollowingTickets/FollowingTicket";
import Organizer_Detail_Follower from "../../Components/Organizer_Detail_follower/Organizer_Detail_Follower";
import Organizer_Detail_Payout from "../../Components/Organizer_Detail_Payout/Organizer_Detail_Payout";
import Organizer_Detail_Scanner_login from "../../Components/Organizer_Detail_Scanner_login/Organizer_Detail_Scanner_login";
import Organiser_Detail_Liked_Events from "../../Components/Organiser_Detail_Liked_Events/Organiser_Detail_Liked_Events";
import Hosted_Events_Attendeed_main from "../../Components/Hosted_Events_Attendeed_main/Hosted_Events_Attendeed_main";
import Organiser_Detail_Liked_Events__Table from "../../Components/Organiser_Detail_Liked_Events__Table/Organiser_Detail_Liked_Events__Table";
import { useDispatch, useSelector } from "react-redux";
import { getOrganizerById } from "../../services/redux/middleware/getOrganizerById";
import { hostedEvents } from "../../services/redux/middleware/hostedEvents";
import { getChatPictures } from "../../services/redux/middleware/getChatPictures";
import { getFollowerDetail } from "../../services/redux/middleware/getFollowerDetail";
import { getUserPayout } from "../../services/redux/middleware/getUserPayout";
import { getLikedEventsOrganizer } from "../../services/redux/middleware/getLikedEventsOrganizer";
import { getScannerById } from "../../services/redux/middleware/getScannerById";
import ScreenLoader from "../../Components/loader/screenLoader";
const PersonalInfoUserOrganizer = () => {
  const containerStyle = {
    borderWidth: "0px",
    borderStyle: "solid",
    borderImageSlice: 1,
    borderImageSource:
      "linear-gradient(180deg, rgba(251, 251, 251, 0.6) 0%, rgba(251, 251, 251, 0.7) 100%)",
    borderRadius: "0.5rem",
    backgroundColor: "transparent",
    height: "100vh",
    overflowY: "scroll",
    // padding: '20px',
  };

  const scrollbarStyles = `
    ::-webkit-scrollbar {
      width: 5px;
    }
    ::-webkit-scrollbar-track {
      background: transparent;
    }
    ::-webkit-scrollbar-thumb {
      background: #13FF7A;
      border-radius: 5px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #13ff79b9;
    }
  `;
  const navigate = useNavigate();

  const location = useLocation(); // To track the current route
  const [act, setact] = useState(
    () => localStorage.getItem("activeTab") || "A"
  );
  const [loading, setLoading] = useState(true);
  const [photoRoll, setphotoRoll] = useState();
  const [followersdata, setFollowersdata] = useState();
  const [payoutdatas, setPayoutDatas] = useState();
  const [likedEventsData, setLikedEventsData] = useState();
  const [scanneridData, setScanneridData] = useState();
  const dispatch = useDispatch();
  const handlebtnclick = (active) => {
    setact(active);
    localStorage.setItem("activeTab", active);
  };

  const navigate2 = useNavigate();
  const goBack = () => {
    localStorage.setItem("activeTab", "A");
    navigate2(-1);
  };
  const [organizerPersonalInfo, setOrgranizerPersonalInfo] = useState([]);
  const [hostedEvent, setHostedEvent] = useState([]);
  const isActive = (path) => location?.pathname === path;

  const { organizerId } = useParams();
  const OrganizerbyID = useSelector((state) => state?.getOrganizerById);
  console.log("organizer data is ...", OrganizerbyID);
  const getHostedEvent = useSelector((state) => state?.hostedEvents);
  const photoRollData = useSelector((state) => state?.getChatPictures);
  const followersData = useSelector((state) => state?.getFollowerDetail);
  const payoutData = useSelector((state) => state?.getUserPayout);
  const likedEvents = useSelector((state) => state?.getLikedEventsOrganizer);
  const getScanner = useSelector((state) => state?.getScannerById);
  console.log("scanner data by id is .....", getScanner);

  useEffect(() => {
    setLoading(true);

    dispatch(getOrganizerById({ id: organizerId }));
    dispatch(hostedEvents({ userId: organizerId }));
    dispatch(getChatPictures({ userId: organizerId }));
    dispatch(getFollowerDetail({ userId: organizerId }));
    dispatch(getUserPayout({ userId: organizerId }));
    dispatch(getLikedEventsOrganizer({ userId: organizerId }));
    dispatch(getScannerById({ userId: organizerId }));
    setLoading(false);
  }, [dispatch, organizerId]);
  console.log("photo roll data", photoRollData);
  console.log("follwers data is ", followersData);
  console.log("payout data is ....", payoutData);
  console.log("likes events data for organizer is .....", likedEvents);
  useEffect(() => {
    if (OrganizerbyID?.getOrganizerByIdData?.data?.data.organizerProfiles[0]) {
      setOrgranizerPersonalInfo(
        OrganizerbyID?.getOrganizerByIdData?.data?.data.organizerProfiles[0]
      );
    }
    if (getHostedEvent) {
      setHostedEvent(getHostedEvent);
    }
    if (photoRollData) {
      setphotoRoll(photoRollData);
    }
    if (followersData) {
      setFollowersdata(followersData);
    }
    if (payoutData) {
      setPayoutDatas(payoutData);
    }
    if (likedEvents) {
      setLikedEventsData(likedEvents);
    }
    if (getScanner) {
      setScanneridData(getScanner);
    }
  }, [OrganizerbyID]);
  const generateCSV = () => {
    if (
      !OrganizerbyID?.getOrganizerByIdData?.data?.data.organizerProfiles ||
      OrganizerbyID?.getOrganizerByIdData?.data?.data.organizerProfiles
        .length === 0
    ) {
      toast.error("No data available to export!");
      return;
    }

    const headers = [
      "Name",
      "Email",
      "Bio",
      "Facebook",
      "Instagram",
      "Telegram",
      "LinkedIn",
      "Youtube",
      "TikTok",
      "Twitter",
    ];

    const data = [
      [
        organizerPersonalInfo?.fullname || "N/A",
        organizerPersonalInfo?.email || "N/A",
        organizerPersonalInfo?.bio || "N/A",
        organizerPersonalInfo?.fbUrl || "N/A",
        organizerPersonalInfo?.instaUrl || "N/A",
        organizerPersonalInfo?.telegramUrl || "N/A",
        organizerPersonalInfo?.linkedinUrl || "N/A",
        organizerPersonalInfo?.youtubeUrl || "N/A",
        organizerPersonalInfo?.tiktokUrl || "N/A",
        organizerPersonalInfo?.twitterUrl || "N/A",
      ],
    ];

    const csvRows = [];
    csvRows.push(headers.join(","));
    data.forEach((row) => {
      csvRows.push(row.join(","));
    });

    const blob = new Blob([csvRows.join("\n")], { type: "text/csv" });

    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "organizerProfile_data.csv";
    link.click();
  };

  return (
    <>
      {loading && <ScreenLoader />}

      <div className="personal-info-detail-container" style={containerStyle}>
        <style>{scrollbarStyles}</style>
        <div className="Simple-User-Details-heading-main">
          <div className="Organiser_Detail_HostedEvents_Sales_Analytics_Main_heading_and_image">
            <img src="/Payout_Images/graphlogo.svg " onClick={goBack} alt="" />
            <h2>Organiser Details</h2>
          </div>
          <div className="top-buttons">
            <button
              style={{
                background:
                  act === "A"
                    ? "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)padding-box, linear-gradient(167deg, #13FF7A 0.2%, #002B12 50.2%, #13FF7A 100.2%)border-box "
                    : "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)padding-box, linear-gradient(360deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.06) 100%)border-box",

                color: act === "A" ? "#00A849" : "#FFFFFF",
                border:
                  act === "A"
                    ? "1px solid  transparent"
                    : "1px solid  transparent",
              }}
              className={isActive("/personal-information") ? "active" : ""}
              onClick={() => handlebtnclick("A")}
            >
              Personal Information
            </button>
            <button
              style={{
                background:
                  act === "B"
                    ? "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)padding-box, linear-gradient(167deg, #13FF7A 0.2%, #002B12 50.2%, #13FF7A 100.2%)border-box "
                    : "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)padding-box, linear-gradient(360deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.06) 100%)border-box",

                color: act === "B" ? "#00A849" : "#FFFFFF",
                border:
                  act === "B"
                    ? "1px solid  transparent"
                    : "1px solid  transparent",
              }}
              className={isActive("/event-tickets") ? "active" : ""}
              onClick={() => handlebtnclick("B")}
            >
              Hosted Events
            </button>
            <button
              style={{
                background:
                  act === "C"
                    ? "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)padding-box, linear-gradient(167deg, #13FF7A 0.2%, #002B12 50.2%, #13FF7A 100.2%)border-box "
                    : "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)padding-box, linear-gradient(360deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.06) 100%)border-box",

                color: act === "C" ? "#00A849" : "#FFFFFF",
                border:
                  act === "C"
                    ? "1px solid  transparent"
                    : "1px solid  transparent",
              }}
              className={isActive("/nft-rewards") ? "active" : ""}
              onClick={() => handlebtnclick("C")}
            >
              Photo Roll
            </button>
            <button
              style={{
                background:
                  act === "D"
                    ? "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)padding-box, linear-gradient(167deg, #13FF7A 0.2%, #002B12 50.2%, #13FF7A 100.2%)border-box "
                    : "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)padding-box, linear-gradient(360deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.06) 100%)border-box",

                color: act === "D" ? "#00A849" : "#FFFFFF",
                border:
                  act === "D"
                    ? "1px solid  transparent"
                    : "1px solid  transparent",
              }}
              className={isActive("/collectibles-owned") ? "active" : ""}
              onClick={() => handlebtnclick("D")}
            >
              Followers
            </button>
            <button
              style={{
                background:
                  act === "E"
                    ? "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)padding-box, linear-gradient(167deg, #13FF7A 0.2%, #002B12 50.2%, #13FF7A 100.2%)border-box "
                    : "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)padding-box, linear-gradient(360deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.06) 100%)border-box",

                color: act === "E" ? "#00A849" : "#FFFFFF",
                border:
                  act === "E"
                    ? "1px solid  transparent"
                    : "1px solid  transparent",
              }}
              className={isActive("/photo-roll") ? "active" : ""}
              onClick={() => handlebtnclick("E")}
            >
              Payout
            </button>
            <button
              style={{
                background:
                  act === "F"
                    ? "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)padding-box, linear-gradient(167deg, #13FF7A 0.2%, #002B12 50.2%, #13FF7A 100.2%)border-box "
                    : "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)padding-box, linear-gradient(360deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.06) 100%)border-box",

                color: act === "F" ? "#00A849" : "#FFFFFF",
                border:
                  act === "F"
                    ? "1px solid  transparent"
                    : "1px solid  transparent",
              }}
              className={isActive("/liked-events") ? "active" : ""}
              onClick={() => handlebtnclick("F")}
            >
              Scanners Login
            </button>
            <button
              style={{
                background:
                  act === "G"
                    ? "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)padding-box, linear-gradient(167deg, #13FF7A 0.2%, #002B12 50.2%, #13FF7A 100.2%)border-box "
                    : "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)padding-box, linear-gradient(360deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.06) 100%)border-box",

                color: act === "G" ? "#00A849" : "#FFFFFF",
                border:
                  act === "G"
                    ? "1px solid  transparent"
                    : "1px solid  transparent",
              }}
              className={isActive("/following") ? "active" : ""}
              onClick={() => handlebtnclick("G")}
            >
              Liked Events
            </button>
          </div>
        </div>
        {act === "A" && (
          <>
            <div style={{ paddingBottom: "100px" }}>
              <div
                style={{
                  marginTop: "0px",
                  cssText: "margin-top: 0px !important;",
                }}
                className="export-button"
              >
                <button
                  style={{
                    marginTop: "0px",
                    cssText: "margin-top: 0px !important;",
                  }}
                  onClick={generateCSV}
                  className="Organizer_detail_Follower__Btn"
                >
                  Export
                </button>
              </div>
              <div className="profile-details">
                {/* <div className="profile-picture">
              <p>Profile Picture</p>
              <img src="/Images/PersonalInfoDetail/profile.svg" alt="Profile" />
            </div> */}
                <div className="profile-details-text-heading">
                  <p>Name</p>
                  <h2>{organizerPersonalInfo?.user?.fullname}</h2>
                </div>
                <div className="profile-details-text-heading">
                  <p>Email</p>
                  <h2>
                    {organizerPersonalInfo?.user?.email ? (
                      <a
                        style={{ color: "white", textDecoration: "none" }}
                        href={`mailto:${organizerPersonalInfo?.user?.email}`}
                      >
                        {organizerPersonalInfo?.user?.email}
                      </a>
                    ) : (
                      "No Link"
                    )}
                  </h2>
                </div>

                <div className="profile-details-text-heading">
                  <p>Bio</p>
                  <h2>{organizerPersonalInfo?.bio}</h2>
                </div>
                <div className="profile-details-text-heading">
                  <p>Personal Organiser Pofile</p>
                  <button
                    onClick={() => {
                      console.log(
                        "Social ID is .....",
                        organizerPersonalInfo?.id
                      );
                      window.open(
                        `https://naitram.live/profile/profile-main/${organizerPersonalInfo?.id}`,
                        "_blank"
                      );
                    }}
                    className="link-button-between"
                  >
                    Link
                  </button>
                </div>
                <div className="profile-details-text-heading">
                  <p>Facebook</p>
                  <h2>
                    {organizerPersonalInfo?.fbUrl ? (
                      <a
                        style={{ color: "white", textDecoration: "none" }}
                        href={organizerPersonalInfo?.fbUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {organizerPersonalInfo?.fbUrl}
                      </a>
                    ) : (
                      "No Link"
                    )}
                  </h2>
                </div>
                <div className="profile-details-text-heading">
                  <p>Instagram</p>
                  <h2>
                    {organizerPersonalInfo?.instaUrl ? (
                      <a
                        style={{ color: "white", textDecoration: "none" }}
                        href={organizerPersonalInfo?.instaUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {organizerPersonalInfo?.instaUrl}
                      </a>
                    ) : (
                      "No Link"
                    )}
                  </h2>
                </div>
                <div className="profile-details-text-heading">
                  <p>Telegram</p>
                  <h2>
                    {organizerPersonalInfo?.telegramUrl ? (
                      <a
                        style={{ color: "white", textDecoration: "none" }}
                        href={organizerPersonalInfo?.telegramUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {organizerPersonalInfo?.telegramUrl}
                      </a>
                    ) : (
                      "No Link"
                    )}
                  </h2>
                </div>
                <div className="profile-details-text-heading">
                  <p>LinkedIn</p>
                  <h2>
                    {organizerPersonalInfo?.linkedinUrl ? (
                      <a
                        style={{ color: "white", textDecoration: "none" }}
                        href={organizerPersonalInfo?.linkedinUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {organizerPersonalInfo?.linkedinUrl}
                      </a>
                    ) : (
                      "No Link"
                    )}
                  </h2>
                </div>
                <div className="profile-details-text-heading">
                  <p>Youtube</p>
                  <h2>
                    {organizerPersonalInfo?.youtubeUrl ? (
                      <a
                        style={{ color: "white", textDecoration: "none" }}
                        href={organizerPersonalInfo?.youtubeUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {organizerPersonalInfo?.youtubeUrl}
                      </a>
                    ) : (
                      "No Link"
                    )}
                  </h2>
                </div>
                <div className="profile-details-text-heading">
                  <p>TikTok</p>
                  <h2>
                    {organizerPersonalInfo?.tiktokUrl ? (
                      <a
                        style={{ color: "white", textDecoration: "none" }}
                        href={organizerPersonalInfo?.tiktokUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {organizerPersonalInfo?.tiktokUrl}
                      </a>
                    ) : (
                      "No Link"
                    )}
                  </h2>
                </div>
                <div className="profile-details-text-heading">
                  <p>Twitter</p>
                  <h2>
                    {organizerPersonalInfo?.twitterUrl ? (
                      <a
                        style={{ color: "white", textDecoration: "none" }}
                        href={organizerPersonalInfo?.twitterUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {organizerPersonalInfo?.twitterUrl}
                      </a>
                    ) : (
                      "No Link"
                    )}
                  </h2>
                </div>
              </div>
            </div>
          </>
        )}
        {act === "B" && (
          <>
            <Hosted_Events_Attendeed_main hostedEvent={hostedEvent} />
          </>
        )}
        {act === "C" && (
          <>
            <PhotoRoll photoData={photoRoll} />
          </>
        )}
        {act === "D" && (
          <>
            <Organizer_Detail_Follower followersData={followersdata} />
          </>
        )}
        {act === "E" && (
          <>
            <Organizer_Detail_Payout payoutData={payoutdatas} />
          </>
        )}
        {act === "F" && (
          <>
            <Organizer_Detail_Scanner_login scannerData={scanneridData} />
          </>
        )}
        {act === "G" && (
          <>
            <Organiser_Detail_Liked_Events__Table
              likedEvents={likedEventsData}
            />
          </>
        )}
      </div>
    </>
  );
};

export default PersonalInfoUserOrganizer;
