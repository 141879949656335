import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";
import "./kyc__Approval__Executive.css";
import { useDispatch, useSelector } from "react-redux";
import { getKycApproval } from "../../services/redux/middleware/getKyc";
import { getKycById } from "../../services/redux/middleware/getKycById";
import ScreenLoader from "../loader/screenLoader";

const Kyc_Details_Executive = () => {
  const { userId } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState();
  const [individualData, setIndividualData] = useState([]);
  const [businessData, setbusinessData] = useState([]);
  const [representativeData, setrepresentativeData] = useState([]);
  const [executiveData, setexecutiveData] = useState([]);
  console.log("Received user id is............", userId);

  const KycDataildata = useSelector(
    (state) => state?.getKycById?.kycRequestData?.data?.data
  );

  useEffect(() => {
    setLoading(true);
    if (userId) {
      dispatch(getKycById({ userId })).finally(() => {
        setLoading(false);
      });
    } else {
      console.log("User ID is not defined");
      setLoading(false);
    }
  }, [dispatch, userId]);

  useEffect(() => {
    if (KycDataildata?.length) {
      const individualData = KycDataildata.filter(
        (item) => item.userType === "Individual"
      );
      setIndividualData(individualData);
      const businessData = KycDataildata.filter(
        (item) => item.userType === "Business"
      );
      setbusinessData(businessData);
      const RepresentativeData = KycDataildata.filter(
        (item) => item.userType === "Representative"
      );
      setrepresentativeData(RepresentativeData);
      const OwnerData = KycDataildata.filter(
        (item) => item.userType === "Owner"
      );
      const ExecutiveData = KycDataildata.filter(
        (item) => item.userType === "Executive"
      );
      setexecutiveData(ExecutiveData);
      console.log("Individual Data:", individualData);

      console.log("Business Data:", businessData);
      console.log("Represntative Data.", RepresentativeData);
      console.log("Owner data ", OwnerData);
      console.log("Ecxecutive data ", ExecutiveData);
    }
  }, [KycDataildata]);

  console.log("KYC detail data is ...", KycDataildata);
  return (
    <div>
      {loading ? (
        <ScreenLoader />
      ) : !executiveData || executiveData.length === 0 ? (
        <div className="empty__state__main__cintainer__2">
          <Typography className="empty_state_text" variant="h6">
            There’s No Ecxecutive
          </Typography>
        </div>
      ) : (
        <TableContainer
          component={Paper}
          className="Organizer_detail_Follower_Main"
        >
          <Table
            sx={{ minWidth: 850, maxWidth: 1070 }}
            aria-label="simple table"
          >
            <TableHead style={{ background: "rgba(255, 255, 255, 0.06)" }}>
              <TableRow>
                <TableCell className="Organizer_detail_Follower_Main___Row ">
                  Name
                </TableCell>
                <TableCell className="Organizer_detail_Follower_Main___Row">
                  Relationship
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              <TableRow>
                <TableCell
                  colSpan={6}
                  style={{
                    height: "16px",
                    backgroundColor: "transparent",
                    borderBottom: "none",
                    padding: "0px",
                  }}
                ></TableCell>
              </TableRow>

              {executiveData?.map((row, index) => (
                <TableRow
                  key={index}
                  className="rganizer_detail_Follower_Main___Body_Top"
                >
                  <TableCell className="Organizer_detail_Follower_Main___Body">
                    {row?.Executive?.[0]?.FirstName ||
                    row?.Executive?.[0]?.LastName
                      ? `${row?.Executive?.[0]?.FirstName || "-----"} ${
                          row?.Executive?.[0]?.LastName || "-----"
                        }`
                      : "-----"}
                  </TableCell>

                  <TableCell className="Organizer_detail_Follower_Main___Body1">
                    {row?.Executive[0]?.relationship || "-----"}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default Kyc_Details_Executive;
